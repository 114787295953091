<div style='overflow:auto;'>
<form #confirmForm="ngForm" novalidate>

    <div class="headline" *ngIf="headline">{{headline}}</div>
    <div class="text" [innerHtml]="text"></div>

    <mat-form-field *ngIf="!hideInput"  style="width:100%;margin-top:25px;">
        <input matInput type="text" [(ngModel)]='response.ResponseText' placeholder="Meddelande" required name="message">
    </mat-form-field>

    <div style="margin-top:30px;">
      <button mat-flat-button [disabled]="requireInput && !confirmForm.valid" (click)="save()" [color]="confirmButtonColor" style="margin-bottom: 5px; float: right;">
          <mat-icon>{{confirmButtonIcon}}</mat-icon>
          &nbsp;{{confirmButtonText}}&nbsp;
      </button>

      <button *ngIf="!hideCancelButton" mat-flat-button (click)="cancel()" color="primary" style="margin-bottom: 5px; margin-right:10px; float: left;">
          <mat-icon>cancel</mat-icon>
          &nbsp;Avbryt&nbsp;
      </button>
    </div>
</form>
</div>
