import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { SharedModule } from '@app/shared';
import { CoreModule } from '@app/core';
import { SettingsModule } from './settings';
import { StaticModule } from './static';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
// Loading bar
import { LoadingBarHttpModule } from '@ngx-loading-bar/http';
// Install language
import { DateAdapter } from '@angular/material';
import { registerLocaleData } from '@angular/common';
import localeSV from '@angular/common/locales/sv';
registerLocaleData(localeSV);
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { SwedishDateAdapter } from './core/adapters/SwedishDateAdapter';
import { FeeTypeService } from './core/services/FeeTypeService';
import { MatAutocompleteModule } from '@angular/material'

@NgModule({
    imports: [
        // angular
        BrowserAnimationsModule,
        BrowserModule,
        // core & shared
        CoreModule,
        SharedModule,
        // features
        StaticModule,
        SettingsModule,
        // app
        AppRoutingModule,
        // Loading bar
        LoadingBarHttpModule,
        // Angular Material
        BrowserModule,
        BrowserAnimationsModule,
        MatAutocompleteModule 
       
    ],
    declarations: [AppComponent, routingComponents],
    providers: [
        { provide: MAT_DATE_LOCALE, useValue: 'sv-SE' },
        { provide: LOCALE_ID, useValue: 'sv-SE' },
        { provide: DateAdapter, useClass: SwedishDateAdapter },
        FeeTypeService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
