import { OnInit } from '@angular/core';
import { environment as env } from '@env/environment';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { CompanyService } from '../../core/services/CompanyService';
import { InvoiceService } from '../../core/services/InvoiceService';
import { CurrencyService } from '../../core/services/CurrencyService';
import { UserService } from '../../core/services/UserService';
import { EmployerTaxService } from '../../core/services/EmployerTaxService';
import { forkJoin } from 'rxjs';
var CombinedInvoiceSummaryComponent = /** @class */ (function () {
    function CombinedInvoiceSummaryComponent(router, route, dialog, companyService, invoiceService, currencyService, userService, employerTaxService) {
        this.router = router;
        this.route = route;
        this.dialog = dialog;
        this.companyService = companyService;
        this.invoiceService = invoiceService;
        this.currencyService = currencyService;
        this.userService = userService;
        this.employerTaxService = employerTaxService;
        this.routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
        this.versions = env.versions;
        this.companies = [];
        this.currencies = [];
        this.employerTaxes = [];
        //hoursWorkedTotal: number = 0;
        this.totalInvoiceAmount = 0;
        this.ref = { Id: 0, Address: "", City: "", Email: "", Name: "", NameLocked: false, Phone: "", Zip: "", IDNumber: "" };
        this.invoiceQuickGroupTemplate = { Vat: "25", Type: { Value: "4", Name: "Övrigt" }, rowTypeSelected: "4" };
        this.invoiceQuickGroup = JSON.parse(JSON.stringify(this.invoiceQuickGroupTemplate));
        this.vatList = [];
        this.invoiceShareTypes = [];
        this.addToInvoiceList = [{ Name: 'Ja', Value: true }, { Name: 'Nej', Value: false }];
        this.paymentTerms = [10, 15, 20, 25, 30];
        this.isLoading = true;
        this.feePaidByReciever = false;
        this.feePaidBySender = false;
        this.milageTotalAmount = 0;
        this.allowancesTotalAmount = 0;
        this.payoutEmployerTax = null;
        this.PayoutMunicipalityTax = 0;
        this.EmployerTax = 0;
        this.InvoiceFeeReceiver = 0;
        this.InvoiceFeeMember = 0;
        this.InvoiceFeePaidByCustomer = true;
        this.PensionSavingsTaxFactor = 0.2426;
        this.PensionSavingsTaxAmount = 0;
        this.municipalityTaxRounded = 0;
        this.ServiceFeeInfo = "";
        this.config = {
            disableClose: false,
            panelClass: 'custom-overlay-pane-class',
            hasBackdrop: true,
            backdropClass: '',
            width: '500px',
            height: '',
            position: {
                top: '',
                bottom: '',
                left: '',
                right: ''
            },
            data: {
                invoiceItemGroup: {},
                customerTemplate: {},
                customer: {},
                contact: {},
                companyId: 0
            },
            dataTemplate: {
                "Id": 0,
                "Type": {},
                "HoursWorked": undefined,
                "StartDate": undefined,
                "EndDate": undefined,
                "Comment": undefined,
                "InvoiceId": 0
            }
        };
    }
    CombinedInvoiceSummaryComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.id = params['id'] ? params['id'] : 0;
            _this.loadInvoice(_this.id);
        });
        this.getCompanies();
        this.loadCurrencies();
        this.loadEmployerTaxes();
        this.vatList.push({ Value: "25" }, { Value: "12" }, { Value: "6" }, { Value: "0" });
    };
    CombinedInvoiceSummaryComponent.prototype.changeValue = function (value) {
        this.InvoiceFeePaidByCustomer = value.checked;
        this.invoice.InvoiceFeePaidByCustomer = this.InvoiceFeePaidByCustomer;
    };
    CombinedInvoiceSummaryComponent.prototype.sendMessage = function (message) {
        var _this = this;
        this.invoiceService.sendMessage(message, this.id)
            .subscribe(function (response) { return _this.messageSent(response); }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.messageSent = function (message) {
        this.invoice.Message = undefined;
        this.invoice.Messages.push(message);
    };
    CombinedInvoiceSummaryComponent.prototype.currencyChanged = function () {
        for (var i = 0; i < this.currencies.length; i++) {
            var curr = this.currencies[i];
            if (curr.Id == this.invoice.Currency.Id) {
                this.invoice.Currency.Name = curr.Name;
            }
        }
    };
    CombinedInvoiceSummaryComponent.prototype.loadInvoice = function (id) {
        var _this = this;
        this.invoiceService.getInvoiceShareTypes()
            .subscribe(function (response) { return _this.invoiceShareTypes = response; }, function (error) { return console.log(error); });
        this.invoiceService.getCombinedInvoiceSummary(id)
            .subscribe(function (response) { return _this.onGetInvoiceCompleted(response); }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.loadCurrencies = function () {
        var _this = this;
        this.currencyService.getCurrencies()
            .subscribe(function (response) { return _this.currencies = response; }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.loadEmployerTaxes = function () {
        var _this = this;
        this.employerTaxService.getEmployerTaxes()
            .subscribe(function (response) { return _this.employerTaxes = response; }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.onGetInvoiceCompleted = function (invoice) {
        this.invoice = invoice;
        this.setInvoiceFee();
        //this.loadUserEmployerTax();
        debugger;
        for (var i = 0; i < invoice.SubInvoices.length; i++) {
            this.updateSubInvoiceAmounts(invoice.SubInvoices[i]);
        }
        this.updateAmounts();
        this.isLoading = false;
    };
    CombinedInvoiceSummaryComponent.prototype.loadUserEmployerTax = function (subInvoice, userId, checkZeroTax) {
        var _this = this;
        if (checkZeroTax === void 0) { checkZeroTax = false; }
        this.userService.getUserEmployerTax(userId, checkZeroTax)
            .subscribe(function (tax) { return _this.employerTaxLoaded(subInvoice, tax); }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.employerTaxLoaded = function (subInvoice, tax) {
        subInvoice.EmployerTaxType = tax;
        subInvoice.UserSettings.EmployerTax.Tax = subInvoice.EmployerTaxType.Tax;
    };
    CombinedInvoiceSummaryComponent.prototype.loadUserMunicipalityTax = function (subInvoice, userId, checkZeroTax) {
        var _this = this;
        if (checkZeroTax === void 0) { checkZeroTax = false; }
        var payoutdate = this.invoice.PayoutDate;
        if (payoutdate == null) {
            payoutdate = new Date();
        }
        this.userService.getUserMunicipalityTax(payoutdate, userId, checkZeroTax)
            .subscribe(function (tax) { return _this.municipalityTaxLoaded(subInvoice, tax); }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.municipalityTaxLoaded = function (subInvoice, tax) {
        subInvoice.UserSettings.Municipality.Tax = tax;
        //Makes sure municipality tax always is calculated with 2 decimals, even if 3 in db table
        // Use temporary tax if present instead of municipality standard tax
        if (subInvoice.UserSettings.TaxAdjusted >= 0) {
            subInvoice.UserSettings.Municipality.Tax = parseFloat(subInvoice.UserSettings.TaxAdjusted.toFixed(4));
        }
        else {
            subInvoice.UserSettings.Municipality.Tax = parseFloat(subInvoice.UserSettings.Municipality.Tax.toFixed(4));
        }
    };
    CombinedInvoiceSummaryComponent.prototype.updateAmounts = function () {
        this.invoice.BruttoLon = 0;
        this.invoice.NettoLon = 0;
        this.payoutEmployerTax = 0;
        //Add all fees 
        this.invoice.FeesTotalAmount = this.InvoiceFeeMember + this.InvoiceFeeReceiver + this.invoice.ExchangeFee + this.invoice.KronofogdenFee + this.invoice.ReminderFee + Math.round(this.invoice.ServiceFee) + this.invoice.InkassoFee + this.invoice.CustomFee;
        //Partial total amount from which payout is calculated
        this.PartialTotalAmount = Math.round(this.invoice.PaidTotal - this.invoice.TotalVatAmount - this.invoice.FeesTotalAmount + this.invoice.ManualDiscount);
        //Total amount of expenses
        this.invoice.ExpensesTotalAmount = Math.round(this.DeductionsTotalAmount + this.milageTotalAmount + this.allowancesTotalAmount);
        //Amount from which employer tax is deducted
        this.PartialAmountExclNetExpenses = Math.round(this.PartialTotalAmount - this.invoice.ExpensesTotalAmount);
        if (this.PartialAmountExclNetExpenses < 0) {
            this.PartialAmountExclNetExpenses = 0;
        }
        this.TotalInvoiceAmount = this.invoice.TotalInvoiceAmount;
        //Calculates the total payout amount
        this.invoice.PayOutTotal = Math.round(this.invoice.NettoLon + this.invoice.ExpensesTotalAmount);
        //If net expenses are exceeding the partial total amount, only the partial total amount can be paid
        if (this.invoice.ExpensesTotalAmount > this.PartialTotalAmount) {
            this.invoice.PayOutTotal = this.PartialTotalAmount;
        }
    };
    CombinedInvoiceSummaryComponent.prototype.checkCustomServiceFee = function (subInvoice) {
        //Check if salary reciever has custom service fee, only if invoice is not paid out
        if (subInvoice.UserSettings.HasCustomServiceFee == true && subInvoice.PayoutDate == null) {
            //If custom service fee, reset total service fee
            subInvoice.FeesTotalAmount = +subInvoice.ServiceFee;
            //Calculate new service fee
            subInvoice.ServiceFee = Math.round(subInvoice.PaidTotal * subInvoice.UserSettings.CustomServiceFee);
            var customServicePercent = this.invoice.UserSettings.CustomServiceFee * 100;
            this.ServiceFeeInfo = "Avtalad serviceavgift: " + customServicePercent + " %";
        }
    };
    CombinedInvoiceSummaryComponent.prototype.setAmountsForCommissionCashInvoice = function (subInvoice) {
        subInvoice.PartialTotalAmount = Math.round(subInvoice.PaidTotal - subInvoice.TotalVatAmount);
        subInvoice.ExpensesTotalAmount = 0;
        subInvoice.PartialAmountExclNetExpenses = subInvoice.PartialTotalAmount;
        subInvoice.TotalPayout = subInvoice.PartialTotalAmount;
    };
    CombinedInvoiceSummaryComponent.prototype.calculatePartialTotalAmount = function (subInvoice) {
        subInvoice.PartialTotalAmount = Math.round(subInvoice.PaidTotal - subInvoice.TotalVatAmount - subInvoice.FeesTotalAmount + subInvoice.ManualDiscount);
    };
    CombinedInvoiceSummaryComponent.prototype.calculatePensionSavingsAmount = function (subInvoice) {
        //Total pension savings amount
        subInvoice.PensionSavingsTaxAmount = Math.round(subInvoice.PensionSavingsAmount * this.PensionSavingsTaxFactor);
        subInvoice.PensionSavingsInclTax = Math.round(subInvoice.PensionSavingsAmount + subInvoice.PensionSavingsTaxAmount);
    };
    CombinedInvoiceSummaryComponent.prototype.calculateAllowancesTotalAmount = function (subInvoice) {
        var allowancesTotalAmount = 0;
        //Calculate allowances total amount
        for (var i = 0; i < subInvoice.Allowances.length; i++) {
            //Calculates total mileage amount
            if (subInvoice.Allowances[i].AllowanceType.Id == '1') {
                this.milageTotalAmount += Math.round(subInvoice.Allowances[i].Sum);
            }
            //Calculates total daily travel allowance amount
            else {
                allowancesTotalAmount += Math.round(subInvoice.Allowances[i].Sum);
                subInvoice.AllowancesTotalAmount = allowancesTotalAmount;
            }
        }
    };
    CombinedInvoiceSummaryComponent.prototype.calculateExpensesTotalAmount = function (subInvoice) {
        subInvoice.ExpensesTotalAmount = Math.round(subInvoice.DeductionsTotalAmount + subInvoice.MileageTotalAmount + subInvoice.AllowancesTotalAmount);
    };
    CombinedInvoiceSummaryComponent.prototype.calculatePartialAmountExclNetExpenses = function (subInvoice) {
        //Amount from which employer tax is deducted
        subInvoice.PartialAmountExclNetExpenses = Math.round(subInvoice.PartialTotalAmount - subInvoice.ExpensesTotalAmount - subInvoice.PensionSavingsInclTax);
        if (subInvoice.PartialAmountExclNetExpenses < 0) {
            subInvoice.PartialAmountExclNetExpenses = 0;
        }
    };
    CombinedInvoiceSummaryComponent.prototype.calculateGrossSalary = function (subInvoice) {
        subInvoice.UserSettings.EmployerTax.Tax = subInvoice.EmployerTaxType.Tax;
        subInvoice.BruttoLon = Math.round(subInvoice.PartialAmountExclNetExpenses / (1 + subInvoice.UserSettings.EmployerTax.Tax));
    };
    CombinedInvoiceSummaryComponent.prototype.calculateEmployerTaxAmount = function (subInvoice) {
        //Calculates employer tax amount
        subInvoice.ArbetsgivarAvgift = Math.round(subInvoice.PartialAmountExclNetExpenses - subInvoice.BruttoLon);
    };
    CombinedInvoiceSummaryComponent.prototype.calculateMunicipalityTaxAmount = function (subInvoice) {
        //How much municipality tax is paid in SEK
        subInvoice.PayoutMunicipalityTax = Math.round(subInvoice.BruttoLon * subInvoice.UserSettings.Municipality.Tax);
    };
    CombinedInvoiceSummaryComponent.prototype.calculateNetSalary = function (subInvoice) {
        //Calculates net salary
        subInvoice.NettoLon = Math.round(subInvoice.BruttoLon - subInvoice.PayoutMunicipalityTax);
    };
    CombinedInvoiceSummaryComponent.prototype.calculatePayout = function (subInvoice) {
        debugger;
        //Rounds payout up to closes int
        subInvoice.TotalPayout = Math.round(subInvoice.PayOutTotal);
        //Calculates the total payout amount
        subInvoice.PayOutTotal = Math.round(subInvoice.NettoLon + subInvoice.ExpensesTotalAmount);
        //If net expenses are exceeding the partial total amount, only the partial total amount can be paid
        if (subInvoice.ExpensesTotalAmount > subInvoice.PartialTotalAmount) {
            subInvoice.PayOutTotal = subInvoice.PartialTotalAmount;
        }
    };
    CombinedInvoiceSummaryComponent.prototype.calculateAttachmentOfSalaryPayment = function (subInvoice, attachment) {
        //if (subInvoice.AttachmentOfSalary.AttachmentAmount > 0) {
        //  this.calculateAttachmentOfSalaryPayment(subInvoice, subInvoice.AttachmentOfSalary)
        //}
    };
    CombinedInvoiceSummaryComponent.prototype.calculateCommissionAmount = function (subInvoice) {
        //PartialAmountExclNetExpenses x invoice?.Commission = invoice?.Commission * PartialAmountExclNetExpenses
        subInvoice.CommissionAmount = subInvoice.PartialAmountExclNetExpenses * this.invoice.Commission;
    };
    // Refaktorisera till mindre metoder med enkla namn som beskriver funktionen
    // Ta bort updateSubInvoiceAmounts helt ur koden, anropa metoderna i lösningen när data laddas
    CombinedInvoiceSummaryComponent.prototype.updateSubInvoiceAmounts = function (subInvoice) {
        var _this = this;
        subInvoice.ZeroTaxChecked = false;
        var municipalityTaxRounded = 0;
        var checkZeroTax = false;
        if (subInvoice.ServiceFeeShare == null || (subInvoice.ServiceFeeShare != null && subInvoice.ServiceFeeShare.Percentage == 0)) {
            this.checkCustomServiceFee(subInvoice);
        }
        else if (subInvoice.ServiceFeeShare != null && subInvoice.ServiceFeeShare.Percentage > 0) {
            var sharedServicePercent = subInvoice.ServiceFeeShare.Percentage;
            var parentServicePercent = subInvoice.ServiceFeeShare.InvoiceSenderServiceFeeFactor * 100;
            this.ServiceFeeInfo = ", andel: " + sharedServicePercent + " % av moderfakturans ordinarie serviceavgift: " + parentServicePercent + " %";
        }
        else {
            this.ServiceFeeInfo = "";
        }
        //Partial total amount from which payout is calculated
        //For commission payouts
        if (subInvoice.Type != null && subInvoice.Type.TechnicalName == "CommissionCashInvoice") {
            this.setAmountsForCommissionCashInvoice(subInvoice);
        }
        // For regular invoices
        else {
            this.calculatePartialTotalAmount(subInvoice);
            if (subInvoice.PartialTotalAmount < 1000) {
                checkZeroTax = true;
            }
            //this.loadUserEmployerTax(subInvoice, subInvoice.UserSettings.Id, checkZeroTax);
            //this.loadUserMunicipalityTax(subInvoice, subInvoice.UserSettings.Id, checkZeroTax);
            var userEmployerTax$ = this.userService.getUserEmployerTax(subInvoice.UserSettings.Id, checkZeroTax);
            var userMunicipalityTax$ = this.userService.getUserMunicipalityTax(subInvoice.PayoutDate, subInvoice.UserSettings.Id, checkZeroTax);
            forkJoin([userEmployerTax$, userMunicipalityTax$]).subscribe(function (_a) {
                var employerTax = _a[0], municipalityTax = _a[1];
                // Both observables have completed, so we can proceed with the calculations
                subInvoice.EmployerTaxType = employerTax;
                subInvoice.UserSettings.EmployerTax.Tax = employerTax;
                subInvoice.UserSettings.Municipality.Tax = municipalityTax;
                // Call the remaining methods
                _this.calculatePensionSavingsAmount(subInvoice);
                _this.calculateAllowancesTotalAmount(subInvoice);
                _this.calculateAllowancesTotalAmount(subInvoice);
                _this.calculateExpensesTotalAmount(subInvoice);
                _this.calculatePartialAmountExclNetExpenses(subInvoice);
                _this.calculateGrossSalary(subInvoice);
                _this.calculateEmployerTaxAmount(subInvoice);
                _this.calculateMunicipalityTaxAmount(subInvoice);
                _this.calculateNetSalary(subInvoice);
                //this.calculateAttachmentOfSalaryPayment(subInvoice);
                _this.calculatePayout(subInvoice);
            }, function (error) { return console.log(error); });
        }
        return subInvoice;
    };
    //updateSubInvoiceAmountsREMOVE(subInvoice: IInvoice) {
    //  var municipalityTaxRounded: any;
    //  var allowancesTotalAmount = 0;
    //  //Check if salary reciever has custom service fee, only if invoice is not paid out
    //  if (subInvoice.UserSettings.HasCustomServiceFee == true && subInvoice.PayoutDate == null) {
    //    //If custom service fee, reset total service fee
    //    subInvoice.FeesTotalAmount = + subInvoice.ServiceFee;
    //    //Calculate new service fee
    //    subInvoice.ServiceFee = Math.round(subInvoice.PaidTotal * subInvoice.UserSettings.CustomServiceFee);
    //    console.log("ServiceFee");
    //    console.log(subInvoice.ServiceFee);
    //    console.log(subInvoice.TotalInvoiceAmount);
    //  }
    //  //Partial total amount from which payout is calculated
    //  //For commission payouts
    //  if (subInvoice.Type != null && subInvoice.Type.TechnicalName == "CommissionCashInvoice") {
    //    subInvoice.PartialTotalAmount = Math.round(subInvoice.PaidTotal - subInvoice.TotalVatAmount);
    //    municipalityTaxRounded = 0;
    //    subInvoice.ExpensesTotalAmount = 0;
    //    subInvoice.PartialAmountExclNetExpenses = subInvoice.PartialTotalAmount;
    //    subInvoice.TotalPayout = subInvoice.PartialTotalAmount;
    //  }
    //  // For regular invoices
    //  else {
    //    subInvoice.PartialTotalAmount = Math.round(subInvoice.PaidTotal - subInvoice.TotalVatAmount - subInvoice.FeesTotalAmount + subInvoice.ManualDiscount);
    //    //if (subInvoice.ZeroTaxChecked == false) {
    //    //  if (this.PartialTotalAmount < 1000) {
    //    //    this.loadUserEmployerTax(subInvoice, subInvoice.UserSettings.Id, true);
    //    //    this.loadUserMunicipalityTax(subInvoice, subInvoice.UserSettings.Id, true);
    //    //  }
    //    //  else {
    //    //    this.loadUserEmployerTax(subInvoice, subInvoice.UserSettings.Id, false);
    //    //    this.loadUserMunicipalityTax(subInvoice, subInvoice.UserSettings.Id, false);
    //    //  }
    //    //  subInvoice.ZeroTaxChecked = true;
    //    //}
    //    //Total pension savings amount
    //    subInvoice.PensionSavingsTaxAmount = Math.round(subInvoice.PensionSavingsAmount * this.PensionSavingsTaxFactor);
    //    subInvoice.PensionSavingsInclTax = Math.round(subInvoice.PensionSavingsAmount + subInvoice.PensionSavingsTaxAmount);
    //    //Makes sure municipality tax always is calculated with 2 decimals, even if 3 in db table
    //    // Use temporary tax if present instead of municipality standard tax
    //    if (subInvoice.UserSettings.TaxAdjusted >= 0) {
    //      municipalityTaxRounded = parseFloat(subInvoice.UserSettings.TaxAdjusted.toFixed(4));
    //    }
    //    else {
    //      municipalityTaxRounded = parseFloat(subInvoice.UserSettings.Municipality.Tax.toFixed(4));
    //    }
    //    //Calculate allowances total amount
    //    for (var i = 0; i < subInvoice.Allowances.length; i++) {
    //      //Calculates total mileage amount
    //      if (subInvoice.Allowances[i].AllowanceType.Id == '1') {
    //        this.milageTotalAmount += Math.round(subInvoice.Allowances[i].Sum);
    //      }
    //      //Calculates total daily travel allowance amount
    //      else {
    //        allowancesTotalAmount += Math.round(subInvoice.Allowances[i].Sum);
    //        subInvoice.AllowancesTotalAmount = allowancesTotalAmount;
    //      }
    //    }
    //    //Total amount of expenses
    //    subInvoice.ExpensesTotalAmount = Math.round(subInvoice.DeductionsTotalAmount + subInvoice.MileageTotalAmount + subInvoice.AllowancesTotalAmount);
    //    //Amount from which employer tax is deducted
    //    subInvoice.PartialAmountExclNetExpenses = Math.round(subInvoice.PartialTotalAmount - subInvoice.ExpensesTotalAmount - subInvoice.PensionSavingsInclTax);
    //    if (subInvoice.PartialAmountExclNetExpenses < 0) {
    //      subInvoice.PartialAmountExclNetExpenses = 0;
    //    }
    //    //Calculates gross salary 
    //    subInvoice.BruttoLon = Math.round(subInvoice.PartialAmountExclNetExpenses / (1 + subInvoice.UserSettings.EmployerTax.Tax));
    //    //Calculates employer tax
    //    subInvoice.ArbetsgivarAvgift = Math.round(subInvoice.PartialAmountExclNetExpenses - subInvoice.BruttoLon);
    //    //How much municipality tax is paid in SEK
    //    subInvoice.PayoutMunicipalityTax = Math.round(subInvoice.BruttoLon * municipalityTaxRounded);
    //    //Calculates net salary
    //    subInvoice.NettoLon = Math.round(subInvoice.BruttoLon - subInvoice.PayoutMunicipalityTax);
    //    //Rounds payout up to closes int
    //    subInvoice.TotalPayout = Math.round(subInvoice.PayOutTotal);
    //    //Calculates the total payout amount
    //    subInvoice.PayOutTotal = Math.round(subInvoice.NettoLon + subInvoice.ExpensesTotalAmount);
    //    //If net expenses are exceeding the partial total amount, only the partial total amount can be paid
    //    if (subInvoice.ExpensesTotalAmount > subInvoice.PartialTotalAmount) {
    //      subInvoice.PayOutTotal = subInvoice.PartialTotalAmount;
    //    }
    //    if (subInvoice.AttachmentOfSalary.AttachmentAmount > 0) {
    //      this.calculateAttachmentOfSalaryPayment(subInvoice, subInvoice.AttachmentOfSalary)
    //    }
    //  }
    //  return subInvoice;
    //}
    //calculatePartialAmountExclNetExpenses(subInvoice: IInvoice) {
    //  this.updateSubInvoiceAmounts(subInvoice);
    //  return subInvoice.PartialAmountExclNetExpenses;
    //}
    CombinedInvoiceSummaryComponent.prototype.calculatePaidTotal = function (subInvoice) {
        this.updateSubInvoiceAmounts(subInvoice);
        return subInvoice.PaidTotal;
    };
    CombinedInvoiceSummaryComponent.prototype.setInvoiceFee = function () {
        if (this.invoice.FeePaidByReciever) {
            this.InvoiceFeeReceiver = this.invoice.InvoiceFee;
            this.InvoiceFeeMember = 0;
        }
        else {
            this.InvoiceFeeMember = this.invoice.InvoiceFee;
            this.InvoiceFeeReceiver = 0;
        }
    };
    CombinedInvoiceSummaryComponent.prototype.save = function (invoice) {
        var _this = this;
        this.isLoading = true;
        this.invoiceService.updateInvoiceSummary(invoice)
            .subscribe(function (response) { return _this.onSaved(response); }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.onSaved = function (response) {
        var _this = this;
        //Reload the view when page is saved
        this.route.params.subscribe(function (params) {
            _this.id = params['id'] ? params['id'] : 0;
            _this.loadInvoice(_this.id);
        });
    };
    CombinedInvoiceSummaryComponent.prototype.updatePayoutDate = function (id, date) {
        var _this = this;
        //this.isLoading = true;
        this.invoiceService.updatePayoutDate(id, date)
            .subscribe(function (response) { return _this.onPayoutDateSaved(response); }, function (error) { return console.log(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.onPayoutDateSaved = function (response) {
        //this.isLoading = false;
    };
    CombinedInvoiceSummaryComponent.prototype.getCompanies = function () {
        var _this = this;
        this.companyService.getMyCompanies()
            .subscribe(function (value) { return _this.onGetCompaniesFinished(value); }, function (error) { return _this.onGetCompaniesError(error); });
    };
    CombinedInvoiceSummaryComponent.prototype.onGetCompaniesError = function (error) {
    };
    CombinedInvoiceSummaryComponent.prototype.onGetCompaniesFinished = function (companies) {
        this.companies = companies;
    };
    CombinedInvoiceSummaryComponent.prototype.getCustomer = function (id) {
        for (var i = 0; i < this.companies.length; i++) {
            if (this.companies[i].Id == this.invoice.Customer.Id) {
                //console.log(this.companies[i]);
                return JSON.parse(JSON.stringify(this.companies[i]));
            }
        }
    };
    CombinedInvoiceSummaryComponent.prototype.openLink = function (link) {
        window.open(link, '_blank');
    };
    CombinedInvoiceSummaryComponent.prototype.employerTaxUpdated = function (taxId) {
        for (var i = 0; i < this.employerTaxes.length; i++) {
            if (this.employerTaxes[i].Id == taxId) {
                this.invoice.EmployerTaxType.Tax = this.employerTaxes[i].Tax;
            }
        }
        this.updateAmounts();
    };
    CombinedInvoiceSummaryComponent.prototype.toFormattedDate = function (iso) {
        var date = new Date(iso);
        var d = date.getFullYear() + "-" + ((date.getMonth() + 1).toString().length == 1 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1) + "-" + (date.getDate().toString().length == 1 ? ("0" + date.getDate().toString()) : date.getDate().toString());
        if (d == '1970-01-01')
            return undefined;
        return d;
    };
    return CombinedInvoiceSummaryComponent;
}());
export { CombinedInvoiceSummaryComponent };
