import { Component, OnInit, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, Observable, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IAccountSettings } from '../../core/interfaces/user/IAccountSettings';
import { UserService } from '../../core/services/UserService';
import { MunicipalityService } from '../../core/services/municipalityService';
import { IUser } from '../../core/interfaces/user/IUser';
import { IFile } from '../../core/interfaces/IFile';
import { IMunicipality } from '../../core/interfaces/IMunicipality';
import { ITaxAdjustment } from '../../core/interfaces/user/ITaxAdjustment';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatTableDataSource } from '@angular/material';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, MatPaginator, MatSortModule, MatSort, Sort } from '@angular/material';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { DataSource } from '@angular/cdk/collections';
import { CanComponentDeactivate } from '../../core/guards/CanDeactivate.Component';

import {
  selectorSettings, 
  ActionSettingsChangeTheme,
  ActionSettingsChangeAutoNightMode,
  ActionSettingsChangeAnimationsPage,
  ActionSettingsChangeAnimationsElements,
  SettingsState,
  ActionSettingsPersist,
} from '../settings.reducer';
import { EditTaxAdjustmentDialogComponent } from './tax-adjustment/edit-tax-adjustment-Dialog.Component';

import {
  ActionAuthLogin,
  ActionAuthLogout,
  AnimationsService,
  selectorAuth,
  routeAnimations
} from '@app/core';
import { environment as env } from '@env/environment';


@Component({
  selector: 'anms-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy, CanComponentDeactivate  {
  private unsubscribe$: Subject<void> = new Subject<void>();
    settings: SettingsState;
    public isAdmin: boolean = false;
    public isCompany: boolean = false;
    public dataSource: MatTableDataSource<ITaxAdjustment>;
    public displayedColumns
    public taxadjustmentsEdited: boolean = false;
    @ViewChild('f', { static: false }) form;
    validationErrorMessage: string;
    countries = [];
    public appId: number = env.appId;
    personalNumberPattern = "[0-9]{12}";
    selectedNotificationTypes = [];
    CustomServiceFee = 0;
    Commission = 0;
    PensionSettingTypeId = 1;
    
    
  themes = [
    { value: 'DEFAULT-THEME', label: 'Blue' },
    { value: 'LIGHT-THEME', label: 'Light' },
    { value: 'NATURE-THEME', label: 'Nature' },
    { value: 'BLACK-THEME', label: 'Dark' }
  ];

  user: IUser;
  isLoading: boolean = true;
  municipalities: any;
  units: any;
  pensionSavingsCompanies: any;



  constructor(
      private router: Router,
      private store: Store<any>,
      private userService: UserService,
      private route: ActivatedRoute,
      private municipalityService: MunicipalityService,
      private loadingService: LoadingBarService,
      public snackBar: MatSnackBar,
      public dialog: MatDialog
  ) {

  }
  
  ngOnInit() {
      this.loadingService.start();
      this.loadUser();
      this.loadMunicipalities();
      this.loadPensionSavingsCompanies();
      this.isAdmin = this.userService.userHasRole("admin");
      this.isCompany = this.userService.userHasRole("companyuser");
      this.setTableColumns(this.isAdmin);
      this.getCountries();
  }

  notificationSettingsChange(selectedItems) {

    // Loop all settings
    for (var i = 0; i < this.user.AccountSettings.NotificationSettings.length; i++) {
        var setting = this.user.AccountSettings.NotificationSettings[i];
      // Loop selected setting
      setting.Active = false;

        for (var e = 0; e < selectedItems.length; e++) {
          var currentSettingId = selectedItems[e];
          if (currentSettingId == setting.Type.Id)
            setting.Active = true; // Selected
        }
    }
  }

  setSelectedNotificationSettings() {

    var selectedNotifications = [];

    // Loop all settings
    for (var i = 0; i < this.user.AccountSettings.NotificationSettings.length; i++) {
      var setting = this.user.AccountSettings.NotificationSettings[i];

      // Loop selected setting
      if (setting.Active) {
        this.selectedNotificationTypes.push(setting.Type.Id);
      }
    }
  }

  canDeactivate(): boolean {

      if (this.form == null)
      {
          return true;
      }
      
      return this.form.dirty || this.taxadjustmentsEdited
          ? confirm('Vill du avsluta utan att spara?')
          : true;
  }

    setTableColumns(isAdmin: boolean) {
        if (isAdmin) {
            this.displayedColumns = ['type', 'startdate', 'enddate', 'maxamount', 'temptax', 'attachment'];
        }
        else {
          this.displayedColumns = ['type', 'startdate', 'enddate', 'temptax', 'attachment'];
        }
    }

  loadUser()
  {
      this.userService.getCurrentUser()
          .subscribe(value => this.userLoaded(value),
          error => console.log(error)
      );
  }

  loadMunicipalities()
  {
      this.municipalityService.getMunicipalities()
          .subscribe(value => this.municipalitiesLoaded(value),
          error => console.log(error)
          );
  }

  municipalitiesLoaded(municipalities: any) {
    this.municipalities = municipalities
  }

  loadUnits() {
      this.municipalityService.getMunicipalities()
        .subscribe(value => this.municipalitiesLoaded(value),
          error => console.log(error)
        );
  }

  unitsLoaded(units: any) {
    this.units = units;
  }

  loadPensionSavingsCompanies() {
    this.userService.getPensionSavingsCompanies()
      .subscribe(value => this.pensionSavingsCompaniesLoaded(value),
        error => console.log(error)
      );
  }

  pensionSavingsCompaniesLoaded(pensionSavingsCompanies: any) {
    this.pensionSavingsCompanies = pensionSavingsCompanies;
  }

  pensionSavingsCompanyChanged(event: any) {
    
    if (event == 1) {
      this.user.AccountSettings.PensionSavingsSettings.PensionSavingsCompany.Id = 1;
      this.user.AccountSettings.PensionSavingsSettings.PensionSavingsCompany.TechnicalName = "SPP";
    }
    else {
      this.user.AccountSettings.PensionSavingsSettings.PensionSavingsCompany.Id = 2;
      this.user.AccountSettings.PensionSavingsSettings.PensionSavingsCompany.TechnicalName = "Avanza";
    }
    debugger
  }

  pensionSavingsTypeChanged(event: any) {

    if (event == 1) {
      this.user.AccountSettings.PensionSavingsSettings.Unit.Id = 1;
      this.user.AccountSettings.PensionSavingsSettings.Unit.TechnicalName = "Percent";
    }
    else {
      this.user.AccountSettings.PensionSavingsSettings.Unit.Id = 2;
      this.user.AccountSettings.PensionSavingsSettings.Unit.TechnicalName = "Share";
    }
    
  }

  addTaxAdjustment(taxAdjustment: ITaxAdjustment) {

      this.config.data.user = this.user;
        
      let dialogRef = this.dialog.open(EditTaxAdjustmentDialogComponent, this.config);
        
      
      dialogRef.afterClosed().subscribe((result: ITaxAdjustment) => {
            
          if (result) {
            if (this.user.AccountSettings.TaxAdjustments == null)
                  this.user.AccountSettings.TaxAdjustments = [];
              this.user.AccountSettings.TaxAdjustments.push(result);
              this.dataSource = new MatTableDataSource(this.user.AccountSettings.TaxAdjustments);
              this.taxadjustmentsEdited = true;
          }

          dialogRef = null;
      });

     
  }

  deleteTaxAdjustment(taxAdjustment: any) {
        
      for (var i = 0; i < this.user.AccountSettings.TaxAdjustments.length; i++) {
          var d = this.user.AccountSettings.TaxAdjustments[i];

          if (d.Guid == taxAdjustment.Guid ) {
              this.user.AccountSettings.TaxAdjustments.splice(i, 1);
              this.dataSource = new MatTableDataSource(this.user.AccountSettings.TaxAdjustments);
              return;
          }
      }
  }

  changeCoordinationNumberValue(value) {
    this.user.AccountSettings.HasCoordinationNumber = value.checked;
  }

  saveValidates() {
    
    //Email address must be given
    if (this.user.AccountSettings.Email == null || this.user.AccountSettings.Email == "") {
      this.validationErrorMessage = "Du måste ange en mailadress";
      return false;
    }

    if (this.user.AccountSettings.IsCompany) {

      //Country must be set
      if (this.user.AccountSettings.Country.Name == null) {
        this.validationErrorMessage = "Du måste ange ett land";
        return false;
      }           

      //If Sweden
      if (this.user.AccountSettings.Country.Name == 'Sverige') {

        //Orgnr must be given
        if (this.user.AccountSettings.OrgNumber == null || this.user.AccountSettings.OrgNumber == "") {
          this.validationErrorMessage = "Du måste ange ett organisationsnummer";
          return false;
        }

        //Bank account, Bankgiro or Plusgiro must be used
        if (((this.user.AccountSettings.BankName == null || this.user.AccountSettings.BankName == "") || (this.user.AccountSettings.AccountNumber == null || this.user.AccountSettings.AccountNumber == "") || (this.user.AccountSettings.ClearingNumber == null || this.user.AccountSettings.ClearingNumber == "")) && (this.user.AccountSettings.Bankgiro == null || this.user.AccountSettings.Bankgiro == "") && (this.user.AccountSettings.Plusgiro == null || this.user.AccountSettings.Plusgiro == "")) {
          this.validationErrorMessage = "Du måste ange bankuppgifter, Bankgiro eller Plusgiro";
          return false;
        }
      }

      //If not Sweden
      if (this.user.AccountSettings.Country.Name != null && this.user.AccountSettings.Country.Name != 'Sverige') {

        if (this.user.AccountSettings.CompanyName == null || this.user.AccountSettings.CompanyName == "") {
          this.validationErrorMessage = "Du måste ange ett namn";
          return false;
        }

        if (this.user.AccountSettings.Address == null || this.user.AccountSettings.Address == "") {
          this.validationErrorMessage = "Du måste ange en gatuadress";
          return false;
        }

        if (this.user.AccountSettings.Zip == null || this.user.AccountSettings.Zip == "") {
          this.validationErrorMessage = "Du måste ange ett postnummer";
          return false;
        }

        if (this.user.AccountSettings.City == null || this.user.AccountSettings.City == "") {
          this.validationErrorMessage = "Du måste ange en stad";
          return false;
        }

        if (this.user.AccountSettings.Iban == null || this.user.AccountSettings.Iban == "") {
          this.validationErrorMessage = "Du måste ange IBAN";
          return false;
        }

        if (this.user.AccountSettings.Bic == null || this.user.AccountSettings.Bic == "") {
          this.validationErrorMessage = "Du måste ange BIC";
          return false;
        }

        else {
          this.validationErrorMessage = ""
          return true;
        }

      }

      //If not Sweden, but EU country
      if (this.user.AccountSettings.Country.Eu && this.user.AccountSettings.Country.Name != 'Sverige') {

        //Orgnr must be given
        if (this.user.AccountSettings.Vat == null || this.user.AccountSettings.Vat == "") {
          this.validationErrorMessage = "Du måste ange VAT";
          return false;
        }
      }

      else {
        this.validationErrorMessage = ""
        return true;
      }

    }

    if (!this.user.AccountSettings.IsCompany) {
      if (this.user.AccountSettings.FirstName == null || this.user.AccountSettings.FirstName == "") {
        this.validationErrorMessage = "Du måste ange förnamn";
        return false;
      }

      if (this.user.AccountSettings.LastName == null || this.user.AccountSettings.LastName == "") {
        this.validationErrorMessage = "Du måste ange efternamn";
        return false;
      }

      if (this.user.AccountSettings.Address == null || this.user.AccountSettings.Address == "") {
        this.validationErrorMessage = "Du måste ange adress";
        return false;
      }

      var regexPn = /[0-9]{12}/g;
      var pnIsValid = regexPn.test(this.user.AccountSettings.PersonalNumber);
      
      if (!pnIsValid) {
        this.validationErrorMessage = "Personnumret måste innehålla 12 siffror utan bindestreck";
        return false;
      }

      if (this.user.AccountSettings.HasCoordinationNumber == true) {
        var regexCn = /[0-9]{4}/g;
        var cnIsValid = regexCn.test(this.user.AccountSettings.BirthYear);
        
        if (!cnIsValid) {
          this.validationErrorMessage = "Du har angivit att du har samordningsnummer, och måste ange ett födelseår med fyra siffror";
          return false;
        }        
      }

      if (this.user.AccountSettings.Zip == null || this.user.AccountSettings.Zip == "") {
        this.validationErrorMessage = "Du måste ange postnummer";
        return false;
      }

      if (this.user.AccountSettings.City == null || this.user.AccountSettings.City == "") {
        this.validationErrorMessage = "Du måste ange ort";
        return false;
      }

      if (this.user.AccountSettings.Municipality == null) {
        this.validationErrorMessage = "Du måste ange kommun";
        return false;
      }

      //Bank account, Bankgiro or Plusgiro must be used
      if ((this.user.AccountSettings.BankName == null || this.user.AccountSettings.BankName == "") || (this.user.AccountSettings.AccountNumber == null || this.user.AccountSettings.AccountNumber == "") || (this.user.AccountSettings.ClearingNumber == null || this.user.AccountSettings.ClearingNumber == "")) {
        this.validationErrorMessage = "Du måste ange bankuppgifter";
        return false;
      }            

      else
      {
        this.validationErrorMessage = ""
        return true;
      }

    }
  }

  getCountries() {
    this.userService.getCountries()
      .subscribe(value => this.countries = value,
        error => this.onError(error)
      );
  }

  onError(error: any) {
    alert('An error occured');
    console.log(error);
  }

  getCountryName(countryId: number) {
    for (var i = 0; i < this.countries.length; i++) {
      if (this.countries[i].Id == countryId) {

        this.user.AccountSettings.Country.Name = this.countries[i].Name;
        this.user.AccountSettings.Country.Eu = this.countries[i].Eu;
      }
    }
  }

  userLoaded(user: IUser)
  {    
    this.user = user;
    this.dataSource = new MatTableDataSource(user.AccountSettings.TaxAdjustments);
    this.isLoading = false;
    this.loadingService.complete();
    this.setSelectedNotificationSettings();
    this.CustomServiceFee = user.AccountSettings.CustomServiceFee * 100;
    this.Commission = user.AccountSettings.Commission * 100;
    debugger
    if (user.AccountSettings.PensionSavingsSettings.PensionSavingsCompany == null) {
      this.pensionSavingsCompanyChanged(1);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSubmit()
  {
    this.user.AccountSettings.Commission = this.Commission / 100;

    this.isLoading = true;
    this.userService.updatetUser(this.user)
        .subscribe(
        res => this.userUpdated(res),
        err => this.onUserError(err),
            () => console.log('HTTP request completed.')
        );
  }

  selectedAppId() {
    if (this.appId == 1) {
      this.isCompany == false;
    }

    if (this.appId == 2) {
      this.isCompany == true;
    }
  }

  userUpdated(response: any)
  {
      this.router.navigate(['/']);
  }

  onUserError(errorMessage: any)
  {
      this.isLoading = false;
      this.showMessage(errorMessage);
  }

  showMessage(message: string) {
      let config = new MatSnackBarConfig();
      config.duration = 7000;
      this.snackBar.open(message, undefined, config);
  }

  showFile(file: IFile) {
        window.open(file.Url);
  }

  municipalityChange(municipalityId: any)
  {
    for (var i = 0; i < this.municipalities.length; i++)
    {
      if (this.municipalities[i].Id == municipalityId)
      {
          this.user.AccountSettings.Municipality.Tax = this.municipalities[i].Tax;
      }
    }      
  }

  onThemeSelect({ value: theme }) {
    this.store.dispatch(new ActionSettingsChangeTheme({ theme }));
    this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
  }

  onAutoNightModeToggle({ checked: autoNightMode }) {
    this.store.dispatch(
      new ActionSettingsChangeAutoNightMode({ autoNightMode })
    );
    this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
  }

  onPageAnimationsToggle({ checked: pageAnimations }) {
    this.store.dispatch(
      new ActionSettingsChangeAnimationsPage({ pageAnimations })
    );
    this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
  }

  onElementsAnimationsToggle({ checked: elementsAnimations }) {
    this.store.dispatch(
      new ActionSettingsChangeAnimationsElements({ elementsAnimations })
    );
    this.store.dispatch(new ActionSettingsPersist({ settings: this.settings }));
    }

    config = {
        disableClose: false,
        panelClass: 'full-screen-modal',
        hasBackdrop: true,
        backdropClass: '',
        width: '500px',
        maxWidth: '100vw',
        height: '100%',
        position: {
            top: '',
            bottom: '',
            left: '',
            right: ''
        },
        data: {
            invoiceItemGroup: {},
            customerTemplate: {},
            customer: {},
            contact: {},
            companyId: 0,
            status: {},
            user: {} as IUser
        },
        dataTemplate: {
            Id: 0,
            Country: { Id: 1 },
            Type: {},
            HoursWorked: undefined,
            StartDate: undefined,
            EndDate: undefined,
            Comment: undefined,
            InvoiceId: 0
        }
    };

}
