<style>
  .accent {
    color: #E91E63;
  }

  .invoicevalue {
    text-align: right;
  }

  .pinkborder{
    border-top: 1px solid pink;
    border-bottom: 1px solid pink;
    padding: 3px 0;
  }

  .pinkbordertop {
    border-top: 1px solid pink;
    padding: 3px 0;
    margin-top: 15px;
  }

  .extrapadding
  {
    padding: 15px;
  }

  .marginbottom {
   margin-bottom: 20px;
  }

  .margintop {
   margin-top: 20px;
  }

  .bold
  {
    font-weight: bold;
  }

  .infotext {
    font-style: italic;
    font-size: 90%;
  }

  .iconPreli {
    font-size: 14px;
    margin-bottom: -14px;
  }

  p {
    margin: 5px 0;
  }

</style>
<div mat-dialog-content>
  <div class="container">

    <div class="row col-lg-12 col-sm-12 marginbottom">
      <div class="col-sm-12 col-lg-6">
        <h3 *ngIf="TransactionLog?.IsPayoutTransLog == false">Lönegrundande belopp</h3>
        <h3 *ngIf="TransactionLog?.IsPayoutTransLog == true">Total utbetalning</h3>
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom">
      <div class="col-sm-12 col-lg-6">
        <b>Fakturanummer:</b> {{ data.InvoiceNumber }}
      </div>
      <div class="col-sm-12 col-lg-6">
        <b>Kund:</b> {{ data.Customer.Name }}
      </div>
      <div style="margin-top: 20px;" *ngIf="data.FeeDescription != null" class="col-sm-12 col-lg-12">
        <strong>Meddelande från löneadministratör:</strong> {{ data?.FeeDescription }}
      </div>
    </div>

    <!--Information shown if transaction log is preliminary -->
    <div class="row col-lg-12 col-sm-12 pinkbordertop extrapadding" *ngIf="TransactionLog?.IsPreliminary == true">
      <div class="col-sm-12 col-lg-12" *ngIf="TransactionLog?.IsPayoutTransLog == false">
        <strong>Detta är en preliminär beräkning av ditt lönegrundande belopp.</strong><br />
        Nedan ser du hur stor ditt lönegrundande belopp (bruttolön plus eventuella avdrag/reseersättningar) blir, förutsatt att innehållet i fakturan är korrekt. <span *ngIf="TransactionLog?.ExchangeRateInvoiceSent != 0">Eftersom din faktura är skickad i utländsk valuta kan summan komma att ändras beroende på vad växelkursen står i när din kund betalar.</span> Vi kontaktar dig om något blivit fel.<br /><br />
        Din faktura förfaller {{ TransactionLog?.PaymentDue | date: 'yyyy-MM-dd' }}, och du kan förvänta dig pengarna på kontot ca 2 arbetsdagar efter det.  Om du valt express kommer utbetalningen att göras så fort vi fått fakturan bekräftad från din kund.


      </div>
      <div class="col-sm-12 col-lg-12" *ngIf="TransactionLog?.IsPayoutTransLog == true">
        <strong>Detta är en preliminär beräkning av din totala utbetalning.</strong><br />
        Nedan ser du hur stor din utbetalning blir, förutsatt att innehållet i fakturan är korrekt. Vi kontaktar dig om något blivit fel.<br /><br />
        Din faktura förfaller {{ TransactionLog?.PaymentDue | date: 'yyyy-MM-dd' }}, och du kan förvänta dig pengarna på kontot ca 2 arbetsdagar efter det. Om du valt express kommer utbetalningen att göras så fort vi fått fakturan bekräftad från din kund.
      </div>
    </div>

    <!--Information shown if trans log is a payment log-->
    <div *ngIf="TransactionLog?.IsPayoutTransLog == false">
      <div class="row col-sm-12 col-lg-12 infotext extrapadding pinkbordertop">
        <p>
          Det belopp du fakturerade din kund var {{ TransactionLog?.TotalInvoiceAmount  | number}} {{ data.Currency?.Name }}.
          <span *ngIf="data.ExchangeRateInvoiceSent != 0">
            Valutakursen när fakturan skickades var {{ data.ExchangeRateInvoiceSent }} SEK per {{ data.Currency?.Name }}. Om växelkurserna ändras från att fakturan skickas tills att den betalas
            ändras också värdet på fakturan. Din avgift till SAMpoolen räknas alltid på fakturans värde när den skickades.
          </span>
        </p>
        <p *ngIf="TransactionLog?.TotalVatAmount > 0">
          Vi har lagt på<span *ngIf="TransactionLog?.InvoiceFee > 0"> kundens fakturaavgift på {{ TransactionLog?.InvoiceFee | number}} SEK och</span> {{ TransactionLog?.TotalVatAmount }} SEK
          i moms för ditt fakturerade belopp<span *ngIf="TransactionLog?.InvoiceFee > 0"> och fakturaavgiften</span>. Den totala summan din kund ska betala är {{ data?.PaidTotal | number: '1.0-0'}} SEK.
        </p>
      </div>
    </div>
    <!---->
    <!--Information shown if trans log is a payment log, not preliminary -->
    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.IsPayoutTransLog == true">
      <div class="col-sm-12 col-lg-12 extrapadding pinkbordertop">
        <p>
          Summan vi beräknar din utbetalning från är {{ TransactionLog?.PartialTotalAmount | number }} SEK. <span *ngIf="!data.SubInvoice">Om du vill se hur vi kommit fram till den klickar du på datumet för inbetalningsdatum.</span>
        </p>
        <p>
          Från ditt lönegrundande belopp har vi dragit sociala avgifter (arbetsgivaravgiften). <a href="https://www.skatteverket.se/foretag/arbetsgivare/arbetsgivaravgifterochskatteavdrag/arbetsgivaravgifter" target="_blank" class="accent">Läs mer om arbetsgivaravgifter hos Skatteverket</a>.<br />
        </p>
        <p *ngIf="TransactionLog?.DeductionsTotalAmount >0 || TransactionLog?.AllowancesTotalAmount >0 || TransactionLog?.MileageTotalAmount >0">
          Om du angivit utlägg, reseersättningar och traktamenten får du tillbaka dem skattefritt. De dras bort från det beloppet vi beräknar din utbetalning ifrån för att få fram ditt lönegrundande belopp. Din totala utbetalning blir alltså nettolön plus eventuella utlägg / reseersättningar.
        </p>
        <p *ngIf="TransactionLog?.IsPreliminary == false"><span>Vi betalade pengarna den {{ TransactionLog?.PayoutDate | date: 'yyyy-MM-dd' }}</span><span *ngIf="TransactionLog.BankName != null"> till ditt konto {{TransactionLog?.ClearingNumber}} {{TransactionLog?.AccountNumber}} i {{TransactionLog?.BankName}}</span>.</p>
      </div>
    </div>
    <!---->

    <div class="row col-lg-12 col-sm-12 marginbottom pinkborder" *ngIf="TransactionLog?.IsPayoutTransLog == false">
      <div class="col-sm-12 col-lg-8">
        Delsumma efter avgifter:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent bold">{{ TransactionLog?.PartialTotalAmount | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom margintop pinkborder" *ngIf="TransactionLog?.IsPayoutTransLog == true">
      <div class="col-sm-12 col-lg-8 bold">
        Total utbetalning:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent bold">{{ TransactionLog?.TotalPayout | number: '1.0-2' }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom">
      <div class="col-sm-12 col-lg-8">
        <h4>Så här har vi räknat</h4>
      </div>
    </div>

    <!--Information shown in incoming transaction log popup -->



    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.TotalInvoiceAmount != 0">
      <div class="col-sm-12 col-lg-8">
        Ditt fakturerade belopp, exkl moms
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ TransactionLog?.TotalInvoiceAmount  | number}}</span> {{ data.Currency?.Name }}
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.ExchangeRateInvoiceSent != 0 && TransactionLog?.IsPayoutTransLog == false">
      <div class="col-sm-12 col-lg-8">
        Växlingskurs när du skickade din faktura
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ TransactionLog?.ExchangeRateInvoiceSent | number}}</span> SEK = 1 {{ data.Currency?.Name }}
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom" *ngIf="TransactionLog?.TotalInvoiceAmountIncVat != 0 && TransactionLog?.ExchangeRateInvoiceSent == 1">
      <div class="col-sm-12 col-lg-8">
        Totalt fakturerat, inkl moms:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue" *ngIf="TransactionLog?.ExchangeRateInvoiceSent == 1">
        <span class="accent">{{ TransactionLog?.TotalInvoiceAmountIncVat | number}}</span> SEK
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue" *ngIf="TransactionLog?.ExchangeRateInvoiceSent != 0">
        <span class="accent">{{ TransactionLog?.TotalInvoiceAmount  | number}}</span> {{ data.Currency?.Name }}
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" style="font-style: italic;" *ngIf="TransactionLog?.ExchangeRateInvoiceSent != 0 && TransactionLog?.IsPayoutTransLog == false">
      <div class="col-sm-12 col-lg-9">
        Fakturans värde när den skickades (exkl fakturaavgift)
      </div>
      <div class="col-sm-12 col-lg-3 invoicevalue">
        <span class="accent">{{ TransactionLog?.ExchangeRateInvoiceSent * TransactionLog?.TotalInvoiceAmount | number}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.InvoiceFeeReceiver > 0">
      <div class="col-sm-12 col-lg-8">
        Fakturaavgift, kund:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">29</span> SEK
      </div>
    </div>   

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.ExchangeRateInvoiceSent != 0 && TransactionLog?.IsPayoutTransLog == false && TransactionLog?.IsPreliminary == false">
      <div class="col-sm-12 col-lg-8">
        Växlingskurs när din kund betalade fakturan
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ data?.ExchangeRateInvoicePaid | number}}</span> SEK = 1 {{ data.Currency?.Name }}
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.IsPayoutTransLog == false">
      <div class="col-sm-12 col-lg-8" *ngIf="TransactionLog?.IsPreliminary == true">
        Vad din kund ska betala (inkl fakturaavgift):
      </div>
      <div class="col-sm-12 col-lg-8" *ngIf="TransactionLog?.IsPreliminary == false">
        Totalt inbetalt från kund:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent"> + {{ TransactionLog?.PaidTotal  | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.ExchangeRateInvoicePaid != 0 && TransactionLog?.IsPayoutTransLog == false">
      <div class="col-sm-12 col-lg-8">
        Växlingskurs när kunden betalade din faktura
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ TransactionLog?.ExchangeRateInvoicePaid | number}}</span> SEK = 1 {{ data.Currency?.Name }}
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.TotalVatAmount > 0">
      <div class="col-sm-12 col-lg-8">
        Avdragen moms:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ TransactionLog?.TotalVatAmount }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.InvoiceFee > 0 && TransactionLog?.InvoiceFeePaidByCustomer == true">
      <div class="col-sm-12 col-lg-8">
        Fakturaavgift från kund:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ TransactionLog?.InvoiceFee | number}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom margintop" *ngIf="TransactionLog?.IsPayoutTransLog == false">
      <div class="col-sm-12 col-lg-8">
        <strong>Dina avgifter:</strong>
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
      </div>
    </div>


    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.InvoiceFeeMember > 0 && TransactionLog?.IsPayoutTransLog == true">
      <div class="col-sm-12 col-lg-8">
        Din fakturaavgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ TransactionLog?.InvoiceFeeMember | number}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.InvoiceFee > 0 && TransactionLog?.InvoiceFeePaidByCustomer == false && TransactionLog?.IsPayoutTransLog == false && TransactionLog?.IsPreliminary == false">
      <div class="col-sm-12 col-lg-8">
        Obetald fakturaavgift, kund: <mat-icon class="iconPreli" color="accent" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Din kund har inte betalt fakturaavgiften, och avgiften har därför dragits från totalbeloppet">info</mat-icon>

      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ TransactionLog?.InvoiceFee | number}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.ServiceFee > 0">
      <div class="col-sm-12 col-lg-8">
        Din avgift till SAMpoolen:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ TransactionLog?.ServiceFee }}</span> SEK
      </div>
    </div>

    <!--<div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.ServiceFee > 0 || !data.ExpressPayOut && TransactionLog?.IsPayoutTransLog == false" style="font-style: italic; font-size: 85%;">
      <div class="col-sm-12 col-lg-8">
        (Ordinarie avgift till SAMpoolen hade varit:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ TransactionLog?.ServiceFee * 1.475 | number: '1.0-0' }}</span> SEK)
      </div>
    </div>-->

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.ExchangeFee > 0">
      <div class="col-sm-12 col-lg-8">
        Bankavgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ TransactionLog?.ExchangeFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.ReminderFee > 0">
      <div class="col-sm-12 col-lg-8">
        Påminnelseavgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ TransactionLog?.ReminderFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.InkassoFee > 0">
      <div class="col-sm-12 col-lg-8">
        Inkassoavgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ TransactionLog?.InkassoFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.KronofogdenFee > 0">
      <div class="col-sm-12 col-lg-8">
        Kronofogdeavgift:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ TransactionLog?.KronofogdenFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.CustomFee > 0">
      <div class="col-sm-12 col-lg-8 marginbottom">
        Annan avgift (se kommentar):
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ TransactionLog?.CustomFee }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.ManualDiscount > 0 && TransactionLog?.IsPayoutTransLog == false">
      <div class="col-sm-12 col-lg-8">
        Rabatt / gåva / kompensation (se kommentar):
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">+ {{ TransactionLog?.ManualDiscount  | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom margintop" *ngIf="TransactionLog?.IsPayoutTransLog == false">
      <div class="col-sm-12 col-lg-8 bold">
        Delsumma efter avgifter:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ TransactionLog?.PartialTotalAmount | number }}</span> SEK
      </div>
    </div>

    <!--Information shown in outgoing transaction log popup -->

    <div class="row col-lg-12 col-sm-12 marginbottom margintop" *ngIf="TransactionLog?.IsPayoutTransLog == true">
      <div class="col-sm-12 col-lg-8">
        Delsumma efter avgifter:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ TransactionLog?.PartialTotalAmount | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.PensionSavingsTaxAmount > 0">
      <div class="col-sm-12 col-lg-8">
        Pensionsavsättning ({{TransactionLog?.PensionSavings}} SEK, plus {{TransactionLog?.PensionSavingsTaxAmount}} SEK i skatt):
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ TransactionLog?.PensionSavings + TransactionLog?.PensionSavingsTaxAmount | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 margintop marginbottom" *ngIf="TransactionLog?.DeductionsTotalAmount > 0">
      <div *ngIf="TransactionLog?.DeductionsTotalAmount >0 || TransactionLog?.AllowancesTotalAmount >0 || TransactionLog?.MileageTotalAmount >0">
        <div class="col-sm-12 col-lg-12">
          <i>Utlägg, reseersättningar och traktamenten får du tillbaka skattefritt. De dras bort från beloppet ovan för att få fram ditt lönegrundande belopp. </i>
        </div>
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.DeductionsTotalAmount > 0">
      <div class="col-sm-12 col-lg-8">
        Privata utlägg:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ TransactionLog?.DeductionsTotalAmount | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.AllowancesTotalAmount > 0">
      <div class="col-sm-12 col-lg-8">
        Traktamenten:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ TransactionLog?.AllowancesTotalAmount | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.MileageTotalAmount > 0">
      <div class="col-sm-12 col-lg-8">
        Milersättning:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ TransactionLog?.MileageTotalAmount | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.ExchangeRateInvoicePaid != 0 && TransactionLog?.IsPayoutTransLog == true">
      <div class="col-sm-12 col-lg-8">
        Växlingskurs när kunden betalade in din faktura
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ TransactionLog?.ExchangeRateInvoicePaid | number}}</span> SEK = 1 {{ data.Currency?.Name }}
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 margintop" *ngIf="TransactionLog?.TotalPayout > 0">
      <div class="col-sm-12 col-lg-8 bold">
        Totalt lönegrundande belopp:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent bold">{{ TransactionLog?.PartialTotalAmount - TransactionLog?.DeductionsTotalAmount - TransactionLog?.MileageTotalAmount - TransactionLog?.AllowancesTotalAmount - (TransactionLog?.PensionSavings + TransactionLog?.PensionSavingsTaxAmount)| number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.EmployerTaxAmount > 0">
      <div class="col-sm-12 col-lg-8">
        Sociala avgifter:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ TransactionLog?.EmployerTaxAmount | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 margintop bold" *ngIf="TransactionLog?.GrossSalary > 0">
      <div class="col-sm-12 col-lg-8">
        Bruttolön:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ TransactionLog?.GrossSalary | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom" *ngIf="TransactionLog?.MunicipalityTaxAmount > 0">
      <div class="col-sm-12 col-lg-8">
        Kommunalskatt:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">- {{ TransactionLog?.MunicipalityTaxAmount | number: '1.0-2'}}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 bold" *ngIf="TransactionLog?.NetSalary > 0">
      <div class="col-sm-12 col-lg-8">
        Nettolön:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ TransactionLog?.NetSalary | number: '1.0-2' }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12" *ngIf="TransactionLog?.DeductionsTotalAmount >0 || TransactionLog?.AllowancesTotalAmount >0 || TransactionLog?.MileageTotalAmount >0">
      <div class="col-sm-12 col-lg-8">
        Summa utlägg och reseersättningar:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent">{{ TransactionLog?.DeductionsTotalAmount + TransactionLog?.AllowancesTotalAmount + TransactionLog?.MileageTotalAmount | number }}</span> SEK
      </div>
    </div>

    <div class="row col-lg-12 col-sm-12 marginbottom margintop bold pinkbordertop" *ngIf="TransactionLog?.TotalPayout > 0">
      <div class="col-sm-12 col-lg-8">
        Total utbetalning:
      </div>
      <div class="col-sm-12 col-lg-4 invoicevalue">
        <span class="accent bold">{{ TransactionLog?.TotalPayout | number: '1.0-2' }}</span> SEK
      </div>
    </div>
  </div>

  </div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Stäng</button>
</div>
