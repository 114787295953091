import { Component, OnInit } from '@angular/core';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpModule, Headers, RequestOptions } from '@angular/http';
import { AuthenticationService, User } from "../../../core/services/AuthenticationService"
import { AutoLogoutService } from "../../../core/services/AutoLogoutService"
import { UserService } from '../../../core/services/UserService';
import { LocalStorageService } from '../../../core/local-storage/local-storage.service'
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { environment as env } from '@env/environment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'anms-login-emailpass',
  templateUrl: './login-emailpass.component.html',
  styleUrls: ['./login-emailpass.component.css']
})
export class LoginEmailpassComponent implements OnInit {

    public user = {} as User;
    routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
    versions = env.versions;
    public appUrl = env.hostBase;
    public errorMsg = '';
    loading = false;
    returnUrl: string;
    isAdmin: boolean = false;
    message: string = 'Felaktigt lösenord!';  
    actionButtonLabel: string = 'Retry';
    action: boolean = false;
    setAutoHide: boolean = true;
    autoHide: number = 10000;
    autoStartToken: string = null;
    redirectToInvoiceId = null;
    loginType: string = null;
  logOut: boolean = false;
  public hideEmailForm: boolean = false;
    
    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,
        private route: ActivatedRoute,
        public snackBar: MatSnackBar,
        public userService: UserService,
      public autoLogoutService: AutoLogoutService,
      private localStorageService: LocalStorageService,
      private deviceService: DeviceDetectorService
  ) { }

  ngOnInit() {

    this.hideEmailForm = this.invoiceEditInProgress();

    this.route.queryParams.subscribe(params => {
      this.autoStartToken = params['bid'] || null;
      //this.loginType = params['logintype'] || null;
      this.logOut = params['logout'] || false;

      // Allready at login page so just delete storage and not the full process
      if (this.logOut)
        this.authenticationService.clearLoginStorage();

      // BankID-inloggning
      if (this.autoStartToken != null) {
        this.loginWithBankIdToken();
      }
      else {
        this.returnUrl = params['returnUrl'] || '/';
      }

    });
    
  }

    onSubmit()
    {
          this.loginPassword();
    }

    loginPassword() {

        this.loading = true;
        this.authenticationService.login(this.user.username, this.user.password)
            .subscribe(
                data => {
                this.loading = false;

                // login successful so redirect to return url
                this.userService.setHttpOptions();
                this.router.navigateByUrl(this.returnUrl);

                },
                error => {
                    this.loading = false;
                });
  }

  loginWithBankIdToken() {
    this.loading = true;
    
    this.authenticationService.login(this.user.username, this.user.password, this.autoStartToken)
      .subscribe(
        data => {
          this.loading = false;
          // login successful so redirect to return url
          this.userService.setHttpOptions();

          this.loginFinished();

        },
        error => {
          alert('Ett fel inträffade! ' + error);
          this.loading = false;
        });
  }

  loginFinished() {

    // Edit invoice parameter found
    if (this.invoiceEditInProgress()) {
      var invoiceEditId = this.invoiceEditInProgressId();
      this.invoiceEditInProgressClear();
      this.router.navigate(['/invoice-tool/' + invoiceEditId], { queryParams: { showExpressDialog: 'true' } });
    }
    else {
      this.router.navigateByUrl(this.returnUrl);
    }
  }

  invoiceEditInProgress() {
    return this.localStorageService.getItem("editInvoice") != null && this.localStorageService.getItem("editInvoice") != 'null' && this.localStorageService.getItem("editInvoice") != 'undefined' ? true : false;
  }

  invoiceEditInProgressId() {
    return this.localStorageService.getItem("editInvoice");
  }

  invoiceEditInProgressClear() {
    return this.localStorageService.removeItem("editInvoice");
  }


  loginBankId(openOnAnotherUnit: boolean) {

    debugger

    if (openOnAnotherUnit) {
      // Starta BankId på annan enhet
      this.loading = true;
      this.authenticationService.login(null, null, null, this.user.PersonalNumber)
        .subscribe(
          data => {
            this.loading = false;

            // login successful so redirect to return url
            this.userService.setHttpOptions();

            this.loginFinished();

          },
          error => {
            this.loading = false;
          });
    }
    else {
      // Starta bankID på samma enhet kräver att sessionen lagras för att läsas upp när man kommer tillbaka till sidan
      this.loading = true;
      this.authenticationService.loginBankIdPassive(this.user.PersonalNumber)
        .subscribe(
          data => {
            // login successful so redirect to return url
            var returnUrl = this.appUrl + "/#/login?bid=" + data;
            var bankIdAuthUrl = "https://app.bankid.com/?autostarttoken=" + data + "&redirect=" + returnUrl;
            window.location.href = bankIdAuthUrl;
          },
          error => {
            this.loading = false;
          });
    }
  }

    openLink(link: string) {
        window.open(link, '_blank');
    }
}
