
import { Injectable } from '@angular/core';
//import { Http, HttpModule, Headers, Response } from '@angular/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
//import { BrowserModule } from '@angular/platform-browser';
import { environment as env } from '@env/environment';
import { HttpHeaders } from '@angular/common/http';
import { tap } from "rxjs/internal/operators/tap";
import { AuthGuardService } from '../auth/auth-guard.service';
import { Store } from '@ngrx/store';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

import {
    ActionAuthLogin,
    ActionAuthLogout
} from '@app/core';
import { validateBasis } from '@angular/flex-layout';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable()
export class AuthenticationService {
    public token: string;
    private userString: string;
    private apiBaseUrl: string;
    private apiAuthUrl: string;
    private httpOptions: any;
    private authGuardService: AuthGuardService;
    public userData: any;
    
    constructor(
        private http: HttpClient,
        authGuardService: AuthGuardService,
        private localStorageService: LocalStorageService,
        private store: Store<any>,
        private router: Router,
        public snackBar: MatSnackBar)
    {

        this.authGuardService = authGuardService;
        this.setHttpOptions();
        var userData = JSON.parse(localStorage.getItem('ANMS-AUTH'));

        if (userData != undefined && userData.token != undefined) {
            
            this.token = userData.token.access_token;
            this.userData = userData
        }

        this.apiBaseUrl = env.hostApi;
        this.apiAuthUrl = env.hostAuth;
    }


    private subject = new Subject<any>();

    userUpdated(message: string) {
        this.subject.next({ text: message });
    }

    getUserData(): Observable<any> {
        return this.subject.asObservable();
    }
    
    login(username: string = null, password: string = null, bankIdStartToken: string = null, bankIdpersonalNumber: string = null): Observable<boolean> {
        
      let data = "grant_type=password&username=" + username + "&password=" + password;
      var url = this.apiAuthUrl;

      if (bankIdStartToken != null && bankIdStartToken.length > 10)
        url = url + "?autoStartToken=" + bankIdStartToken

      if (bankIdpersonalNumber != null && bankIdpersonalNumber.length == 12)
        url = url + "?bankIdpersonalNumber=" + bankIdpersonalNumber
      
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': 'application/json'
            })
        };

      return this.http.post<any>(url, data, httpOptions)
            .pipe(
            tap((response: any) => 
                this.saveToken(response, username)),
                catchError(this.handleError('login', data)
            ));
    }

  loginBankIdPassive(personalNumber: string): Observable<boolean> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      })
    };

    return this.http.get<any>(this.apiBaseUrl + "BankId/" + personalNumber + "/AuthenticatePassive", httpOptions)
      .pipe(
        tap((response: any) =>
          this.saveToken(response, response.userName)),
        catchError(this.handleError('bankid_passive')
        ));
  }

  onUserLoggedIn(response: any) {
    console.log('login');
    this.saveToken(response, "");
  }

    createAccount(user: any): Observable<boolean> {
        
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };

        var data = JSON.stringify(user);

        return this.http.post<any>(this.apiBaseUrl + 'Users', data, httpOptions)
            .pipe(
            tap((response: any) =>
                console.log(response)
            ),
              catchError(this.handleError('login', "error"))  
            );
    }
    
    handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            
            let config = new MatSnackBarConfig();
            config.duration = 7000;

            console.log('Err:')
            console.log(error);

            if (error.status == 400)
            {              
                this.snackBar.open("Fel användarnamn eller lösenord!", undefined, config);
            }
            else if (error.status == 409) {
                this.snackBar.open("Epostadressen är upptagen!", undefined, config);
            }
            else if (error.statusText == 'Unknown Error') {
                this.snackBar.open("Något gick fel. Försök gärna logga in igen, och kontakta support om problemet kvarstår.", undefined, config);
            }
            else if (error.status == 500) {
                this.snackBar.open(error.error.ExceptionMessage, undefined, config);
            }
            else {
                this.snackBar.open(error.error, undefined, config);
            }
            
            return of(result as T);
        };
    }
    
    getCurrentUser(): Observable<any> {
        
        var url = this.apiBaseUrl + 'User/My';
        var bearerToken = 'bearer ' + this.token;
        
        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': bearerToken
            })
        };
        
        return this.http.get<any>(url, this.httpOptions)
            .pipe(
            tap((response: any) => this.onFinished(response)),
            catchError(
                (err: any) => {
                    console.log(err)
                    return new Observable<any>();

                }
            ));
    }

    onFinished(response: any) {

        
        var userString = localStorage.getItem('ANMS-AUTH')
        var currentUserObject = JSON.parse(userString);
        this.userData = currentUserObject;
        currentUserObject.User = response;
        localStorage.setItem('ANMS-AUTH', JSON.stringify(currentUserObject));
        this.userUpdated("");
        
      if (
            (response.AccountSettings.IsCompany == false && (
            response.AccountSettings.FirstName == null ||
            response.AccountSettings.LastName == null ||
            response.AccountSettings.Address == null ||
            response.AccountSettings.Zip == null ||
            response.AccountSettings.City == null ||
            response.AccountSettings.Municipality.Id == 0 ||
            response.AccountSettings.PersonalNumber == null ||
            response.AccountSettings.BankName == null ||
            response.AccountSettings.ClearingNumber == null ||
            response.AccountSettings.AccountNumber == null))
            || (response.AccountSettings.IsCompany == true && (
            response.AccountSettings.CompanyName == null ||
            response.AccountSettings.Address == null ||
            response.AccountSettings.Zip == null ||
            response.AccountSettings.OrgNumber == null ||
            response.AccountSettings.City == null))
        )
        {
            console.log('user')
            console.log(response)

            this.router.navigate(['/settings']);
        }
        
    }

    setHttpOptions() {
        var bearerToken = 'bearer ' + this.token;

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': bearerToken
            })
        };
  }

  currentUserHasCoordinationNumber() {
    return this.userData.User.AccountSettings.HasCoordinationNumber;
  }

  currentUserIsLoggedInWithBankId() {
    return this.userData.User.LoginType === 'bankid';
  }


    saveToken(response: any, username: string)
    {
      
        // login successful if there's a jwt token in the response
        if (response) {
            // set token property
            this.token = response.access_token;
            console.log('saved token to auth service');
            console.log(this.token);

            this.store.dispatch(new ActionAuthLogin());

            // store username and jwt token in local storage to keep user logged in between page refreshes
            var userString = JSON.stringify({ isAuthenticated: true, username: username, token: response });
            localStorage.setItem('ANMS-AUTH', userString);
            var currentUserObject = JSON.parse(userString);

            this.token = currentUserObject.token.access_token;
            
            this.getCurrentUser()
                .subscribe(
                data => {
                    var userString = localStorage.getItem('ANMS-AUTH')
                    var currentUserObject = JSON.parse(userString);
                    currentUserObject.User = data;
                    localStorage.setItem('ANMS-AUTH', JSON.stringify(currentUserObject));
                    this.userData = currentUserObject;
                },
                error => {
                    alert('err');
                });
            
            return true;
        } else {
            // return false to indicate failed login
            return false;
        }
    }

    //resetPassword(passwordReset: PasswordReset): Observable<boolean> {

    //    let headers = new Headers();
    //    headers.append('Accept', 'application/json');

    //    //return this.http
    //    //    .post(this.apiBaseUrl + 'api/Users/ResetPassword', passwordReset, { headers })
    //    //    .map((response: Response) => {
                
    //    //        console.log(response);
    //    //        return true;
    //    //    });

    //}

    requestResetPassword(user: User): Observable<boolean> {

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };

        var data = JSON.stringify(user);

        return this.http.post<any>(this.apiBaseUrl + 'Users/ForgotPassword', data, httpOptions)
            .pipe(
            tap((response: any) =>
                this.requestResetPasswordFinished()
            ),
            catchError(this.handleError('login', data)
            ));
    }

    requestResetPasswordFinished()
    {
        let config = new MatSnackBarConfig();
        config.duration = 7000;
        
        this.snackBar.open("E-postmeddelande skickat!", undefined, config);
        this.router.navigateByUrl("/login");
    }

    resetPassword(request: PasswordReset): Observable<boolean> {
        
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
        };

        var data = JSON.stringify(request);

        return this.http.post<any>(this.apiBaseUrl + 'Users/ResetPassword', data, httpOptions)
            .pipe(
            tap((response: any) =>
                this.resetPasswordFinished()
            ),
            catchError(this.handleError('resetpassword', data)
            ));
    }

    resetPasswordFinished() {
        let config = new MatSnackBarConfig();
        config.duration = 7000;

        this.snackBar.open("Lösenordet återställt!", undefined, config);
        this.router.navigateByUrl("/login");
    }

    //requestResetPassword(passwordReset: PasswordReset): Observable<boolean> {

    //    let headers = new Headers();
    //    //let data = "grant_type=password&username=" + username + "&password=" + password;
    //    //headers.append('Content-Type', 'application/x-www-form-urlencoded');
    //    headers.append('Accept', 'application/json');

    //    return this.http
    //        .post(this.apiBaseUrl + 'api/Users/ForgotPassword', passwordReset, { headers })
    //        .map((response: Response) => {
                
    //            console.log(response);
    //            return true;
    //        });

    //}    

    logout(): void {
        // clear token remove user from local storage to log user out
        //this.token = "";
        //localStorage.removeItem('currentUser');
        //localStorage.removeItem('ANMS-AUTH');

        //this.store.dispatch(new ActionAuthLogout());

        //location.reload();

        this.token = "";
        localStorage.removeItem('currentUser');
        localStorage.removeItem('ANMS-AUTH');

        this.store.dispatch(new ActionAuthLogout());

        location.reload();
  }

  clearLoginStorage() {
    this.localStorageService.removeItem('currentUser');
    this.localStorageService.setItem("AUTH", "{ isAuthenticated: false }");
  }
}


@Injectable()
export class User {
    constructor
        (
      public Email: string,
      public PersonalNumber: string,
        public username: string,
        public password: string
    ) { }
}

@Injectable()
export class PasswordReset {
    constructor
        (
        public Email: string,
        public Password: string,
        public ConfirmPassword: string,
        public Code: string
        ) { }
}
