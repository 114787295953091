  
<style>
  

  .main-heading
  {
    margin-left: 10px;
    margin-bottom: 20px;
  }

  .sp-form-field
  {
    margin-bottom: 30px;
  }
  .sp-form-field-label {
    margin-bottom: 15px;
    font-weight: 600;
  }
  
  .right {
    float: right;
  }
  .description
  {
    margin-bottom: 20px;
  }
  .warning
  {
    color: red;
  }
</style>


<!--<h1 mat-dialog-title *ngIf="!isNewCustomer" style="margin-top: 5px;margin-bottom: 35px;">Redigera kund</h1>-->

  <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
  <div class="">
    <div class="row">
      <div class="col-md-12">
        <h3 class="main-heading">UTLÄGG</h3>

        <!--<mat-form-field style="width:200px;margin-left: 10px; margin-right: 10px;">
    <input class="text-input" matInput placeholder="Beskrivning" [(ngModel)]="c.Description" [disabled]="!canUpdatePayout()">
  </mat-form-field>-->

        <mat-form-field class="col-sm-12">
          <input type="text" matInput [ngModel]="deduction.Name" (ngModelChange)="deduction.Name = $event" required md-maxlength="50" placeholder="Utlägget avser" id="name" name="name" />
        </mat-form-field>

        <mat-form-field class="col-sm-6">
          <input type="number" step="0.01" matInput [ngModel]="deduction.Amount" (ngModelChange)="deduction.Amount = $event" required md-maxlength="50" placeholder="Belopp (SEK)" id="amount" name="amount" />
        </mat-form-field>

        <mat-form-field class="col-sm-6">
          <mat-select [(ngModel)]="deduction.VatPercentage" name="VatPercentage" matInput placeholder="Momssats">
            <mat-option *ngFor="let item of vatList" value="{{item.Value}}">{{item.Value}} %</mat-option>
          </mat-select>
        </mat-form-field>

        <div class="col-sm-12 sp-form-field">
          <span style="font-size:12px;">Vid ett kvitto med olika momssatser: lägg till ett utlägg per momssats med respektive belopp och momssatser men använd samma kvitto</span>
        </div>

        <mat-form-field class="col-sm-12 description">
          <textarea matInput [ngModel]="deduction.Description" (ngModelChange)="deduction.Description = $event" required md-maxlength="500" rows="5" placeholder="Beskrivning/Syfte" id="message" name="message" md-select-on-focus></textarea>
        </mat-form-field>

        <div style="margin-bottom: 30px;">
          <div class="col-lg-12">
            <h4><b>Reseutlägg</b></h4>
            <p>Gäller ditt kvitto ett utlägg du gjort för resa i samband med det du fakturerat, t ex tågbiljetter, hotell, taxi eller liknande?</p>
          </div>
          <div class="col-lg-6">
            <mat-checkbox matInput [ngModel]="TravelExpense" [checked]="TravelExpense" (change)="changeValue($event)" name="TravelExpense">Ja</mat-checkbox>
          </div>
        </div>

        <div class="col-sm-12 sp-form-field" style="border-top: 1px solid grey; padding-top: 20px;">
          <div class="sp-form-field-label">Ladda upp PDF-dokument eller bild *</div>
          <input *ngIf="deduction.Attachment.Guid == null" type="file" mat-stroked-button mat-raised-button (change)="fileChange($event,deduction.Attachment)" accept=".pdf,.doc,.docx,.jpeg,.jpg,.png,.gif">
          <a *ngIf="deduction.Attachment.Guid != null" class="col-sm-8" mat-stroked-button color="secondary" (click)="showFile(deduction.Attachment)"><mat-icon>open_in_new</mat-icon>&nbsp; visa dokument</a>&nbsp;
          <a *ngIf="deduction.Attachment.Guid != null" class="warning right col-sm-3" mat-stroked-button color="secondary" (click)="deleteFile(deduction.Attachment)">radera</a>&nbsp;
        </div>

        <div class="col-sm-12 sp-form-field">
          <div class="sp-form-field-label">Är ovan fil en faktura, ladda upp kvittens på att fakturan är betald</div>
          <input *ngIf="deduction.AttachmentReciept.Guid == null" type="file" mat-stroked-button mat-raised-button (change)="fileChange($event,deduction.AttachmentReciept)" accept=".pdf,.doc,.docx,.jpeg,.jpg,.png,.gif">
          <a *ngIf="deduction.AttachmentReciept.Guid != null" class="col-sm-8" mat-stroked-button color="secondary" (click)="showFile(deduction.AttachmentReciept)"><mat-icon>open_in_new</mat-icon>&nbsp; visa dokument</a>&nbsp;
          <a *ngIf="deduction.AttachmentReciept.Guid != null" class="warning right col-sm-3" mat-stroked-button color="secondary" (click)="deleteFile(deduction.AttachmentReciept)">radera</a>&nbsp;
        </div>

        <div class="col-sm-12 sp-form-field" style="margin-top: 40px;">
          <button mat-raised-button [disabled]="!f.form.valid || deduction.Attachment.Guid == null" color="accent" class="right">Spara</button>&nbsp;
          <button type="button" mat-stroked-button (click)="cancel()" color="secondary" class="right" style="margin-right:10px;">Avbryt</button>&nbsp;
        </div>

      </div>
    </div>
  </div>

  <div class="row">

    <!--<mat-form-field style="width:200px;margin-left: 10px; margin-right: 10px;">
      <input class="text-input" type="number" matInput placeholder="Totalt belopp i kr inkl. moms" [(ngModel)]="c.Amount" [disabled]="!canUpdatePayout()">
    </mat-form-field>

    <mat-form-field style="width:200px;margin-left: 10px; margin-right: 10px;">
      <mat-select [(ngModel)]="c.VatPercentage" name="VatPercentage" matInput placeholder="Momssats" [disabled]="!canUpdatePayout()">
        <mat-option *ngFor="let item of vatList" value="{{item.Value}}">{{item.Value}} %</mat-option>
      </mat-select>
    </mat-form-field>

    <input type="file" *ngIf="c.Attachment.Url == undefined" [disabled]="!canUpdatePayout()" mat-stroked-button mat-raised-button (change)="fileChange($event,c)" accept=".pdf,.doc,.docx,.jpeg,.jpg,.png,.gif">



    <div style="width:93px;;margin-left:15px;">
      <h5>Belopp (kr)</h5>
      <input matInput type="number" class="text-input amount" [disabled]="!canEditGroup()" [(ngModel)]="data.invoiceItemGroup.Amount" [ngModelOptions]="{ standalone: true }">
    </div>
    <div *ngIf="canEditGroup()" style="margin-left: 15px;">

      <h5>Arbetad tid/dag:</h5>

      <mat-select placeholder="Timmar" class="select-hours" [(ngModel)]="hoursSelected" name="hoursworked" style="width:80px;">
        <mat-option *ngFor="let item of hoursList" value="{{item.Value}}">{{item.Value}} h</mat-option>
      </mat-select>

      <mat-select placeholder="Minuter" class="select-hours" [(ngModel)]="minutesSelected" name="minutesworked" style="width:90px;margin-left:10px;">
        <mat-option *ngFor="let item of minutesList" value="{{item.Value}}">{{item.Value}} min</mat-option>
      </mat-select>

    </div>
    <div style="margin-left: 16px;">
      <h5>Momssats</h5>
      <mat-select [disabled]="!canEditGroup()" placeholder="Moms" class="select-vat" [(ngModel)]="data.invoiceItemGroup.Vat" name="vat" style="width:80px;">
        <mat-option *ngFor="let item of vatList" value="{{item.Value}}">{{item.Value}} %</mat-option>
      </mat-select>
    </div>-->

  </div>

  <!--<div *ngFor="let i of data.invoiceItemGroup.InvoiceRows" style="width:100%;margin-left:5px;margin-bottom:18px;">

    <div class="time-col">
      <span><b>{{i.StartDate | date: 'yyyy-MM-dd'}}</b></span>
    </div>
    <div class="time-col left-margin">

      <mat-select [disabled]="!canEditGroup() && i.Id > 0" placeholder="Timmar" [(ngModel)]="i.HoursWorked" [ngModelOptions]="{standalone: true}" class="time-input" style="width: 52px;">
        <mat-option *ngFor="let item of hoursList" value="{{item.Value}}">{{item.Value}} h</mat-option>
      </mat-select>

    </div>
    <div class="time-col" style="margin-left:10px;">

      <mat-select [disabled]="!canEditGroup() && i.Id > 0" class="time-input" placeholder="Minuter" [(ngModel)]="i.MinutesWorked" name="minutesworked" [ngModelOptions]="{standalone: true}">
        <mat-option *ngFor="let item of minutesList" value="{{item.Value}}">{{item.Value}} min</mat-option>
      </mat-select>

    </div>
    <div style="float:right;">

      <mat-icon *ngIf="canEditGroup() || i.Id.length > 20" (click)="deleteListItem(i)" class="delete_button time-input" style="width:20px;">delete</mat-icon>

    </div>
  </div>-->


</form>

<!--<div mat-dialog-actions align="end" *ngIf="data.invoiceItemGroup.Type.Name != undefined" style="margin-bottom:15px;">
  
  <div *ngIf="!validates()" style="width: 100%;">
    <span style="color:red;float:left;margin-bottom:5px;">Välj datum och tid för att visa på faktura</span>
  </div>

  <button mat-stroked-button *ngIf="canEditGroup()" align="left" color="secondary" style="width:32%" (click)="deleteRow()">radera</button>&nbsp;
  <button mat-stroked-button [disabled]="!validates()" color="secondary" style="width:32%" (click)="saveRow()">Spara</button>&nbsp;
  <button mat-stroked-button *ngIf="!data.invoiceItemGroup.isNew" style="width:32%" (click)="cancel()">avbryt</button>&nbsp;
  
</div>-->

