import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle } from '@angular/material';
import { UserService } from '../../core/services/UserService';
import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
//import { ServiceService } from '../../core/services/ServiceService';
import { AuthenticationService } from '../../core/services/AuthenticationService'
import { AppConfig } from '../../config/app.config';
import { ICompany } from '../../core/interfaces/ICompany';
//import { InvoiceService } from '../../core/services/InvoiceService';
import { CompanyService } from '../../core/services/CompanyService';
import { FormControl } from '@angular/forms';
import { IAccountSettings } from '../../core/interfaces/user/IAccountSettings';

@Component({
    selector: 'edit-customer-dialog',
    templateUrl: 'EditCustomerDialog.Component.html'
})


export class EditCustomerDialogComponent {
    private _dimesionToggle = false;
    private _formValid = false;
    public _imageList: any[] = [];
    public _hasImages = false;
    private _showImageList = false;
    private _showAttachments = false;
    private _hasAttachments = false;
    private _showComments = false;
    private _isCompany = false;
    public _isPrivatePerson = true;
    public customer: ICompany;
    public isNewCustomer: boolean;
    @ViewChild('myInput', { static: false })
    myInputVariable: any;
    private _apiBaseUrl: string;
    countries = [];
    orgNumberPattern = "^[0-9]{6}-[0-9]{4}$";
    pNumberPattern = "^[0-9]{6}-[0-9]{4}$";
    emailPattern = "[A-Öa-ö0-9._%+-]+@[A-Öa-ö0-9.-]+\.[A-Öa-ö]{1,63}$";
    isAdmin: boolean = false;
    orgNumberValid: boolean = false;
    pNrValid: boolean = false;
    phoneNumberValid: boolean = true;
    errorMessage: string = "";
    searchCompleted: boolean = false;
    customerSearchTerm: string;
    filteredCustomers: Observable<any[]>;
    selectedCustome: any;
    selectedCustomerObject: any;
    selectedCustomerName: any;
    public searchAccountValue: string;
    customeSearchTerm: string;
    customers: Customer[] = [];
    userCtrl: FormControl;
    searchAccountResult: any;
    searchByOrgNrCompleted: boolean = false;
    InvoiceSendTypeLocked = false;
    paymentTerms = [0, 10, 15, 20, 25, 30, 45, 60];
    errorMessageOrgnr: string = '';
    errorMessagePnr: string = '';
    errorMessagePhoneNr: string = '';

   
        
    constructor(
        public dialogRef: MatDialogRef<EditCustomerDialogComponent>,
      private http: Http,
      private userService: UserService,
        private authenticationService: AuthenticationService,
        //private invoiceService: InvoiceService,
        private companyService: CompanyService,
        //private config: AppConfig,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) 
    { 
        //this._apiBaseUrl = config.getConfig('host');
    }

    ngOnInit() {

        this.isAdmin = this.userService.userHasRole("admin");    
        this._isPrivatePerson = true;
        this.getCountries();
        
        if (this.data.customer != undefined && this.data.customer.Id != undefined && this.data.customer.Id > 0)
        {
            this.customer = JSON.parse(JSON.stringify(this.data.customer));
        }
        else if (this.data.Id != undefined)
        {
            this.customer = JSON.parse(JSON.stringify(this.data));
        }
        else
        {
            this.customer = JSON.parse(JSON.stringify(this.data.customerTemplate));
        }
        
        if (this.customer.Country == null || this.customer.Country.Id == null)
          this.customer.Country.Id = 1;
        
      this.isNewCustomer = (this.customer == undefined || this.customer.Id == 0) ? true : false;

      if (this.isNewCustomer) {
        this.customer.AcceptsInvoiceFee = true;
        this.customer.InvoiceSendType = 0;
        this.customer.IsCompany = true;
        this.customer.Active = true;
      }

      if (this.customer.InvoiceSendType != 0) {
        this.InvoiceSendTypeLocked = true;
      }
              
    }
    
    save(customer: ICompany)
    {

      if (customer.Id == undefined || customer.Id == 0) {            

          this.companyService.createCompany(customer)
              .subscribe(value => this.onAfterRowSaved(value),
                  error => this.onError(error)
              );
      }
      else {
            
          this.companyService.updateCompany(customer)
              .subscribe(value => this.onAfterRowSaved(value),
              error => this.onError(error)
              );
      }
        
    }

    getCountries()
    {
        this.companyService.getCountries()
            .subscribe(value => this.countries = value,
            error => this.onError(error)
            );
    }

    getCountryName(countryId: number) {
      for (var i = 0; i < this.countries.length; i++) {
        if (this.countries[i].Id == countryId) {

          this.customer.Country.Name = this.countries[i].Name;
          
        }
      }
  }

    getErrorMessage() {
    //if (this.customer.OrgNumber != null) {
    //  if (this.customer.OrgNumber != null) {
        
    //  }
    //}

  }

    cancel()
    {
        this.dialogRef.close(undefined);
    }

    onError(error: any)
    {
        alert('An error occured');
        console.log(error);
    }

    onAfterRowSaved(response: ICompany)
    {
      this.dialogRef.close(response);
    }

    customerTypeToggle(isCompany: boolean)
    {
        this.customer.IsCompany = isCompany;
    }

  customerSelected(event: any) {
    this.getCustomerData(event.option.value);
    this.customerSearchTerm = null;
  }

  getCustomerData(orgNumber: number) {
    this.companyService.getGlobalCompanyByOrgNr(orgNumber.toString())
      .subscribe(response => this.getCustomerDataFinished(response), error => console.log(error));
  }

  getCustomerDataFinished(response: any) {

    if (response.length > 0)
      this.selectedCustomerObject = response[0];
  }

  orgNumberValidCheck(orgNumber: string) {
    
    if (orgNumber != null) {
      
      const value: string = orgNumber || '';
                  
      if (this.customer.Country.Name == 'Sverige' || this.customer.Country.Id == 1)
      {
        if (value.match(/^[0-9]{6}-[0-9]{4}$/g)) {
          this.orgNumberValid = true;
          this.errorMessageOrgnr = "";
        }
        else {
          this.errorMessageOrgnr = "Organisationsnumret måste vara i formatet NNNNNN-NNNN (10 siffror och vanligt bindestreck)";
          this.orgNumberValid = false;
        }

        this.SearchAccount(orgNumber);
        this.customer.OrgNumber = orgNumber;
      }    
      else {
        this.orgNumberValid = true;
        this.customer.OrgNumber = orgNumber;
        this.errorMessageOrgnr = "";
      }

    }
  }

  pNumberValidCheck(pNumber: string) {    
    if (pNumber != null) {

      const value: string = pNumber || '';

      if (this.customer.Country.Name == 'Sverige' || this.customer.Country.Id == 1) {
        if (value.match(/^[0-9]{8}-[0-9]{4}$/g)) {
          this.pNrValid = true;
          this.errorMessagePnr = "";
        }
        else {
          this.errorMessagePnr = "Personnumret måste vara i formatet NNNNNN-NNNN (10 siffror och bindestreck)";
          this.pNrValid = false;
        }
                
        this.customer.OrgNumber = pNumber;
      }
      else {
        this.pNrValid = true;
        this.customer.OrgNumber = pNumber;
        this.errorMessageOrgnr = "";
      }

    }
  }

  phoneNumberValidCheck(phoneNumber: string) {
    
    if (phoneNumber != null && phoneNumber != "") {
      
      const value: string = phoneNumber || '';

      if (value.match(/^[0-9+-]*$/g)) {
        this.phoneNumberValid = true;
        this.errorMessagePhoneNr = "";
      }
      else {
        this.errorMessagePhoneNr = "Telefonnummer får bara innehålla siffror, vanligt bindestreck och plustecken";
        this.phoneNumberValid = false;
      }

      this.customer.Phone = phoneNumber;
      }
    else {
      this.phoneNumberValid = true;
      this.customer.Phone = phoneNumber;
      this.errorMessageOrgnr = "";
    }
  }

  SearchAccount(term: string) {
    
    if (term == null || term == "") {
      return;
    }
     
    this.companyService.getGlobalCompanyByOrgNr(term)
      .subscribe(
        response => this.onSearchAccountCompleted(response),
        error => console.log(error));
  }

  onSearchAccountCompleted(response: ICompany) {

    this.searchAccountResult = response;    

    if (this.searchAccountResult != null) {
      this.updateCustomerInfo(this.searchAccountResult);
    }

    console.log('response');
    console.log(response);

  }

  updateCustomerInfo(globalCompany: ICompany) {
    
    this.customer.OrgNumber = globalCompany.OrgNumber;
    this.customer.Active = globalCompany.Active;

    if (globalCompany.Name != null && globalCompany.Name != "") {
      this.customer.Name = globalCompany.Name;
      this.customer.NameLocked = true;
    }
    
    if (globalCompany.PaymentTerm != null && globalCompany.PaymentTerm > 0) {
      this.customer.PaymentTerm = globalCompany.PaymentTerm;
      this.customer.PaymentTermLocked = true;
    }

    if (globalCompany.Phone != null && globalCompany.Phone != "") {
      this.customer.Phone = globalCompany.Phone;
      this.customer.PhoneLocked = true;
    }

    if (globalCompany.InvoiceEmail != null && globalCompany.InvoiceEmail != "") {
      this.customer.InvoiceEmail = globalCompany.InvoiceEmail;
      this.customer.InvoiceEmailLocked = true;
    }

    if (globalCompany.Co != null && globalCompany.Co != "") {
      this.customer.Co = globalCompany.Co
      this.customer.CoLocked = true;
    }

    if (globalCompany.Address != null && globalCompany.Address != "") {
      this.customer.Address = globalCompany.Address;
      this.customer.AddressLocked = true;
    }
    
    if (globalCompany.Address2 != null && globalCompany.Address2 != "") {
      this.customer.Address2 = globalCompany.Address2;
      this.customer.Address2Locked = true;
    }

    if (globalCompany.Zip != null && globalCompany.Zip != "") {
      this.customer.Zip = globalCompany.Zip;
      this.customer.ZipLocked = true;
    }

    if (globalCompany.City != null && globalCompany.City != "") {
      this.customer.City = globalCompany.City;
      this.customer.CityLocked = true;
    }

    if (globalCompany.GlnNumber != null && globalCompany.GlnNumber != "") {
      this.customer.GlnNumber = globalCompany.GlnNumber;
      this.customer.GlnNumberLocked = true;
    }

    if (globalCompany.InvoiceSendType != 0) {
      this.customer.InvoiceSendType = globalCompany.InvoiceSendType;
      this.customer.InvoiceSendTypeLocked = true;
    }

    if (globalCompany.AcceptsInvoiceFee == false) {
      this.customer.AcceptsInvoiceFee = false;
      this.customer.AcceptsInvoiceFeeLocked = true;
    }

    if (globalCompany.InvoiceSendType != 0) {
      this.customer.InvoiceSendType = globalCompany.InvoiceSendType;
      this.customer.InvoiceSendTypeLocked = true;
    }

    this.customer.ElectronicReference = globalCompany.ElectronicReference;

  }

}

export class Customer {
  constructor(public name: string) { }
}
