var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { MatTable, MatTableDataSource } from '@angular/material';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../core/services/UserService';
import { InvoiceService } from '../../core/services/InvoiceService';
import { LocalStorageService } from '../../core/local-storage/local-storage.service';
import { MunicipalityService } from '../../core/services/municipalityService';
import { MessagesDialogComponent } from '../../shared/messages/messages-dialog-component';
import { GlobalMessageService } from '../../core/services/GlobalMessageService';
import { environment as env } from '@env/environment';
var AboutComponent = /** @class */ (function () {
    function AboutComponent(invoiceService, localStorageService, loadingService, userService, municipalityService, globalmessageService, router, dialog) {
        this.invoiceService = invoiceService;
        this.localStorageService = localStorageService;
        this.loadingService = loadingService;
        this.userService = userService;
        this.municipalityService = municipalityService;
        this.globalmessageService = globalmessageService;
        this.router = router;
        this.dialog = dialog;
        this.routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
        this.releaseButler = require('../../../assets/release-butler.png');
        this.displayedColumns = ['icon', 'customer', 'paymentdue', 'money'];
        this.displayedColumnsCompany = ['invoicenumbercomp', 'amountcomp', 'paymentduecomp', 'invoicestatuscomp'];
        this.isLoading = true;
        this.isAdmin = false;
        this.statuses = [];
        this.selectedFilterStatus = { Id: 0, Name: 'Alla fakturor' };
        this.appId = env.appId;
        // Calculator
        this.invoiceFee = 29;
        this.samFee = 0.02; // Check if in DataBase
        this.samFeeExpress = 0.05; // Check if in DataBase
        this.samFeeSlide = false;
        this.tempEmployerTax = 0.3; // Check if in DataBase
        this.vatStandard = 1.25; // Check if in DataBase
        this.vatMedium = 1.12; // Check if in DataBase
        this.vatLow = 1.06; // Check if in DataBase
        this.isCompanyUser = false;
    }
    AboutComponent.prototype.ngOnInit = function () {
        this.loadUser();
        this.loadingService.start();
        this.loadMunicipalities();
        this.loadGlobalMessages();
    };
    AboutComponent.prototype.loadUser = function () {
        var _this = this;
        this.userService.getCurrentUser()
            .subscribe(function (value) { return _this.userLoaded(value); }, function (error) { return console.log(error); });
    };
    AboutComponent.prototype.userLoaded = function (user) {
        this.user = user;
        this.isAdmin = this.userService.userHasRole("admin");
        this.isCompanyUser = this.user.AccountSettings.IsCompany;
        // Default selectted item from storage
        var defaultItem = this.localStorageService.getItem("filterStatusDefault");
        if (defaultItem && this.isAdmin) {
            var item = JSON.parse(defaultItem);
            this.selectedFilterStatus = { Id: item.Id, Name: item.Name };
            this.getInvoices([item.Id], 0, 10, this.filter);
        }
        else {
            this.getInvoices(null, 0, 10, this.filter);
        }
        this.getUserEmployerTax();
        console.log(this.user);
    };
    AboutComponent.prototype.getUserEmployerTax = function () {
        var _this = this;
        this.userService.getUserEmployerTax(this.user.AccountSettings.Id)
            .subscribe(function (value) { return _this.employerTaxLoaded(value); }, function (error) { return console.log(error); });
    };
    AboutComponent.prototype.employerTaxLoaded = function (userEmployerTax) {
        this.employertax = userEmployerTax;
    };
    AboutComponent.prototype.getInvoices = function (statuses, pageIndex, pageSize, filter) {
        var _this = this;
        if (statuses === void 0) { statuses = null; }
        this.isLoading = true;
        // All invoices selected
        if (statuses != null && statuses.length == 1 && statuses[0] == 0) {
            statuses = null;
        }
        //if (this.isAdmin == true) {
        //  this.invoiceService.getInvoices(statuses, pageIndex,pageSize, filter)
        //    .subscribe(value => this.onInvociesRetrieved(value),
        //      error => console.log(error)
        //    )
        //}
        if (this.appId == 1) {
            this.invoiceService.getInvoicesWithTransLogs(statuses, pageIndex, pageSize, filter)
                .subscribe(function (value) { return _this.onInvociesRetrieved(value); }, function (error) { return console.log(error); });
        }
        if (this.appId == 2) {
            this.invoiceService.getInvoices(statuses, pageIndex, pageSize, filter)
                .subscribe(function (value) { return _this.onInvociesRetrieved(value); }, function (error) { return console.log(error); });
        }
    };
    AboutComponent.prototype.onInvociesRetrieved = function (response) {
        for (var i = 0; i < response.Invoices.length; i++) {
            if (response.Invoices[i].Status != undefined)
                response.Invoices[i].Status.Id = response.Invoices[i].Status.Id.toString();
        }
        this.invoiceData = response.Invoices;
        this.dataSource = new MatTableDataSource(this.invoiceData);
        this.isLoading = false;
        this.loadingService.complete();
    };
    // salaryCalculator
    AboutComponent.prototype.myCalculator = function (finalAmount) {
        this.samFeeNew = Math.round(this.totalInvoiceAmount * this.samFee); // this.invoice.ServiceFee
        this.partialTotalAmount = Math.round(this.totalInvoiceAmount - this.samFeeNew);
        this.grossSalary = Math.round(this.partialTotalAmount / (1 + this.employertax.Tax));
        this.netSalary = Math.round(this.grossSalary - (this.grossSalary * this.user.AccountSettings.Municipality.Tax));
        this.arbetsgivarAvgift = Math.round(this.partialTotalAmount - this.grossSalary);
        this.displayTax = Math.round(this.grossSalary * this.user.AccountSettings.Municipality.Tax);
        finalAmount = this.netSalary;
    };
    // expressSalaryCalculator
    AboutComponent.prototype.myExpressCalculator = function (finalAmountExpress) {
        this.samFeeNew = Math.round(this.totalInvoiceAmountExpress * this.samFeeExpress);
        this.partialTotalAmount = Math.round(this.totalInvoiceAmountExpress - this.samFeeNew);
        this.grossSalary = Math.round(this.partialTotalAmount / (1 + this.tempEmployerTax));
        this.netSalaryExpress = Math.round(this.grossSalary - (this.grossSalary * this.user.AccountSettings.Municipality.Tax));
        this.arbetsgivarAvgift = Math.round(this.partialTotalAmount - this.grossSalary);
        this.displayTax = Math.round(this.grossSalary * this.user.AccountSettings.Municipality.Tax);
        finalAmountExpress = this.netSalaryExpress;
    };
    // vatCalcStandard 25%
    AboutComponent.prototype.myVatCalc = function (totalAmount) {
        this.withVat = Math.round(this.noVat * this.vatStandard);
        totalAmount = this.withVat;
    };
    AboutComponent.prototype.myNoVatCalc = function (totalNoVat) {
        this.noVat = Math.round(this.withVat / this.vatStandard);
        totalNoVat = this.noVat;
    };
    // vatCalcMedium 12%
    AboutComponent.prototype.myVatCalcMedium = function (totalAmountMedium) {
        this.withVatMedium = Math.round(this.noVatMedium * this.vatMedium);
        totalAmountMedium = this.withVatMedium;
    };
    AboutComponent.prototype.myNoVatCalcMedium = function (totalNoVatMedium) {
        this.noVatMedium = Math.round(this.withVatMedium / this.vatMedium);
        totalNoVatMedium = this.noVatMedium;
    };
    // vatCalcMedium 6%
    AboutComponent.prototype.myVatCalcLow = function (totalAmountLow) {
        this.withVatLow = Math.round(this.noVatLow * this.vatLow);
        totalAmountLow = this.withVatLow;
    };
    AboutComponent.prototype.myNoVatCalcLow = function (totalNoVatLow) {
        this.noVatLow = Math.round(this.withVatLow / this.vatLow);
        totalNoVatLow = this.noVatLow;
    };
    AboutComponent.prototype.loadMunicipalities = function () {
        var _this = this;
        this.municipalityService.getMunicipalities()
            .subscribe(function (value) { return _this.municipalitiesLoaded(value); }, function (error) { return console.log(error); });
    };
    AboutComponent.prototype.loadGlobalMessages = function () {
        var _this = this;
        this.globalmessageService.getGlobalMessages()
            .subscribe(function (value) { return _this.globalMessagesLoaded(value); }, function (error) { return console.log(error); });
    };
    AboutComponent.prototype.globalMessagesLoaded = function (globalmessages) {
        this.globalmessages = globalmessages;
        console.log(this.globalmessages);
    };
    AboutComponent.prototype.municipalitiesLoaded = function (municipalities) {
        this.municipalities = municipalities;
    };
    AboutComponent.prototype.municipalityChange = function (municipalityId) {
        for (var i = 0; i < this.municipalities.length; i++) {
            if (this.municipalities[i].Id == municipalityId) {
                this.user.AccountSettings.Municipality.Tax = this.municipalities[i].Tax;
            }
        }
    };
    AboutComponent.prototype.openDialog = function (data) {
        var _this = this;
        var dialogRef = this.dialog.open(MessagesDialogComponent, {
            width: '500px;',
            data: __assign({}, data),
        });
        localStorage.setItem('test', JSON.stringify(this.isRead == true));
        console.log(this.isRead);
        dialogRef.afterClosed().subscribe(function (result) {
            localStorage.getItem("test");
            console.log('The dialog was closed', _this.isRead);
        });
    };
    return AboutComponent;
}());
export { AboutComponent };
