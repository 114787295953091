<style>
  .fix-static-bug {
    width: 100% !important;
    position: static !important;
  }

  div {
    font-size: 15px !important;
  }

  .grey {
    color: #383838;
  }

  .padding30{
      padding: 30px;
  }

  .link {
    float: right;
    margin-right: 20px;
    position: relative;
    top: 15px;
  }

  .infotext {
    padding-left: 15px;
    font-size: 80%;
    margin-top: -15px;
  }

  .person {
    float: right;
  }

  .marginbottom15 {
      margin-bottom: 15px;
  }

</style>

<div class="fix-static-bug">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h3 class="main-heading">Inbetalning</h3>
      </div>
    </div>

    <!--Calculate partial total amount / gross salary-->
    <div *ngIf="!isLoading" class="row align-items-end" style="background-color: #f3f3f3; padding-top: 20px; padding-bottom: 30px;">

      <div *ngIf="invoice.ApprovedForExpress" class="col-12" style="margin-bottom: 10px;">
        <b style="color: green;">Godkänd för expressutbetalning</b><br />
      </div>
      <div *ngIf="!invoice.ApprovedForExpress" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">Ej godkänd för expressutbetalning</b><br />
      </div>
      <div *ngIf="invoice?.AdminInfo != ''" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">{{invoice?.AdminInfo}}</b><br />
      </div>

      <!--Invoice info-->
      <div class="col-sm-12 col-md-6" style="height: 600px; margin-bottom: 20px;">

        <mat-card>
          <mat-card-title>
            Fakturainfo
            <span *ngIf="invoice.SubInvoice" class="person" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Detta är en underfaktura"><mat-icon>person</mat-icon></span>
            <span *ngIf="invoice.ParentInvoice" class="person" matTooltipPosition="after" area-label="Hover to display info" matTooltip="Detta är en moderfaktura"><mat-icon>groups</mat-icon></span>
          </mat-card-title>

          <!--<mat-form-field class="col-6">
    <input type="text" [(ngModel)]="invoice.UserSettings.EmploymentNumber" matInput placeholder="Anställningsnummer" disabled />
  </mat-form-field>-->

          <mat-form-field class="col-6">
            <input type="text" [(ngModel)]="invoice.UserSettings.CompanyName" matInput placeholder="Företagsnamn" disabled />
          </mat-form-field>

          <mat-form-field class="col-6">
            <input type="text" [(ngModel)]="invoice.UserSettings.MemberNumber" matInput placeholder="Medlemsnummer" disabled />
          </mat-form-field>

          <mat-form-field class="col-6">
            <input type="text" [(ngModel)]="invoice.UserSettings.FirstName" matInput placeholder="Förnamn" disabled />
          </mat-form-field>

          <mat-form-field class="col-6">
            <input type="text" [(ngModel)]="invoice.UserSettings.LastName" matInput placeholder="Efternamn" disabled />
          </mat-form-field>

          <mat-form-field class="col-6">
            <input type="text" [(ngModel)]="invoice.InvoiceNumber" matInput placeholder="Fakturanummer" disabled />
          </mat-form-field>

          <mat-form-field class="col-6">
            <input type="text" [(ngModel)]="invoice.Id" matInput placeholder="Faktura-id" disabled />
          </mat-form-field>

          <mat-form-field class="col-12">
            <input type="text" [(ngModel)]="invoice.Ocr" matInput placeholder="OCR" disabled />
          </mat-form-field>

          <mat-form-field class="col-5">
            <input matInput [matDatepicker]="pickerInPayment" [(ngModel)]="invoice.PaymentDate" (dateChange)="invoice.PaymentDate = toFormattedDate($event.value)" placeholder="Inbetalningsdatum">
            <mat-datepicker-toggle matSuffix [for]="pickerInPayment"></mat-datepicker-toggle>
            <mat-datepicker #pickerInPayment></mat-datepicker>
          </mat-form-field>

          <!-- PaidTotal is pre-set to the total invoice amount inc vat, which is set when invoice is sent -->
          <mat-form-field class="col-5">
            <input type="number" maxlength="10" [ngModel]="invoice.PaidTotal" (ngModelChange)="invoice.PaidTotal=$event; updateAmounts();" matInput placeholder="Totalt inbetalt från kund" />
          </mat-form-field>

          <span class="col-2">
            SEK
          </span>
          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.TotalInvoiceAmountIncVat" (ngModelChange)="invoice.TotalInvoiceAmountIncVat=$event" name="TotalInvoiceAmountIncVat" matInput placeholder="Totalt fakturerat inkl. moms" disabled />
          </mat-form-field>
          <span class="col-2">
            {{invoice.Currency.Name}}
          </span>
          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.TotalVatAmount" matInput placeholder="Totalt fakturerad moms" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-10">
            <input type="number" [ngModel]="InvoiceFeeReceiver" matInput placeholder="Fakturaavgift" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.TotalInvoiceAmount" matInput placeholder="Totalt fakturerat exkl. moms" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

        </mat-card>

      </div>

      <!--Fees added to invoice-->
      <div class="col-sm-12 col-md-6" style="height: 600px; margin-bottom: 20px;">

        <mat-card>
          <mat-card-title>Avgifter som dras från fakturerat belopp</mat-card-title>

          <mat-form-field class="col-10">
            <mat-icon *ngIf="!invoice.InvoiceFeeUnlocked && !invoice.FeePaidByReciever" (click)="invoice.InvoiceFeeUnlocked = !invoice.InvoiceFeeUnlocked" after matSuffix style="cursor: pointer;">lock</mat-icon>
            <mat-icon *ngIf="invoice.InvoiceFeeUnlocked  && !invoice.FeePaidByReciever" (click)="invoice.InvoiceFeeUnlocked = !invoice.InvoiceFeeUnlocked" after color="accent" matSuffix style="cursor: pointer;">lock_open</mat-icon>
            <input type="number" [disabled]="!invoice.InvoiceFeeUnlocked" [ngModel]="InvoiceFeeMember | number:'1.0-0'" (change)="updateAmounts()" (ngModelChange)="invoice.InvoiceFee=$event; InvoiceFeeMember=$event;" matInput placeholder="Fa.avgift medlem" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-10">
            <mat-icon *ngIf="!invoice.ServiceFeeUnlocked" (click)="invoice.ServiceFeeUnlocked = !invoice.ServiceFeeUnlocked" after matSuffix style="cursor: pointer;">lock</mat-icon>
            <mat-icon *ngIf="invoice.ServiceFeeUnlocked" (click)="invoice.ServiceFeeUnlocked = !invoice.ServiceFeeUnlocked" after color="accent" matSuffix style="cursor: pointer;">lock_open</mat-icon>
            <input type="number" [disabled]="!invoice.ServiceFeeUnlocked" [ngModel]="invoice.ServiceFee" (ngModelChange)="invoice.ServiceFee=$event" matInput placeholder="SAMpoolen-avgift" (change)="updateAmounts()" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-10">
            <mat-icon *ngIf="!invoice.ExchangeFeeUnlocked" (click)="invoice.ExchangeFeeUnlocked = !invoice.ExchangeFeeUnlocked" after matSuffix style="cursor: pointer;">lock</mat-icon>
            <mat-icon *ngIf="invoice.ExchangeFeeUnlocked" (click)="invoice.ExchangeFeeUnlocked = !invoice.ExchangeFeeUnlocked" after color="accent" matSuffix style="cursor: pointer;">lock_open</mat-icon>
            <input type="number" [disabled]="!invoice.ExchangeFeeUnlocked" [ngModel]="invoice.ExchangeFee | number:'1.0-0'" (ngModelChange)="invoice.ExchangeFee=$event" matInput placeholder="Bankavgift" (change)="updateAmounts()" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.ReminderFee | number:'1.0-0'" (ngModelChange)="invoice.ReminderFee=$event" matInput placeholder="Påminnelseavgift" (change)="updateAmounts()" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.InkassoFee" (ngModelChange)="invoice.InkassoFee=$event" matInput placeholder="Inkassoavgift" (change)="updateAmounts()" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.KronofogdenFee" (ngModelChange)="invoice.KronofogdenFee=$event" matInput placeholder="Avgift Kronofogden" (change)="updateAmounts()" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.CustomFee" (ngModelChange)="invoice.CustomFee=$event" matInput placeholder="Manuell avgift" (change)="updateAmounts()" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-10">
            <input type="number" [(ngModel)]="invoice.ManualDiscount" matInput placeholder="Manuell rabatt eller gåva" (change)="updateAmounts()" />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
        </mat-card>
      </div>

      <div class="col-lg-6 col-sm-12">
        <mat-card class="grey padding30">
          <mat-checkbox matInput [ngModel]="InvoiceFeePaidByCustomer" (change)="changeValue($event)">Kunden har betalat fakturaavgiften</mat-checkbox>
        </mat-card>

        <mat-card>
          <mat-form-field class="col-10">
            <input type="text" [(ngModel)]="invoice.FeeDescription" matInput placeholder="Fritext / kommentar" />
          </mat-form-field>
        </mat-card>
      </div>

      <!--Partial total amount-->
      <div class="col-lg-6 col-sm-12">
        <mat-card>
          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.FeesTotalAmount" matInput placeholder="Summa avgifter (inkl kunds ev fakturaavgift)" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-10">
            <input type="number" [ngModel]="invoice.ManualDiscount" matInput placeholder="Manuell rabatt" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>

          <mat-form-field class="col-10">
            <input type="number" [value]="PartialTotalAmount" [(ngModel)]="PartialTotalAmount" matInput placeholder="Delsumma (lönegrundande belopp + nettoersättningar)" disabled />
          </mat-form-field>
          <span class="col-2">
            SEK
          </span>
        </mat-card>
      </div>

      <!-- If a payment date is set, a transaction log is created, otherwise it is just saved -->
      <div class="col-lg-12 col-sm-12" style="margin-top: 10px; padding-right: 25px; float: left;">
        <button mat-flat-button color="accent" (click)="save(invoice)" [disabled]="isLoading" style="float:right; display: block;">Spara</button>
        <a href="#/transactions" class="link">Gå till Ekonomi</a>
      </div>


    </div>


    <!--   ***********   -->
    <!--   UTBETALNING   -->
    <!--   ***********   -->

    <div class="col-md-12">
      <h3 class="main-heading" style="margin-top: 20px;">Utbetalningar</h3>
    </div>

    <div *ngFor="let subInvoice of invoice?.SubInvoices">
      <div *ngIf="!isLoading" class="row align-items-end" style="background-color: #f3f3f3; margin-top: 20px; padding-top: 20px; padding-bottom: 30px;">
        <!--For commission invoice-->
        <div *ngIf="subInvoice?.Type != null">
          <div class="row" *ngIf="subInvoice?.Type?.TechnicalName == 'CommissionCashInvoice'">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row col-lg-12">
                <div class="col-sm-6 col-md-6 col-lg-3">
                  <h3>Provision</h3>
                </div>
              </div>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row col-12">
                <div class="col-sm-6 col-md-6 col-lg-3">Fakturanummer: {{subInvoice?.InvoiceNumber}}</div>
                <div class="col-sm-6 col-md-6 col-lg-3">Faktura-id: {{subInvoice?.Id}} </div>
                <div class="col-sm-12 col-md-12 col-lg-3">Namn: {{subInvoice?.UserSettings?.CompanyName}}</div>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
              <mat-card style="min-height: 350px;">
                <div class="row col-12">
                  <span class="col-sm-6 col-md-6 col-lg-6">Provisionsbelopp</span><span class="col-sm-6 col-md-6 col-lg-6"><strong>{{subInvoice.Commission | number: '1.0-3'}}</strong></span>
                </div>
                <div class="row col-12">
                  <span class="col-sm-6 col-md-6 col-lg-6">Provisionsprocent</span><span class="col-sm-6 col-md-6 col-lg-6"><strong>{{invoice?.Commission * 100}} %</strong></span>
                </div>
                <div class="row col-12">
                  <span class="col-sm-6 col-md-6 col-lg-6">Delsumma utan nettoersättningar</span><span class="col-sm-6 col-md-6 col-lg-6"><strong>{{PartialAmountExclNetExpenses | number}}</strong></span>
                </div>
                <div class="row col-12">
                  <span class="col-sm-6 col-md-6 col-lg-6">Provisionsberäkning</span><span class="col-sm-6 col-md-6 col-lg-6"><strong>{{PartialAmountExclNetExpenses | number: '1.0-2'}} x {{invoice?.Commission  | number: '1.0-3'}} = {{invoice?.Commission * PartialAmountExclNetExpenses | number: '1.0-0'}}</strong></span>
                </div>
              </mat-card>
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
              <mat-card style="min-height: 350px;">
                <div class="row col-lg-12" style="margin: 0 0 20px -15px">
                  <div class="col-sm-6 col-md-6 col-lg-6">Totalt att betala ut:</div><div class="col-sm-6 col-md-6 col-lg-6"><b>{{ subInvoice?.TotalPayout }}</b> </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">Bank:</div><div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.UserSettings?.BankName }} </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">Clearing + kontonummer: </div><div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.UserSettings?.ClearingNumber }} {{ subInvoice?.UserSettings?.AccountNumber }}</div>
                  <div class="col-sm-6 col-md-6 col-lg-6">Bankgiro: </div><div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.UserSettings?.Bankgiro }}</div>
                  <div class="col-sm-6 col-md-6 col-lg-6">Plusgiro: </div><div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.UserSettings?.Plusgiro }}</div>
                  <div class="col-sm-6 col-md-6 col-lg-6">BIC: </div><div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.UserSettings?.Bic }}</div>
                  <div class="col-sm-6 col-md-6 col-lg-6">IBAN: </div><div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.UserSettings?.Iban }}</div>
                  <div class="col-sm-6 col-md-6 col-lg-6">Text avsändarkonto: </div><div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.UserSettings?.EmploymentNumber }} {{ subInvoice?.InvoiceNumber }} </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">Text mottagarkonto: </div><div class="col-sm-6 col-md-6 col-lg-6">sam{{invoice?.InvoiceNumber}}</div>
                </div>

                <div class="row col-lg-12" style="margin: 0 0 20px -15px">
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <mat-form-field>
                      <input matInput [matDatepicker]="pickerPayment" [(ngModel)]="subInvoice.PayoutDate" (dateChange)="subInvoice.PayoutDate = toFormattedDate($event.value)" placeholder="Datum för utbetalning:">
                      <mat-datepicker-toggle matSuffix [for]="pickerPayment"></mat-datepicker-toggle>
                      <mat-datepicker #pickerPayment></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6">
                    <button *ngIf="subInvoice" color="accent" mat-flat-button (click)="updatePayoutDate(subInvoice.Id, subInvoice.PayoutDate)" [disabled]="isLoading || subInvoice.PayoutDate == null" style="float:right;">Spara datum</button>
                  </div>

                  <div *ngIf="subInvoice.ExpressPayOut" style="color: red; font-size: 80%; padding-left: 15px; font-style: italic;">
                    Expressutbetalning är vald, men moderfakturan är ej bekräftad
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
        <!-- For regular sub invoices -->
        <div class="row" *ngIf="subInvoice.Type == null || subInvoice?.Type?.TechnicalName != 'CommissionCashInvoice'">

          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="row col-lg-12">
              <div class="col-sm-6 col-md-6 col-lg-3">
                <h3>Löneutbetalning</h3>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12">
            <span class="col-sm-6 col-md-6 col-lg-3" *ngIf="subInvoice.ExpressPayOut"><strong style="color: red;">Express</strong></span>
            <span class="col-sm-6 col-md-6 col-lg-3" *ngIf="subInvoice?.UserSettings?.TaxAdjusted >= 0"><strong style="color: red;">Skatten är justerad</strong></span>
            <span class="col-sm-6 col-md-6 col-lg-3" *ngIf="subInvoice?.UserSettings?.AttachmentOfSalaries.length != 0 && subInvoice?.UserSettings?.AttachmentOfSalaries.length > 0"><strong style="color: red;">Medlemmen har löneutmätning</strong></span>
          </div>

          <div class="col-sm-12 col-md-12 col-lg-12">
            <div class="row col-12">
              <div class="col-sm-6 col-md-6 col-lg-3">Fakturanummer: {{subInvoice?.InvoiceNumber}}</div>
              <div class="col-sm-6 col-md-6 col-lg-3">Faktura-id: {{subInvoice?.Id}} </div>
              <div class="col-sm-12 col-md-12 col-lg-3">Namn: {{subInvoice?.UserSettings?.FirstName}} {{subInvoice?.UserSettings?.LastName}} </div>
              <div class="col-sm-6 col-md-2 col-lg-3">Anställningsnr: {{subInvoice?.UserSettings?.EmploymentNumber}} </div>
              <div class="col-sm-6 col-md-6 col-lg-3">Delsumma: <strong>{{subInvoice?.PartialTotalAmount}}</strong> SEK</div>
              <div class="col-sm-6 col-md-6 col-lg-6">(Avdragen SAMpoolen-avgift: {{subInvoice?.ServiceFee}} SEK {{ServiceFeeInfo}})</div>
              <!-- </mat-card>-->
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6">
            <mat-card style="min-height: 350px;">
              <div class="row col-12">

                <span class="col-sm-6 col-md-6 col-lg-6">Privata avdrag</span><span class="col-sm-6 col-md-6 col-lg-6">{{subInvoice?.DeductionsTotalAmount}}</span>
                <span class="col-sm-6 col-md-6 col-lg-6">Traktamenten</span><span class="col-sm-6 col-md-6 col-lg-6">{{subInvoice?.AllowancesTotalAmount}}</span>
                <span class="col-sm-6 col-md-6 col-lg-6">Milersättning</span><span class="col-sm-6 col-md-6 col-lg-6">{{subInvoice?.MileageTotalAmount}}</span>
                <span class="col-sm-6 col-md-6 col-lg-6">Summa nettoersättningar</span><span class="col-sm-6 col-md-6 col-lg-6">{{subInvoice?.ExpensesTotalAmount}}</span>
                <br /><br />
                <span class="col-sm-6 col-md-6 col-lg-6">Lönegrundande belopp</span><span class="col-sm-6 col-md-6 col-lg-6"><strong>{{subInvoice?.PartialAmountExclNetExpenses}}</strong></span>
                <br /><br />
                <span class="col-sm-6 col-md-6 col-lg-6">Arbetsgivaravgift</span><span class="col-sm-3 col-md-3 col-lg-3">{{subInvoice?.ArbetsgivarAvgift}}</span><span class="col-sm-3 col-md-3 col-lg-3">{{subInvoice?.UserSettings?.EmployerTax?.Tax * 100 | number: '1.0-2'}} %</span>
                <span class="col-sm-6 col-md-6 col-lg-6">Bruttolön</span><span class="col-sm-6 col-md-6 col-lg-6">{{subInvoice?.BruttoLon}}</span>
                <span class="col-sm-6 col-md-6 col-lg-6">Kommunalskatt</span><span class="col-sm-3 col-md-3 col-lg-3">{{subInvoice?.PayoutMunicipalityTax}}</span>
                <span class="col-sm-3 col-md-3 col-lg-3" *ngIf="subInvoice.UserSettings.TaxAdjusted < 0">{{ subInvoice?.UserSettings?.Municipality?.Tax * 100  | number: '1.2-2'}} %</span>
                <span class="col-sm-3 col-md-3 col-lg-3" *ngIf="subInvoice.UserSettings.TaxAdjusted >= 0">{{ subInvoice?.UserSettings?.TaxAdjusted * 100  | number: '1.2-2'}} %</span>
                <span class="col-sm-6 col-md-6 col-lg-6">Nettolön</span><span class="col-sm-6 col-md-6 col-lg-6">{{subInvoice?.NettoLon}}</span>
                <span class="col-lg-12" *ngIf="subInvoice?.UserSettings?.HasCoordinationNumber" style="margin-top: 15px; font-size: 80%; font-style: italic;">Medlemmen har samordningsnummer. Angivet födelseår: {{ subInvoice?.UserSettings?.BirthYear }}</span>
              </div>
            </mat-card>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-6">
            <mat-card style="min-height: 350px;">
              <div class="row col-lg-12" style="margin: 0 0 20px -15px">
                <div class="row col-12 marginbottom15">
                  <span class="col-sm-6 col-md-6 col-lg-6">Nettolön</span><span class="col-6">{{subInvoice?.NettoLon}}</span>
                  <span class="col-sm-6 col-md-6 col-lg-6">Summa nettoersättningar</span><span class="col-sm-6 col-md-6 col-lg-6">{{subInvoice?.ExpensesTotalAmount}}</span>
                  <span class="col-sm-6 col-md-6 col-lg-6">Beräkning utbetalning</span><span class="col-sm-6 col-md-6 col-lg-6">{{subInvoice?.PayOutTotal}}</span>
                </div>
                <br /><br />

                <!--<div class="row redbordercalc" *ngIf="subInvoice.AttachmentOfSalary.AttachmentAmount > 0">
                  <h4 class="col-lg-12">Utmätning av lön</h4>
                  <div class="col-sm-6 col-md-6 col-lg-6">Total nettolön denna månad:</div><div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.AttachmentOfSalary?.TotalSalaryPayoutThisMonth }}</div>
                  <div class="col-sm-6 col-md-6 col-lg-6">Totalt utmätt denna månad:</div><div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.AttachmentOfSalary?.TotalAttachmentPayoutThisMonth}} </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">Reservationsbelopp:</div><div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.AttachmentOfSalary?.ReservedAmount }}</div>
                  <div class="col-sm-6 col-md-6 col-lg-6">Utmätningsbelopp:</div><div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.AttachmentOfSalary?.AttachmentAmount}} </div>
                </div>-->

                <div class="row col-12 marginbottom15">
                  <div class="col-sm-6 col-md-6 col-lg-6">Totalt att betala ut:</div><div class="col-sm-6 col-md-6 col-lg-6"><b>{{ subInvoice?.PayOutTotal }}</b> </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">Personnummer:</div><div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.UserSettings?.PersonalNumber}} </div>
                </div>
                <br /><br />

                <div class="row col-12 marginbottom15" *ngIf="subInvoice?.PensionSavingsAmount > 0">
                  <div class="col-sm-6 col-md-6 col-lg-6">Totalt till tjänstepension:</div> <div class="col-sm-6 col-md-6 col-lg-6"><b>{{ subInvoice?.PensionSavingsInclTax}}</b> ({{ subInvoice?.PensionSavingsAmount + '+' + subInvoice?.PensionSavingsTaxAmount}})</div>
                  <div class="col-sm-6 col-md-6 col-lg-6">Tjänstepensionsföretag:</div> <div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.UserSettings?.PensionSavingsSettings?.PensionSavingsCompany?.Name }}</div>                  
                </div>

                <div class="row col-12 marginbottom15">
                  <div class="col-sm-6 col-md-6 col-lg-6">Bank:</div><div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.UserSettings?.BankName }} </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">Clearing + kontonummer: </div><div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.UserSettings?.ClearingNumber }} {{ subInvoice?.UserSettings?.AccountNumber }}</div>
                  <div class="col-sm-6 col-md-6 col-lg-6">Text avsändarkonto: </div><div class="col-sm-6 col-md-6 col-lg-6">{{ subInvoice?.UserSettings?.EmploymentNumber }} {{ subInvoice?.InvoiceNumber }} </div>
                  <div class="col-sm-6 col-md-6 col-lg-6">Text mottagarkonto: </div><div class="col-sm-6 col-md-6 col-lg-6">sam{{invoice?.InvoiceNumber}}</div>
                </div>
              </div>

              <div class="row col-lg-12" style="margin: 0 0 20px -15px">
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <mat-form-field>
                    <input matInput [matDatepicker]="pickerPayment" [(ngModel)]="subInvoice.PayoutDate" (dateChange)="subInvoice.PayoutDate = toFormattedDate($event.value)" placeholder="Datum för utbetalning:">
                    <mat-datepicker-toggle matSuffix [for]="pickerPayment"></mat-datepicker-toggle>
                    <mat-datepicker #pickerPayment></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                  <button *ngIf="subInvoice" color="accent" mat-flat-button (click)="updatePayoutDate(subInvoice.Id, subInvoice.PayoutDate)" [disabled]="isLoading || subInvoice.PayoutDate == null" style="float:right;">Spara datum</button>
                </div>

                <div *ngIf="subInvoice.ExpressPayOut" style="color: red; font-size: 80%; padding-left: 15px; font-style: italic;">
                  Expressutbetalning är vald, men moderfakturan är ej bekräftad
                </div>
              </div>
            </mat-card>
          </div>
        </div>
      </div>
    </div>

    <!--<div class="col-lg-12 col-sm-12" style="margin: 20px 0; float: left;">
      <button mat-flat-button color="accent" (click)="save(invoice)" [disabled]="isLoading" style="float:right;">Spara</button>
      <a href="#/transactions" class="link">Gå till Ekonomi</a>
    </div>-->

  </div>
</div>
