
<style>
  .fix-static-bug {
    width: 100% !important;
    position: static !important;
  }
</style>


<div class="fix-static-bug">
  <div class="container">
    <div class="row">
      <div class="col-md-12 entry" style="margin-top: 40px;">

        <div class="example-container mat-elevation-z8">

          <div class="title">
            <span>ANVÄNDARE ({{customerData?.length}})</span>

            <mat-menu #appMenu="matMenu">
              <button mat-menu-item (click)="getExcelReport()">Medlemslista</button>
            </mat-menu>

            <button mat-icon-button [matMenuTriggerFor]="appMenu">
              <mat-icon>more_vert</mat-icon>
            </button>

          </div>
          <div style="background-color: #FAFAFA; padding-left:24px;">
            <mat-form-field floatPlaceholder="never">
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Sök">
            </mat-form-field>
          </div>

          <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" matSortActive="name" matSortDirection="asc" matSortDisableClear>

            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Namn/kontakt </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span><b>{{row?.FirstName}} {{row?.LastName}} </b></span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="companyname">
              <mat-header-cell *matHeaderCellDef mat-sort-header> Företagsnamn </mat-header-cell>
              <mat-cell *matCellDef="let row">
                <span *ngIf="!row.IsCompany"><b></b></span>
                <span *ngIf="row.IsCompany"><b>{{row?.CompanyName}} </b></span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="memberNumber">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> Nr </mat-header-cell>
              <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm> {{row?.MemberNumber}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="personalNumber">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> Personnummer </mat-header-cell>
              <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm> {{row?.PersonalNumber}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> E-postadress </mat-header-cell>
              <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm> {{row?.Email}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="created">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> Medlem sedan </mat-header-cell>
              <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm> {{(row?.Created == '0001-01-01T00:00:00' ? '' : row?.Created) | date: 'yyyy-MM-dd'}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="lastLogin">
              <mat-header-cell *matHeaderCellDef mat-sort-header fxShow="false" fxShow.gt-sm> Senast inloggad </mat-header-cell>
              <mat-cell *matCellDef="let row" fxShow="false" fxShow.gt-sm> {{(row?.LastLogin == '0001-01-01T00:00:00' ? '' : row?.LastLogin) | date: 'yyyy-MM-dd'}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef style="border-bottom:none;">.</th>
              <td mat-cell *matCellDef="let row" style="border-bottom:none;">

                <button mat-mini-fab color="accent" style="float:right;" [matMenuTriggerFor]="menu">
                  <mat-icon>settings</mat-icon>
                </button>
                <mat-menu #menu="matMenu" color="accent">
                  <button mat-menu-item (click)="addInvoice(row)"><mat-icon>note_add</mat-icon>Ny faktura</button>
                  <button mat-menu-item (click)="edit(row)"><mat-icon>edit</mat-icon>Redigera</button>
                  <button mat-menu-item (click)="delete(row)"><mat-icon>delete</mat-icon>Radera</button>
                </mat-menu>
              </td>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;">
            </mat-row>
          </mat-table>

          <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>

        </div>


      </div>
    </div>

  </div>

</div>
