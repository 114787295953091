
<style>
  .frame::-webkit-scrollbar {
    -webkit-appearance: none;
  }

    .frame::-webkit-scrollbar:vertical {
      width: 11px;
    }

    .frame::-webkit-scrollbar:horizontal {
      height: 11px;
    }

  .frame::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, .5);
  }

  .frame::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
  }

  /*No footer in dialog fix */
  mat-dialog-content {
    overflow: initial !important;
  }

  .mat-dialog-container {
    height: auto !important;
  }

  .errormessage {
      color: red;
      font-size: 80%;
      font-style: italic;
  }
</style>

<h1 mat-dialog-title *ngIf="isNewCustomer" style="margin-top: 5px;margin-bottom: 35px;">Ny kund</h1>
<h2 mat-dialog-title *ngIf="!isNewCustomer" style="margin-top: 5px;margin-bottom: 35px;">Redigera kund</h2>

<div *ngIf="!customer.Active">Kund med detta organisationsnummer kan inte användas längre.</div>

<div *ngIf="!isAdmin && customer.PublicInfo != ''">
  <span style="color: red;">{{ customer.PublicInfo }}</span>
</div>

<form #personForm="ngForm" novalidate>

  <div *ngIf="isAdmin">
    <mat-checkbox *ngIf="isAdmin" [(ngModel)]="customer.Global" name="_global" (change)="customer.Global = $event.checked" style="margin-left:15px;">Global kund</mat-checkbox> <br /><br />
    <mat-checkbox *ngIf="isAdmin" [(ngModel)]="customer.Active" name="_active" (change)="customer.Active = $event.checked" style="margin-left:15px;">Aktiv</mat-checkbox> <br /><br />
  </div>


  <!--Regular text field shown to admin, and only as text to member-->
  <div *ngIf="isAdmin">
    <mat-form-field style="width:100%; margin-top:20px;">
      <mat-label>Info om kunden som syns för medlem</mat-label>
      <input matInput type="text" [(ngModel)]='customer.PublicInfo' [disabled]="!isAdmin" placeholder="Info om kunden som syns för medlem" name="PublicInfo">
    </mat-form-field>
  </div>

  <div *ngIf="isAdmin">
    <mat-form-field style="width:100%; margin-top:20px;">
      <mat-label>Info om kunden som enbart syns för admin</mat-label>
      <input matInput type="text" [(ngModel)]='customer.AdminInfo' [disabled]="!isAdmin" placeholder="Info om kunden som enbart syns för admin" name="AdminInfo">
    </mat-form-field>
  </div>

  <mat-checkbox [(ngModel)]="customer.IsCompany" [disabled]="!isNewCustomer && !isAdmin" name="_isPrivatePerson2" (change)="customer.IsCompany = $event.checked" style="margin-left:15px;">Företag</mat-checkbox> &nbsp;
  <mat-checkbox [(ngModel)]="!customer.IsCompany" [disabled]="!isNewCustomer && !isAdmin" name="_isPrivatePerson" (change)="customer.IsCompany = !$event.checked" style="margin-left:15px;">Privatperson</mat-checkbox> &nbsp;

  <mat-form-field *ngIf="customer.Country.Id != null" style="width:100%; margin-top:20px;">
    <mat-select [disabled]="!isNewCustomer && !isAdmin" name="country" placeholder="Land" [(ngModel)]="customer?.Country.Id" (ngModelChange)="customer.Country.Id = $event; getCountryName(customer.Country.Id);orgNumberValidCheck(searchAccountValue)" [ngModelOptions]="{standalone: true}" required>
      <mat-option *ngFor="let c of countries" [value]="c.Id">
        {{ c.Name }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <!--Searchbox only shown to users adding a new business customer-->
  <div *ngIf="customer.IsCompany && !isAdmin && isNewCustomer">
    <div style="margin-bottom: 20px;">
      <mat-form-field style="width:100%;">
        <mat-label>Organisationsnummer / VAT</mat-label>
        <input matInput [(ngModel)]="searchAccountValue" (ngModelChange)="orgNumberValidCheck($event)" name="searchCompany" id="searchCompany" type="text" placeholder="NNNNNN-NNNN">
      </mat-form-field>
      <span class="errormessage">{{errorMessageOrgnr}}</span>
    </div>
  </div>

  <!--Regular text field shown to admin, and disabled for users when a customer is once saved-->
  <div *ngIf="(customer.IsCompany && isAdmin) || !isNewCustomer">
    <mat-form-field style="width:100%; margin-top:20px;">
      <mat-label>Organisationsnummer / VAT</mat-label>
      <input matInput type="text" [(ngModel)]='customer.OrgNumber' [disabled]="!isNewCustomer && !isAdmin" placeholder="NNNNNN-NNNN (10 siffror och bindestreck)" required name="OrgNumber">
    </mat-form-field>
  </div>

  <!--Other form fields are shown when orgnr is added, or if private person customer. Always shown to admin-->
  <div *ngIf="!customer.IsCompany || isAdmin || !isNewCustomer || (customer.IsCompany && orgNumberValid)">
    <mat-form-field style="width:100%;">
      <input matInput type="text" [disabled]="customer.NameLocked && !isAdmin" [(ngModel)]='customer.Name' placeholder="Namn" required name="name">
    </mat-form-field>
    <mat-form-field style="width:100%; margin-top:20px;" *ngIf="!customer.IsCompany">
      <mat-label>Personnummer</mat-label>
      <input matInput type="text" [disabled]="customer.OrgNumberLocked && !isAdmin" (ngModelChange)="pNumberValidCheck($event)" [(ngModel)]='customer.OrgNumber' placeholder="NNNNNN-NNNN (10 siffror och bindestreck)" required name="Personnummer">
    </mat-form-field>
    <span class="errormessage">{{errorMessagePnr}}</span>
    <mat-form-field style="width:100%;">
      <input matInput type="text" [disabled]="customer.PhoneLocked && !isAdmin" (ngModelChange)="phoneNumberValidCheck($event)" [(ngModel)]='customer.Phone' placeholder="Telefon" name="Phone">
    </mat-form-field>
    <span class="errormessage">{{errorMessagePhoneNr}}</span>
    <mat-form-field style="width:100%;">
      <input matInput type="email" [disabled]="customer.InvoiceEmailLocked && !isAdmin" #correctEmail='ngModel' ngModel [(ngModel)]='customer.InvoiceEmail' placeholder="Epost för faktura" name="InvoiceEmail">
      <mat-icon *ngIf="!correctEmail.valid && !customer.InvoiceEmailLocked" matSuffix color="accent">warning</mat-icon>
    </mat-form-field>
    <mat-form-field style="width:100%;">
      <input matInput type="text" [disabled]="customer.CoLocked && !isAdmin" [(ngModel)]='customer.Co' placeholder="C/o" name="Co">
    </mat-form-field>
    <mat-form-field style="width:100%;">
      <input matInput type="text" [disabled]="customer.AddressLocked && !isAdmin" [(ngModel)]='customer.Address' placeholder="Adress" required name="Address">
    </mat-form-field>
    <mat-form-field style="width:100%;">
      <input matInput type="text" [disabled]="customer.Address2Locked && !isAdmin" [(ngModel)]='customer.Address2' placeholder="Adress 2" name="Address 2">
    </mat-form-field>
    <mat-form-field style="width:100%;">
      <input matInput type="text" [disabled]="customer.ZipLocked && !isAdmin" [(ngModel)]='customer.Zip' placeholder="Postnummer" required name="Zip">
    </mat-form-field>
    <mat-form-field style="width:100%;">
      <input matInput type="text" [disabled]="customer.CityLocked && !isAdmin" [(ngModel)]='customer.City' placeholder="Ort" required name="City">
    </mat-form-field>
    <mat-form-field appearance="outline" style="width:100%;">
      <mat-label>GLN-nummer (ej obligatorisk)</mat-label>
      <input matInput type="text" [disabled]="customer.GlnNumberLocked && !isAdmin" [(ngModel)]='customer.GlnNumber' placeholder="GLN-nummer för e-faktura" name="glnnumber">
      <mat-icon color="accent" matSuffix matTooltipPosition="before" area-label="Hover to display info" matTooltip="GLN-nummer är endast krav när man skickar en e-faktura, vilket inte ska förväxlas med PDF-faktura via e-post. Man kan förklara GLN-nummer som en adress dit man skickar den elektroniska fakturan. Din kund kan förse dig med den information du behöver.">info</mat-icon>
    </mat-form-field>

    <p>Förvald leveransmetod för faktura</p>
    <mat-radio-group name="invoiceSendType" [(ngModel)]="customer.InvoiceSendType">
      <mat-radio-button class="ml-2 radiobuttonmargin" [disabled]="customer.InvoiceSendTypeLocked" [value]="0">Ingen förvald</mat-radio-button>
      <mat-radio-button class="ml-2 radiobuttonmargin" [disabled]="customer.InvoiceSendTypeLocked" [value]="2">Post</mat-radio-button>
      <mat-radio-button class="ml-2 radiobuttonmargin" [disabled]="customer.InvoiceSendTypeLocked" [value]="1">E-post</mat-radio-button>
      <mat-radio-button class="ml-2 radiobuttonmargin" [disabled]="customer.InvoiceSendTypeLocked" [value]="3">E-faktura</mat-radio-button>
    </mat-radio-group>
    <br><br>

    <div *ngIf="isAdmin" class="row" style="margin-bottom: 20px;">
      <div class="col-lg-12">
        <span class="bold" style="position: relative; top: 18px;">Betalvillkor:</span>
      </div>
      <div class="col-lg-12">
        <mat-form-field>
          <mat-select [ngModel]="customer.PaymentTerm" (ngModelChange)="customer.PaymentTerm = $event" name="paymentterm">
            <mat-option *ngFor="let item of paymentTerms" [value]="item">{{item}} dagar</mat-option>
          </mat-select>
        </mat-form-field>
        <p style="font-style: italic;">0 dagar = inget förvalt värde</p>
      </div>
    </div>

    <div *ngIf="isAdmin" class="row" style="margin-bottom: 10px;">
      <div class="col-12">
        <span class="bold">Kunden kräver elektronisk referens: </span>
      </div>
      <div class="col-12">
        <mat-checkbox [(ngModel)]="customer.ElectronicReferenceMandatory" (change)="customer.ElectronicReferenceMandatory = $event.checked;" name="referenceyes">Ja</mat-checkbox>
        <mat-checkbox [(ngModel)]="!customer.ElectronicReferenceMandatory" (change)="customer.ElectronicReferenceMandatory = !$event.checked;" style="margin-left:15px;" name="referenceno">Nej</mat-checkbox>
      </div>
    </div>

    <!--If electronic reference is mandatory-->
    <div *ngIf="customer.ElectronicReferenceMandatory && !isAdmin" style="margin-bottom: 20px;">
      <mat-form-field appearance="outline" style="width:100%;">
        <mat-label>Elektronisk referens</mat-label>
        <input matInput type="text" [(ngModel)]='customer.ElectronicReference' placeholder="Elektronisk referens obligatorisk" name="ereference">
        <mat-icon color="accent" matSuffix matTooltipPosition="before" area-label="Hover to display info" matTooltip="Din kund kräver elektronisk referens på e-fakturor. Oftast är detta en sifferkod, men kan variera. Om du inte fått info från din kund behöver du fråga din kund efter elektronisk referens på e-fakturor.">info</mat-icon>
      </mat-form-field>
      <span style="font-size: 80%; font-style: italic;">Din kund kräver elektronisk referens på e-fakturor. Oftast är detta en sifferkod, men kan variera. Om du inte fått info från din kund behöver du fråga din kund efter elektronisk referens på e-fakturor. Du kan fylla i en referenskod här så att de automatiskt adderas på framtida fakturor (som går att ändra när du skapar en faktura) eller så kan du fylla i informationen när du skapar en faktura.</span>
    </div>


    <!--If electronic reference is not mandatory-->
    <div *ngIf="(!customer.ElectronicReferenceMandatory && !isAdmin) || isAdmin">
      <mat-form-field appearance="outline" style="width:100%;">
        <mat-label>Elektronisk referens</mat-label>
        <input matInput type="text" [(ngModel)]='customer.ElectronicReference' placeholder="Elektronisk referens" name="ereference">
        <mat-icon color="accent" matSuffix matTooltipPosition="before" area-label="Hover to display info" matTooltip="Din kund kräver elektronisk referens på e-fakturor. Oftast är detta en sifferkod, men kan variera. Om du inte fått info från din kund behöver du fråga din kund efter elektronisk referens på e-fakturor.">info</mat-icon>
      </mat-form-field>
    </div>

    <div *ngIf="isAdmin" class="row" style="margin-bottom: 10px;">
      <div class="col-12">
        <span class="bold">Kunden godkänner fakturaavgift: </span>
      </div>
      <div class="col-12">
        <mat-checkbox [disabled]="customer.AcceptsInvoiceFeeLocked && !isAdmin" [(ngModel)]="customer.AcceptsInvoiceFee" (change)="customer.AcceptsInvoiceFee = $event.checked;" name="yes">Ja</mat-checkbox>
        <mat-checkbox [disabled]="customer.AcceptsInvoiceFeeLocked && !isAdmin " [(ngModel)]="!customer.AcceptsInvoiceFee" (change)="customer.AcceptsInvoiceFee = !$event.checked;" style="margin-left:15px;" name="no">Nej</mat-checkbox>
      </div>
    </div>
    <div *ngIf="!isAdmin && customer.AcceptsInvoiceFeeLocked && !customer.AcceptsInvoiceFee" class="row" style="margin-bottom: 10px;">
      <div class="col-12">
        <span class="bold">Denna kund godkänner inte att du fakturerar dem för fakturaavgiften.</span>
      </div>
    </div>

    <div *ngIf="isAdmin" class="row" style="margin-bottom: 10px;">
      <div class="col-12">
        <span class="bold">Kunden kräver detaljerad tidrapport: </span>
      </div>
      <div class="col-12">
        <mat-checkbox [disabled]="customer.TimeReportMandatory && !isAdmin" [(ngModel)]="customer.TimeReportMandatory" (change)="customer.TimeReportMandatory = $event.checked;" name="timeyes">Ja</mat-checkbox>
        <mat-checkbox [disabled]="customer.TimeReportMandatory && !isAdmin " [(ngModel)]="!customer.TimeReportMandatory" (change)="customer.TimeReportMandatory = !$event.checked;" style="margin-left:15px;" name="timeno">Nej</mat-checkbox>
      </div>
    </div>
    <div *ngIf="!isAdmin && customer.TimeReportMandatory" class="row" style="margin-bottom: 10px;">
      <div class="col-12">
        <span class="bold">Denna kund kräver att du anger detaljerad tidrapport på din faktura.</span>
      </div>
    </div>


  </div>

</form>

<div>{{errorMessage}}</div>

<button mat-raised-button style="float: right;" color="accent" [disabled]="(!personForm.valid || !phoneNumberValid || (customer.IsCompany && isNewCustomer && !orgNumberValid) || !customer.Active) && !isAdmin" (click)="save(customer)">
  <mat-icon>check</mat-icon>
  &nbsp;Spara&nbsp;
</button>&nbsp;



