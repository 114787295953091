import { Component, OnInit } from '@angular/core';
import { environment as env } from '@env/environment';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, MatCheckbox } from '@angular/material';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ICompany } from '../../core/interfaces/ICompany';
import { IContact } from '../../core/interfaces/IContact';
import { IUser } from '../../core/interfaces/user/IUser';
import { IInvoice } from '../../core/interfaces/IInvoice';
import { CompanyService } from '../../core/services/CompanyService';
import { InvoiceService } from '../../core/services/InvoiceService';
import { CurrencyService } from '../../core/services/CurrencyService';
import { IEmployerTax } from '../../core/interfaces/IEmployerTax';
import { UserService } from '../../core/services/UserService';
import { EmployerTaxService } from '../../core/services/EmployerTaxService';
import { debug } from '../../core/meta-reducers/debug.reducer';
import { IAllowance } from '../../core/interfaces/invoice';
import { ITransactionLog } from '../../core/interfaces/invoice/ITransactionLog';
import { IAttachmentOfSalary } from '../../core/interfaces/user/IAttachmentOfSalary';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'combined-invoice-summary',
  templateUrl: './combined-invoice-summary.component.html',
  styleUrls: ['./combined-invoice-summary.component.scss']
})

export class CombinedInvoiceSummaryComponent implements OnInit {
    routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
    versions = env.versions;
    id: number;
    companies = [];
    currencies = [];
    employerTaxes = [];
    //hoursWorkedTotal: number = 0;
  totalInvoiceAmount: number = 0;
  ref: IContact = { Id: 0, Address: "", City: "", Email: "", Name: "", NameLocked: false,  Phone: "", Zip: "", IDNumber: "" };
    
    invoice: IInvoice;

    invoiceQuickGroupTemplate = { Vat: "25", Type: { Value: "4", Name: "Övrigt" }, rowTypeSelected: "4" };
    invoiceQuickGroup = JSON.parse(JSON.stringify(this.invoiceQuickGroupTemplate));
    vatList = [];
    invoiceShareTypes = [];
    addToInvoiceList = [{ Name: 'Ja', Value: true }, { Name: 'Nej', Value: false }];
    paymentTerms = [10, 15, 20, 25, 30];
    isLoading = true;
    feePaidByReciever: boolean = false;
    feePaidBySender: boolean = false;
    milageTotalAmount = 0;
    allowancesTotalAmount = 0;
    payoutEmployerTax = null;
    PayoutMunicipalityTax = 0;
    TotalPayout: number;
    DeductionsTotalAmount: number;
    ExpensesTotalAmount: number;
    TotalInvoiceAmount: number;
    EmployerTax = 0
    PartialAmountExclNetExpenses: number;
    userEmployerTax: any;
    InvoiceFeeReceiver = 0;
    InvoiceFeeMember = 0;
    PersonalNumber: any;
    InvoiceFeePaidByCustomer = true;    
    PartialTotalAmount: number;
    PensionSavingsTaxFactor = 0.2426;
    PensionSavingsTaxAmount = 0;
    PensionSavingsInclTax: number;
    municipalityTaxRounded: number = 0;
    ServiceFeeInfo: string = "";


    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public dialog: MatDialog,
        private companyService: CompanyService,
        private invoiceService: InvoiceService,
        private currencyService: CurrencyService,
        private userService: UserService,
        private employerTaxService: EmployerTaxService
    ) {

    }
    
  ngOnInit() {
        this.route.params.subscribe(params => {
          this.id = params['id'] ? params['id'] : 0;
          this.loadInvoice(this.id);
        });

        this.getCompanies();
        this.loadCurrencies();
        this.loadEmployerTaxes();
        this.vatList.push({ Value: "25" }, { Value: "12" }, { Value: "6" }, { Value: "0" })
    }

  changeValue(value) {
    this.InvoiceFeePaidByCustomer = value.checked;
    this.invoice.InvoiceFeePaidByCustomer = this.InvoiceFeePaidByCustomer;
  }

  sendMessage(message: string) {
      this.invoiceService.sendMessage(message, this.id)
          .subscribe(
              response => this.messageSent(response),
              error => console.log(error));
  }

  messageSent(message: any) {
      this.invoice.Message = undefined;
      this.invoice.Messages.push(message);
  }

  currencyChanged() {
      for (var i = 0; i < this.currencies.length; i++) {
          var curr = this.currencies[i];
          if (curr.Id == this.invoice.Currency.Id) {
              this.invoice.Currency.Name = curr.Name;
          }
      }
  }

  loadInvoice(id: number) {
      this.invoiceService.getInvoiceShareTypes()
          .subscribe(
              response => this.invoiceShareTypes = response,
              error => console.log(error));
        
    this.invoiceService.getCombinedInvoiceSummary(id)
          .subscribe(
              response => this.onGetInvoiceCompleted(response),
            error => console.log(error));
  }

  loadCurrencies() {
      this.currencyService.getCurrencies()
          .subscribe(
              response => this.currencies = response,
              error => console.log(error));
  }

  loadEmployerTaxes() {
        this.employerTaxService.getEmployerTaxes()
            .subscribe(
                response => this.employerTaxes = response,
                error => console.log(error));
  }

  onGetInvoiceCompleted(invoice: any) {

    this.invoice = invoice;
    this.setInvoiceFee();
    //this.loadUserEmployerTax();
    debugger
    for (var i = 0; i < invoice.SubInvoices.length; i++) {
      this.updateSubInvoiceAmounts(invoice.SubInvoices[i]);
    }

    this.updateAmounts();
    this.isLoading = false;

  }

  loadUserEmployerTax(subInvoice, userId, checkZeroTax: boolean = false)
  {
    this.userService.getUserEmployerTax(userId, checkZeroTax)
      .subscribe(
        tax => this.employerTaxLoaded(subInvoice, tax),       
        error => console.log(error));
  }

  employerTaxLoaded(subInvoice: IInvoice, tax: any) { 
    subInvoice.EmployerTaxType = tax;
    subInvoice.UserSettings.EmployerTax.Tax = subInvoice.EmployerTaxType.Tax;
  }

  loadUserMunicipalityTax(subInvoice, userId, checkZeroTax: boolean = false) {

    var payoutdate = this.invoice.PayoutDate;

    if (payoutdate == null) {
      payoutdate = new Date();
    }

    this.userService.getUserMunicipalityTax(payoutdate, userId, checkZeroTax)
      .subscribe(
        tax => this.municipalityTaxLoaded(subInvoice, tax),
        error => console.log(error));
  }

  municipalityTaxLoaded(subInvoice: IInvoice, tax: any) {    
    
    subInvoice.UserSettings.Municipality.Tax = tax;
    
    //Makes sure municipality tax always is calculated with 2 decimals, even if 3 in db table
    // Use temporary tax if present instead of municipality standard tax
    if (subInvoice.UserSettings.TaxAdjusted >= 0) {
      subInvoice.UserSettings.Municipality.Tax = parseFloat(subInvoice.UserSettings.TaxAdjusted.toFixed(4));
    }
    else {
      subInvoice.UserSettings.Municipality.Tax = parseFloat(subInvoice.UserSettings.Municipality.Tax.toFixed(4));
    }
    
  }

  updateAmounts() {
    this.invoice.BruttoLon = 0;
    this.invoice.NettoLon = 0;
    this.payoutEmployerTax = 0;


    //Add all fees 
    this.invoice.FeesTotalAmount = this.InvoiceFeeMember + this.InvoiceFeeReceiver + this.invoice.ExchangeFee + this.invoice.KronofogdenFee + this.invoice.ReminderFee + Math.round(this.invoice.ServiceFee) + this.invoice.InkassoFee + this.invoice.CustomFee;

    //Partial total amount from which payout is calculated
    this.PartialTotalAmount = Math.round(this.invoice.PaidTotal - this.invoice.TotalVatAmount - this.invoice.FeesTotalAmount + this.invoice.ManualDiscount);

    //Total amount of expenses
    this.invoice.ExpensesTotalAmount = Math.round(this.DeductionsTotalAmount + this.milageTotalAmount + this.allowancesTotalAmount);

    //Amount from which employer tax is deducted
    this.PartialAmountExclNetExpenses = Math.round(this.PartialTotalAmount - this.invoice.ExpensesTotalAmount);
    if (this.PartialAmountExclNetExpenses < 0) {
      this.PartialAmountExclNetExpenses = 0;
    }
    this.TotalInvoiceAmount = this.invoice.TotalInvoiceAmount;

    //Calculates the total payout amount
    this.invoice.PayOutTotal = Math.round(this.invoice.NettoLon + this.invoice.ExpensesTotalAmount);

    //If net expenses are exceeding the partial total amount, only the partial total amount can be paid
    if (this.invoice.ExpensesTotalAmount > this.PartialTotalAmount) {
      this.invoice.PayOutTotal = this.PartialTotalAmount;
    }    
  }
  
  checkCustomServiceFee(subInvoice: IInvoice) {
    //Check if salary reciever has custom service fee, only if invoice is not paid out
    if (subInvoice.UserSettings.HasCustomServiceFee == true && subInvoice.PayoutDate == null) {

      //If custom service fee, reset total service fee
      subInvoice.FeesTotalAmount = + subInvoice.ServiceFee;

      //Calculate new service fee
      subInvoice.ServiceFee = Math.round(subInvoice.PaidTotal * subInvoice.UserSettings.CustomServiceFee);

      var customServicePercent = this.invoice.UserSettings.CustomServiceFee * 100;

      this.ServiceFeeInfo = "Avtalad serviceavgift: " + customServicePercent + " %";
    }
  }

  setAmountsForCommissionCashInvoice(subInvoice: IInvoice) {
    subInvoice.PartialTotalAmount = Math.round(subInvoice.PaidTotal - subInvoice.TotalVatAmount);

    subInvoice.ExpensesTotalAmount = 0;
    subInvoice.PartialAmountExclNetExpenses = subInvoice.PartialTotalAmount;
    subInvoice.TotalPayout = subInvoice.PartialTotalAmount;
  }

  calculatePartialTotalAmount(subInvoice: IInvoice) {
    subInvoice.PartialTotalAmount = Math.round(subInvoice.PaidTotal - subInvoice.TotalVatAmount - subInvoice.FeesTotalAmount + subInvoice.ManualDiscount);
  }

  calculatePensionSavingsAmount(subInvoice: IInvoice) {
    //Total pension savings amount
    subInvoice.PensionSavingsTaxAmount = Math.round(subInvoice.PensionSavingsAmount * this.PensionSavingsTaxFactor);
    subInvoice.PensionSavingsInclTax = Math.round(subInvoice.PensionSavingsAmount + subInvoice.PensionSavingsTaxAmount);
  }

  calculateAllowancesTotalAmount(subInvoice: IInvoice) {

    var allowancesTotalAmount = 0;

    //Calculate allowances total amount
    for (var i = 0; i < subInvoice.Allowances.length; i++) {
      //Calculates total mileage amount
      if (subInvoice.Allowances[i].AllowanceType.Id == '1') {
        this.milageTotalAmount += Math.round(subInvoice.Allowances[i].Sum);
      }
      //Calculates total daily travel allowance amount
      else {
        allowancesTotalAmount += Math.round(subInvoice.Allowances[i].Sum);
        subInvoice.AllowancesTotalAmount = allowancesTotalAmount;
      }
    }
  }

  calculateExpensesTotalAmount(subInvoice: IInvoice) {
    subInvoice.ExpensesTotalAmount = Math.round(subInvoice.DeductionsTotalAmount + subInvoice.MileageTotalAmount + subInvoice.AllowancesTotalAmount);
  }

  calculatePartialAmountExclNetExpenses(subInvoice: IInvoice) {
    //Amount from which employer tax is deducted
    subInvoice.PartialAmountExclNetExpenses = Math.round(subInvoice.PartialTotalAmount - subInvoice.ExpensesTotalAmount - subInvoice.PensionSavingsInclTax);
    if (subInvoice.PartialAmountExclNetExpenses < 0) {
      subInvoice.PartialAmountExclNetExpenses = 0;
    }

  }

  calculateGrossSalary(subInvoice: IInvoice) {
    subInvoice.UserSettings.EmployerTax.Tax = subInvoice.EmployerTaxType.Tax; 
    subInvoice.BruttoLon = Math.round(subInvoice.PartialAmountExclNetExpenses / (1 + subInvoice.UserSettings.EmployerTax.Tax));
  }

  calculateEmployerTaxAmount(subInvoice: IInvoice) {
    //Calculates employer tax amount
    subInvoice.ArbetsgivarAvgift = Math.round(subInvoice.PartialAmountExclNetExpenses - subInvoice.BruttoLon);
  }

  calculateMunicipalityTaxAmount(subInvoice: IInvoice) {
    //How much municipality tax is paid in SEK
    subInvoice.PayoutMunicipalityTax = Math.round(subInvoice.BruttoLon * subInvoice.UserSettings.Municipality.Tax);
  }

  calculateNetSalary(subInvoice: IInvoice) {
    //Calculates net salary
    subInvoice.NettoLon = Math.round(subInvoice.BruttoLon - subInvoice.PayoutMunicipalityTax);
  }

  calculatePayout(subInvoice: IInvoice) {debugger

    //Rounds payout up to closes int
    subInvoice.TotalPayout = Math.round(subInvoice.PayOutTotal);

    //Calculates the total payout amount
    subInvoice.PayOutTotal = Math.round(subInvoice.NettoLon + subInvoice.ExpensesTotalAmount);

    //If net expenses are exceeding the partial total amount, only the partial total amount can be paid
    if (subInvoice.ExpensesTotalAmount > subInvoice.PartialTotalAmount) {
      subInvoice.PayOutTotal = subInvoice.PartialTotalAmount;
    }
  }

  calculateAttachmentOfSalaryPayment(subInvoice: IInvoice, attachment: IAttachmentOfSalary) {

    //if (subInvoice.AttachmentOfSalary.AttachmentAmount > 0) {
    //  this.calculateAttachmentOfSalaryPayment(subInvoice, subInvoice.AttachmentOfSalary)
    //}
  }

  calculateCommissionAmount(subInvoice: IInvoice) {
    //PartialAmountExclNetExpenses x invoice?.Commission = invoice?.Commission * PartialAmountExclNetExpenses

    subInvoice.CommissionAmount = subInvoice.PartialAmountExclNetExpenses * this.invoice.Commission;
  }

  // Refaktorisera till mindre metoder med enkla namn som beskriver funktionen
  // Ta bort updateSubInvoiceAmounts helt ur koden, anropa metoderna i lösningen när data laddas
    
  updateSubInvoiceAmounts(subInvoice: IInvoice) {
    
    
    subInvoice.ZeroTaxChecked = false;
    var municipalityTaxRounded = 0;
    var checkZeroTax = false;

    if (subInvoice.ServiceFeeShare == null || (subInvoice.ServiceFeeShare != null && subInvoice.ServiceFeeShare.Percentage == 0)) {
      this.checkCustomServiceFee(subInvoice);
    }
    else if (subInvoice.ServiceFeeShare != null && subInvoice.ServiceFeeShare.Percentage > 0) {
      var sharedServicePercent = subInvoice.ServiceFeeShare.Percentage;
      var parentServicePercent = subInvoice.ServiceFeeShare.InvoiceSenderServiceFeeFactor * 100;

      this.ServiceFeeInfo = ", andel: " + sharedServicePercent + " % av moderfakturans ordinarie serviceavgift: " + parentServicePercent + " %";
    }
    else {
      this.ServiceFeeInfo = "";
    }

       
    //Partial total amount from which payout is calculated
    //For commission payouts
    if (subInvoice.Type != null && subInvoice.Type.TechnicalName == "CommissionCashInvoice") {
      this.setAmountsForCommissionCashInvoice(subInvoice);
    }
    // For regular invoices
    else {
      
      this.calculatePartialTotalAmount(subInvoice);
      
      if (subInvoice.PartialTotalAmount < 1000)
      {
        checkZeroTax = true;
      }
      
      //this.loadUserEmployerTax(subInvoice, subInvoice.UserSettings.Id, checkZeroTax);
      //this.loadUserMunicipalityTax(subInvoice, subInvoice.UserSettings.Id, checkZeroTax);

      const userEmployerTax$ = this.userService.getUserEmployerTax(subInvoice.UserSettings.Id, checkZeroTax);
      const userMunicipalityTax$ = this.userService.getUserMunicipalityTax(subInvoice.PayoutDate, subInvoice.UserSettings.Id, checkZeroTax);

      forkJoin([userEmployerTax$, userMunicipalityTax$]).subscribe(
        ([employerTax, municipalityTax]) => {
          // Both observables have completed, so we can proceed with the calculations
          subInvoice.EmployerTaxType = employerTax;
          subInvoice.UserSettings.EmployerTax.Tax = employerTax;

          subInvoice.UserSettings.Municipality.Tax = municipalityTax;

          // Call the remaining methods
          this.calculatePensionSavingsAmount(subInvoice);
          this.calculateAllowancesTotalAmount(subInvoice);
          this.calculateAllowancesTotalAmount(subInvoice);
          this.calculateExpensesTotalAmount(subInvoice);
          this.calculatePartialAmountExclNetExpenses(subInvoice);
          this.calculateGrossSalary(subInvoice);
          this.calculateEmployerTaxAmount(subInvoice);
          this.calculateMunicipalityTaxAmount(subInvoice);
          this.calculateNetSalary(subInvoice);
          //this.calculateAttachmentOfSalaryPayment(subInvoice);
          this.calculatePayout(subInvoice);
        },
        error => console.log(error)
      );
    }

    return subInvoice;

  }

  //updateSubInvoiceAmountsREMOVE(subInvoice: IInvoice) {

  //  var municipalityTaxRounded: any;
  //  var allowancesTotalAmount = 0;

  //  //Check if salary reciever has custom service fee, only if invoice is not paid out
  //  if (subInvoice.UserSettings.HasCustomServiceFee == true && subInvoice.PayoutDate == null) {

  //    //If custom service fee, reset total service fee
  //    subInvoice.FeesTotalAmount = + subInvoice.ServiceFee;

  //    //Calculate new service fee
  //    subInvoice.ServiceFee = Math.round(subInvoice.PaidTotal * subInvoice.UserSettings.CustomServiceFee);

  //    console.log("ServiceFee");
  //    console.log(subInvoice.ServiceFee);
  //    console.log(subInvoice.TotalInvoiceAmount);
  //  }

  //  //Partial total amount from which payout is calculated
  //  //For commission payouts
  //  if (subInvoice.Type != null && subInvoice.Type.TechnicalName == "CommissionCashInvoice") {
  //    subInvoice.PartialTotalAmount = Math.round(subInvoice.PaidTotal - subInvoice.TotalVatAmount);

  //    municipalityTaxRounded = 0;
  //    subInvoice.ExpensesTotalAmount = 0;
  //    subInvoice.PartialAmountExclNetExpenses = subInvoice.PartialTotalAmount;
  //    subInvoice.TotalPayout = subInvoice.PartialTotalAmount;
  //  }
  //  // For regular invoices
  //  else {
  //    subInvoice.PartialTotalAmount = Math.round(subInvoice.PaidTotal - subInvoice.TotalVatAmount - subInvoice.FeesTotalAmount + subInvoice.ManualDiscount);

  //    //if (subInvoice.ZeroTaxChecked == false) {
  //    //  if (this.PartialTotalAmount < 1000) {
  //    //    this.loadUserEmployerTax(subInvoice, subInvoice.UserSettings.Id, true);
  //    //    this.loadUserMunicipalityTax(subInvoice, subInvoice.UserSettings.Id, true);
  //    //  }
  //    //  else {
  //    //    this.loadUserEmployerTax(subInvoice, subInvoice.UserSettings.Id, false);
  //    //    this.loadUserMunicipalityTax(subInvoice, subInvoice.UserSettings.Id, false);
  //    //  }

  //    //  subInvoice.ZeroTaxChecked = true;
  //    //}

  //    //Total pension savings amount
  //    subInvoice.PensionSavingsTaxAmount = Math.round(subInvoice.PensionSavingsAmount * this.PensionSavingsTaxFactor);
  //    subInvoice.PensionSavingsInclTax = Math.round(subInvoice.PensionSavingsAmount + subInvoice.PensionSavingsTaxAmount);

  //    //Makes sure municipality tax always is calculated with 2 decimals, even if 3 in db table
  //    // Use temporary tax if present instead of municipality standard tax
  //    if (subInvoice.UserSettings.TaxAdjusted >= 0) {
  //      municipalityTaxRounded = parseFloat(subInvoice.UserSettings.TaxAdjusted.toFixed(4));
  //    }
  //    else {
  //      municipalityTaxRounded = parseFloat(subInvoice.UserSettings.Municipality.Tax.toFixed(4));
  //    }

  //    //Calculate allowances total amount
  //    for (var i = 0; i < subInvoice.Allowances.length; i++) {
  //      //Calculates total mileage amount
  //      if (subInvoice.Allowances[i].AllowanceType.Id == '1') {
  //        this.milageTotalAmount += Math.round(subInvoice.Allowances[i].Sum);
  //      }
  //      //Calculates total daily travel allowance amount
  //      else {
  //        allowancesTotalAmount += Math.round(subInvoice.Allowances[i].Sum);
  //        subInvoice.AllowancesTotalAmount = allowancesTotalAmount;
  //      }
  //    }

  //    //Total amount of expenses
  //    subInvoice.ExpensesTotalAmount = Math.round(subInvoice.DeductionsTotalAmount + subInvoice.MileageTotalAmount + subInvoice.AllowancesTotalAmount);

  //    //Amount from which employer tax is deducted
  //    subInvoice.PartialAmountExclNetExpenses = Math.round(subInvoice.PartialTotalAmount - subInvoice.ExpensesTotalAmount - subInvoice.PensionSavingsInclTax);
  //    if (subInvoice.PartialAmountExclNetExpenses < 0) {
  //      subInvoice.PartialAmountExclNetExpenses = 0;
  //    }

  //    //Calculates gross salary 
  //    subInvoice.BruttoLon = Math.round(subInvoice.PartialAmountExclNetExpenses / (1 + subInvoice.UserSettings.EmployerTax.Tax));

  //    //Calculates employer tax
  //    subInvoice.ArbetsgivarAvgift = Math.round(subInvoice.PartialAmountExclNetExpenses - subInvoice.BruttoLon);

  //    //How much municipality tax is paid in SEK
  //    subInvoice.PayoutMunicipalityTax = Math.round(subInvoice.BruttoLon * municipalityTaxRounded);

  //    //Calculates net salary
  //    subInvoice.NettoLon = Math.round(subInvoice.BruttoLon - subInvoice.PayoutMunicipalityTax);

  //    //Rounds payout up to closes int
  //    subInvoice.TotalPayout = Math.round(subInvoice.PayOutTotal);

  //    //Calculates the total payout amount
  //    subInvoice.PayOutTotal = Math.round(subInvoice.NettoLon + subInvoice.ExpensesTotalAmount);

  //    //If net expenses are exceeding the partial total amount, only the partial total amount can be paid
  //    if (subInvoice.ExpensesTotalAmount > subInvoice.PartialTotalAmount) {
  //      subInvoice.PayOutTotal = subInvoice.PartialTotalAmount;
  //    }

  //    if (subInvoice.AttachmentOfSalary.AttachmentAmount > 0) {
  //      this.calculateAttachmentOfSalaryPayment(subInvoice, subInvoice.AttachmentOfSalary)
  //    }
  //  }

  //  return subInvoice;

  //}

  //calculatePartialAmountExclNetExpenses(subInvoice: IInvoice) {
  //  this.updateSubInvoiceAmounts(subInvoice);
    
  //  return subInvoice.PartialAmountExclNetExpenses;
  //}

  calculatePaidTotal(subInvoice: IInvoice) {
    this.updateSubInvoiceAmounts(subInvoice);

    return subInvoice.PaidTotal;
  }

  setInvoiceFee() {
      if (this.invoice.FeePaidByReciever) {
          this.InvoiceFeeReceiver = this.invoice.InvoiceFee;
          this.InvoiceFeeMember = 0;
      }
      else {
          this.InvoiceFeeMember = this.invoice.InvoiceFee;
          this.InvoiceFeeReceiver = 0;
      }
  }

  save(invoice: any) {
    
     this.isLoading = true;
        this.invoiceService.updateInvoiceSummary(invoice)
            .subscribe(
                response => this.onSaved(response),
                error => console.log(error));
  }

  onSaved(response: any) {
    //Reload the view when page is saved
    this.route.params.subscribe(params => {
      this.id = params['id'] ? params['id'] : 0;
      this.loadInvoice(this.id);
    });
        
  }


  updatePayoutDate(id: number, date: Date) {
    //this.isLoading = true;
    this.invoiceService.updatePayoutDate(id,date)
      .subscribe(
        response => this.onPayoutDateSaved(response),
        error => console.log(error));
  }

  onPayoutDateSaved(response: any) {
    //this.isLoading = false;
  }

  getCompanies() {
      this.companyService.getMyCompanies()
          .subscribe(value => this.onGetCompaniesFinished(value),
              error => this.onGetCompaniesError(error)
          );
  }

  onGetCompaniesError(error: any) {
  }

  onGetCompaniesFinished(companies: any) {
      this.companies = companies;
  }
    
  getCustomer(id) {
      for (var i = 0; i < this.companies.length; i++) {
          if (this.companies[i].Id == this.invoice.Customer.Id) {
              //console.log(this.companies[i]);
              return JSON.parse(JSON.stringify(this.companies[i]));
          }
      }
  }


  openLink(link: string) {
      window.open(link, '_blank');
  }

  employerTaxUpdated(taxId: number) {
      for (var i = 0; i < this.employerTaxes.length; i++) {
          if (this.employerTaxes[i].Id == taxId) {
              this.invoice.EmployerTaxType.Tax = this.employerTaxes[i].Tax;
          }
      }

      this.updateAmounts();
}

  toFormattedDate(iso: string) {
    const date = new Date(iso);
    var d = `${date.getFullYear()}-${(date.getMonth() + 1).toString().length == 1 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1}-${date.getDate().toString().length == 1 ? ("0" + date.getDate().toString()) : date.getDate().toString()}`;
    if (d == '1970-01-01')
      return undefined;
    return d;
  }


    config = {
        disableClose: false,
        panelClass: 'custom-overlay-pane-class',
        hasBackdrop: true,
        backdropClass: '',
        width: '500px',
        height: '',
        position: {
            top: '',
            bottom: '',
            left: '',
            right: ''
        },
        data: {
            invoiceItemGroup: {},
            customerTemplate: {},
            customer: {},
            contact: {},
            companyId: 0
        },
        dataTemplate: {
            "Id": 0,
            "Type": {},
            "HoursWorked": undefined,
            "StartDate": undefined,
            "EndDate": undefined,
            "Comment": undefined,
            "InvoiceId": 0
        }
    };
}
