import { Component, OnInit } from '@angular/core';
import { environment as env } from '@env/environment';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, MatCheckbox } from '@angular/material';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ICompany } from '../../core/interfaces/ICompany';
import { IContact } from '../../core/interfaces/IContact';
import { IUser } from '../../core/interfaces/user/IUser';
import { IInvoice } from '../../core/interfaces/IInvoice';
import { CompanyService } from '../../core/services/CompanyService';
import { InvoiceService } from '../../core/services/InvoiceService';
import { CurrencyService } from '../../core/services/CurrencyService';
import { ReportService } from '../../core/services/ReportService';
import { IEmployerTax } from '../../core/interfaces/IEmployerTax';
import { UserService } from '../../core/services/UserService';
import { EmployerTaxService } from '../../core/services/EmployerTaxService';
import { debug } from '../../core/meta-reducers/debug.reducer';
import { IAllowance } from '../../core/interfaces/invoice';
import { ITransactionLog } from '../../core/interfaces/invoice/ITransactionLog';

@Component({
    selector: 'invoice-summary',
    templateUrl: './invoice-summary.component.html',
    styleUrls: ['./invoice-summary.component.scss']
})

export class InvoiceSummaryComponent implements OnInit {
    routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
    versions = env.versions;
    id: number;
    companies = [];
    currencies = [];
    employerTaxes = [];
    //hoursWorkedTotal: number = 0;
  totalInvoiceAmount: number = 0;
  ref: IContact = { Id: 0, Address: "", City: "", Email: "", Name: "", NameLocked: false,  Phone: "", Zip: "", IDNumber: "" };
    
  invoice: IInvoice;

  invoiceQuickGroupTemplate = { Vat: "25", Type: { Value: "4", Name: "Övrigt" }, rowTypeSelected: "4" };
  invoiceQuickGroup = JSON.parse(JSON.stringify(this.invoiceQuickGroupTemplate));
  vatList = [];
  invoiceShareTypes = [];
  addToInvoiceList = [{ Name: 'Ja', Value: true }, { Name: 'Nej', Value: false }];
  paymentTerms = [10, 15, 20, 25, 30];
  isLoading = true;
  feePaidByReciever: boolean = false;
  feePaidBySender: boolean = false;
  milageTotalAmount = 0;
  allowancesTotalAmount = 0;
  payoutEmployerTax = null;
  PayoutMunicipalityTax = 0;
  TotalPayout: number;
  DeductionsTotalAmount: number;
  ExpensesTotalAmount: number;
  TotalInvoiceAmount: number;
  EmployerTax = 0
  PartialAmountExclNetExpenses: number;
  PensionSavingsInclTax: number;
  userEmployerTax: any;
  InvoiceFeeReceiver = 0;
  InvoiceFeeMember = 0;
  PersonalNumber: any;
  InvoiceFeePaidByCustomer = true;
  municipalityTaxRounded: any;
  PartialTotalAmount: number;
  ServiceFee: 0;
  pensionSavingsTaxFactor = 0.2426;
  pensionSavingsTaxAmount = 0;
  attachmentPaymentToKronofogdenAmount = 0;
  serviceFeeFactor: number = 0;
  zeroTaxChecked: boolean = false;
  ServiceFeeInfo: string = "";

  constructor(
      private router: Router,
      private route: ActivatedRoute,
      public dialog: MatDialog,
      private companyService: CompanyService,
      private invoiceService: InvoiceService,
      private currencyService: CurrencyService,
      private userService: UserService,
      private employerTaxService: EmployerTaxService,
      private reportService: ReportService
  ) {

  }
    
  ngOnInit() {
    
    this.route.params.subscribe(params => {
      this.id = params['id'] ? params['id'] : 0;
      this.loadInvoice(this.id);
    });
        
    this.getCompanies();
    this.loadCurrencies();
    this.loadEmployerTaxes();
    this.vatList.push({ Value: "25" }, { Value: "12" }, { Value: "6" }, { Value: "0" })
  }

  changeValue(value) {
    this.InvoiceFeePaidByCustomer = value.checked;
    this.invoice.InvoiceFeePaidByCustomer = this.InvoiceFeePaidByCustomer;
  }

  sendMessage(message: string) {
      this.invoiceService.sendMessage(message, this.id)
          .subscribe(
              response => this.messageSent(response),
              error => console.log(error));
  }

  messageSent(message: any) {
      this.invoice.Message = undefined;
      this.invoice.Messages.push(message);
  }

  currencyChanged() {
      for (var i = 0; i < this.currencies.length; i++) {
          var curr = this.currencies[i];
          if (curr.Id == this.invoice.Currency.Id) {
              this.invoice.Currency.Name = curr.Name;
          }
      }
  }

  loadInvoice(id: number) {
      this.invoiceService.getInvoiceShareTypes()
          .subscribe(
              response => this.invoiceShareTypes = response,
              error => console.log(error));
        
      this.invoiceService.getInvoice(id)
          .subscribe(
              response => this.onGetInvoiceCompleted(response),
            error => console.log(error));
  }

  loadCurrencies() {
      this.currencyService.getCurrencies()
          .subscribe(
              response => this.currencies = response,
              error => console.log(error));
  }

  loadEmployerTaxes() {
      this.employerTaxService.getEmployerTaxes()
          .subscribe(
              response => this.employerTaxes = response,
              error => console.log(error));
  }

  onGetInvoiceCompleted(invoice: any) {

    this.invoice = invoice;
    this.setInvoiceFee();
    this.loadUserEmployerTax();
    //this.loadMonthlyAttachmentOfSalaryReport();
    this.updateAmounts();
    this.isLoading = false;
    this.DeductionsTotalAmount = 0;
    this.allowancesTotalAmount = 0;
    this.milageTotalAmount = 0;
    this.loadGlobalServiceFee();

    //Calculate deductions total amount 
    for (var i = 0; i < this.invoice.Deductions.length; i++) {
      this.DeductionsTotalAmount += Math.round(this.invoice.Deductions[i].Amount);
    }

    //Calculate allowances total amount
    for (var i = 0; i < this.invoice.Allowances.length; i++) {
      //Calculates total mileage amount
      if (this.invoice.Allowances[i].AllowanceType.Id == '1') {
        this.milageTotalAmount += Math.round(this.invoice.Allowances[i].Sum);
      }
      //Calculates total daily travel allowance amount
      else {
        this.allowancesTotalAmount += Math.round(this.invoice.Allowances[i].Sum);
      }
    }

    //console.log("User");
    //console.log(this.invoice.UserSettings);
  }

  loadUserEmployerTax(checkZeroTax: boolean = false)
  {
    this.userService.getUserEmployerTax(this.invoice.UserSettings.Id, checkZeroTax)
      .subscribe(
        tax => this.employerTaxLoaded(tax),
        error => console.log(error));
  }

  employerTaxLoaded(tax: any) {
    this.invoice.EmployerTaxType = tax;

    this.updateAmounts();
  }

  loadUserMunicipalityTax(checkZeroTax: boolean = false) {
    this.userService.getUserMunicipalityTax(this.invoice.PayoutDate, this.invoice.UserSettings.Id, checkZeroTax)
      .subscribe(
        tax => this.municipalityTaxLoaded(tax),
        error => console.log(error));
  }

  municipalityTaxLoaded(tax: any) {
    this.invoice.UserSettings.Municipality.Tax = tax;
    
    this.updateAmounts();
  }

  //loadMonthlyAttachmentOfSalaryReport() {
  //  this.reportService.getMonthlyAttachmentOfSalaryReport(this.invoice.UserSettings.Guid, this.invoice.Id, this.invoice.PayoutDate)
  //    .subscribe(
  //      monthlyAttachmentReport => this.monthlyAttachmentOfSalaryReportLoaded(monthlyAttachmentReport),
  //      error => console.log(error));
  //}

  //monthlyAttachmentOfSalaryReportLoaded(monthlyAttachmentReport: any) {
  //  this.invoice.EmployerTaxType = monthlyAttachmentReport;

  //  console.log("Att rep")
  //  console.log(monthlyAttachmentReport);
  //  //this.updateAmounts();
  //}

  updateAmounts() {
    this.invoice.BruttoLon = 0;
    this.invoice.NettoLon = 0;
    this.payoutEmployerTax = 0;

    this.getServiceFee();

   
    //Add all fees 
    this.invoice.FeesTotalAmount = this.InvoiceFeeMember + this.InvoiceFeeReceiver + this.invoice.ExchangeFee + this.invoice.KronofogdenFee + this.invoice.ReminderFee + Math.round(this.invoice.ServiceFee) + this.invoice.InkassoFee + this.invoice.CustomFee;
    
    //Partial total amount from which payout is calculated
    this.PartialTotalAmount = Math.round(this.invoice.PaidTotal - this.invoice.TotalVatAmount - this.invoice.FeesTotalAmount + this.invoice.ManualDiscount);
    
    if (this.zeroTaxChecked == false) {
      if (this.PartialTotalAmount < 1000) {
        this.loadUserEmployerTax(true);
        this.loadUserMunicipalityTax(true);
      }
      else {
        this.loadUserEmployerTax(false);
        this.loadUserMunicipalityTax(false);
      }

      this.zeroTaxChecked = true;
    }

    //Makes sure municiplaity tax always is calculated with 2 decimals, even if 3 in db table
    // Use temporary tax if present instead of municipality standard tax
        
    if (this.invoice.Type != null && this.invoice.Type.TechnicalName == "CommissionCashInvoice") {
      this.municipalityTaxRounded = 0;
    }
    else if (this.invoice.UserSettings.TaxAdjustments != null && this.invoice.UserSettings.TaxAdjustments.length > 0) {
        this.municipalityTaxRounded = parseFloat(this.invoice.UserSettings.TaxAdjustments[0].TemporaryTax.toFixed(4));
    }
    else
    {
        this.municipalityTaxRounded = parseFloat(this.invoice.UserSettings.Municipality.Tax.toFixed(4));
    }

    //Total amount of expenses
    this.invoice.ExpensesTotalAmount = (this.DeductionsTotalAmount + this.milageTotalAmount + this.allowancesTotalAmount);

    //Total pension savings amount
    this.pensionSavingsTaxAmount = (this.invoice.PensionSavingsAmount * this.pensionSavingsTaxFactor);
    this.PensionSavingsInclTax = (this.invoice.PensionSavingsAmount + this.pensionSavingsTaxAmount);
      
    //Amount from which employer tax is deducted
    this.PartialAmountExclNetExpenses = (this.PartialTotalAmount - this.invoice.ExpensesTotalAmount - this.PensionSavingsInclTax);
    if (this.PartialAmountExclNetExpenses < 0) {
      this.PartialAmountExclNetExpenses = 0;
    }
    
    //Calculates gross salary 
    this.invoice.BruttoLon = this.PartialAmountExclNetExpenses / (1 + this.invoice.EmployerTaxType.Tax);

    //Calculates employer tax
    this.invoice.ArbetsgivarAvgift = (this.PartialAmountExclNetExpenses - this.invoice.BruttoLon);
      
    //How much municipality tax is paid in SEK
    this.PayoutMunicipalityTax = (this.invoice.BruttoLon * this.municipalityTaxRounded);
    
    //Calculates net salary
    this.invoice.NettoLon = (this.invoice.BruttoLon - this.PayoutMunicipalityTax);

    //Rounds payout up to closes int
    //this.TotalPayout = Math.round(this.invoice.PayOutTotal);
    this.TotalPayout = this.invoice.PayOutTotal;

    this.TotalInvoiceAmount = this.invoice.TotalInvoiceAmount;

    //Calculates the total payout amount
    this.invoice.PayOutTotal = Math.round(this.invoice.NettoLon + this.invoice.ExpensesTotalAmount);

    //If net expenses are exceeding the partial total amount, only the partial total amount can be paid
    if (this.invoice.ExpensesTotalAmount > this.PartialTotalAmount) {
    this.invoice.PayOutTotal = this.PartialTotalAmount;
    }

    this.invoice.BruttoLon = Math.round(this.invoice.BruttoLon);
    this.invoice.NettoLon = Math.round(this.invoice.NettoLon);
    this.PayoutMunicipalityTax = Math.round(this.PayoutMunicipalityTax);
    this.PartialAmountExclNetExpenses = Math.round(this.PartialAmountExclNetExpenses);
    this.PensionSavingsInclTax = Math.round(this.PensionSavingsInclTax);
    this.invoice.ArbetsgivarAvgift = Math.round(this.invoice.ArbetsgivarAvgift);
    this.invoice.PensionSavingsAmount = Math.round(this.invoice.PensionSavingsAmount);
    this.pensionSavingsTaxAmount = Math.round(this.pensionSavingsTaxAmount);

    if (this.invoice.ExchangeRateInvoiceSent > 0 && this.invoice.ExchangeRateInvoicePaid == 0) {
      this.updateExchangeRatePaid()
    }
  }

  updateExchangeRatePaid() {
    this.invoice.ExchangeRateInvoicePaid = (this.invoice.PaidTotal / this.invoice.TotalInvoiceAmountIncVat).toFixed(6);    
  }


  setInvoiceFee() {
      if (this.invoice.FeePaidByReciever) {
          this.InvoiceFeeReceiver = this.invoice.InvoiceFee;
          this.InvoiceFeeMember = 0;
      }
      else {
          this.InvoiceFeeMember = this.invoice.InvoiceFee;
          this.InvoiceFeeReceiver = 0;
      }
  }

  save(invoice: any, sendEmail: boolean) {

  this.isLoading = true;
          this.invoiceService.updateInvoiceSummary(invoice, sendEmail)
          .subscribe(
              response => this.onSaved(response),
              error => console.log(error));
  }

  onSaved(response: any) {
  //Reload the view when page is saved
  this.route.params.subscribe(params => {
    this.id = params['id'] ? params['id'] : 0;
    this.loadInvoice(this.id);
  });
        
  }

  getCompanies() {
      this.companyService.getMyCompanies()
          .subscribe(value => this.onGetCompaniesFinished(value),
              error => this.onGetCompaniesError(error)
          );
  }

  onGetCompaniesFinished(companies: any) {
    this.companies = companies;
  }

  onGetCompaniesError(error: any) {
  }

  getServiceFee() {

    this.loadGlobalServiceFee();
    
    if (!this.invoice.ServiceFeeUnlocked) {     
      //If invoice is not paid, and member has custom service fee, use custom service fee, unless shared service fee
      if (this.invoice.ServiceFeeShare.Percentage != null && this.invoice.ServiceFeeShare.Percentage == 0) {

        if (this.invoice.UserSettings.HasCustomServiceFee && !this.invoice.ExpressPayOut && this.invoice.PayoutDate == null) {
          this.serviceFeeFactor = this.invoice.UserSettings.CustomServiceFee;
          this.invoice.ServiceFee = Math.round(this.serviceFeeFactor * this.invoice.TotalInvoiceAmount);

          var customServicePercent = this.invoice.UserSettings.CustomServiceFee * 100;

          this.ServiceFeeInfo = "Avtalad serviceavgift: " + customServicePercent + " %";
        }
      }
      else if (this.invoice.ServiceFeeShare.Percentage != null && this.invoice.ServiceFeeShare.Percentage > 0) {
        var sharedServicePercent = this.invoice.ServiceFeeShare.Percentage;
        var parentServicePercent = this.invoice.ServiceFeeShare.InvoiceSenderServiceFeeFactor * 100;

        this.ServiceFeeInfo = "Andel: " + sharedServicePercent + " % av moderfakturans ordinarie serviceavgift: " + parentServicePercent + " %";
      }
      else {
        this.ServiceFeeInfo = "";
      }
    }    
  }

  loadGlobalServiceFee() {
    var express = this.invoice.ExpressPayOut;

    this.invoiceService.getGlobalServiceFee(express)
      .subscribe(value => this.onGetGlobalServiceFeeFinished(value),
        error => this.onGetGlobalServiceFeeFinished(error)
      );    
  }

  onGetGlobalServiceFeeError(error: any) {
    
  }

  onGetGlobalServiceFeeFinished(serviceFeeFactor: any) {
      this.serviceFeeFactor = serviceFeeFactor;
  }
    
  getCustomer(id) {
      for (var i = 0; i < this.companies.length; i++) {
          if (this.companies[i].Id == this.invoice.Customer.Id) {
              //console.log(this.companies[i]);
              return JSON.parse(JSON.stringify(this.companies[i]));
          }
      }
  }


  openLink(link: string) {
      window.open(link, '_blank');
  }

  employerTaxUpdated(taxId: number) {
      for (var i = 0; i < this.employerTaxes.length; i++) {
          if (this.employerTaxes[i].Id == taxId) {
              this.invoice.EmployerTaxType.Tax = this.employerTaxes[i].Tax;
          }
      }

      this.updateAmounts();
  }

  toFormattedDate(iso: string) {
    const date = new Date(iso);
    var d = `${date.getFullYear()}-${(date.getMonth() + 1).toString().length == 1 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1}-${date.getDate().toString().length == 1 ? ("0" + date.getDate().toString()) : date.getDate().toString()}`;
    if (d == '1970-01-01')
      return undefined;
    return d;
  }


    config = {
        disableClose: false,
        panelClass: 'custom-overlay-pane-class',
        hasBackdrop: true,
        backdropClass: '',
        width: '500px',
        height: '',
        position: {
            top: '',
            bottom: '',
            left: '',
            right: ''
        },
        data: {
            invoiceItemGroup: {},
            customerTemplate: {},
            customer: {},
            contact: {},
            companyId: 0
        },
        dataTemplate: {
            "Id": 0,
            "Type": {},
            "HoursWorked": undefined,
            "StartDate": undefined,
            "EndDate": undefined,
            "Comment": undefined,
            "InvoiceId": 0
        }
    };
}
