import { Component, OnInit, ViewChild } from '@angular/core';
import { environment as env } from '@env/environment';
import { ROUTE_ANIMATIONS_ELEMENTS } from '@app/core';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, MatRadioModule, MatRadioButton } from '@angular/material';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ICompany } from '../../core/interfaces/ICompany';
import { IContact } from '../../core/interfaces/IContact';
import { IInvoice } from '../../core/interfaces/IInvoice';
import { IFile } from '../../core/interfaces/IFile';
import { IDeduction } from '../../core/interfaces/invoice/IDeduction';
import { IAllowance } from '../../core/interfaces/invoice/IAllowance';
import { EditCustomerDialogComponent } from '../../shared/customer/EditCustomerDialog.Component';
import { EditDeductionDialogComponent } from '../../static/invoice-tool/deduction/edit-deduction-Dialog.Component';
import { EditAllowanceDialogComponent } from '../../static/invoice-tool/allowance/edit-allowance-Dialog.Component';
import { EditContactDialogComponent } from '../../shared/contact/EditContactDialog.Component';
import { LoginDialogComponent } from '../../shared/login-dialog/LoginDialog.Component';
import { ModalSpinnerDialogComponent } from '../../shared/modal-spinner/ModalSpinnerDialog.Component';
import { InvoiceToolCalendarDialogComponent } from './invoice-tool.calendarDialog.Component';
import { InvoicePreviewDialogComponent } from './invoice-tool.invoicePreviewDialog.Component'
import { CompanyService } from '../../core/services/CompanyService';
import { InvoiceService } from '../../core/services/InvoiceService';
import { AuthenticationService } from '../../core/services/AuthenticationService';
import { CurrencyService } from '../../core/services/CurrencyService';
import { UserService } from '../../core/services/UserService';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { IAccountSettings } from '../../core/interfaces/user/IAccountSettings';
import { IConfirmResponse } from '../../core/interfaces/confirm/IConfirmResponse';
import { IUser } from '../../core/interfaces/user/IUser'; 
import { AddMissingRecieverDialogComponent } from './addreciever/add-missing-reciever-Dialog.Component';
import { IFileType } from '../../core/interfaces/IFileType';
import { FormControl, Validators } from "@angular/forms";
import { LocalStorageService } from '../../core/local-storage/local-storage.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/ConfirmDialog';

@Component({
  selector: 'invoice-tool',
  templateUrl: './invoice-tool.component.html',
  styleUrls: ['./invoice-tool.component.scss']
})

export class InvoiceToolComponent implements OnInit {
  panelOpenStateSalaryShares = false;
  panelOpenStateAllowances = false;
  panelOpenStateDeductions = false;
  routeAnimationsElements = ROUTE_ANIMATIONS_ELEMENTS;
  versions = env.versions;
  id: number;
  companies = [];
  currencies = [];
  public searchAccountValue: string;
  hoursWorkedTotal: number = 0;
  TotalInvoiceAmount: number = 0;
  ref: IContact = { Id: 0, Address: "", City: "", Email: "", Name: "", NameLocked: false, Phone: "", Zip: "", IDNumber: "" };
  invoice = {
    Id: 0,
    Allowances: [],
    ReferenceText: null,
    InvoiceFee: 0,
    ExpressPayOut: false,
    RotDrivingFee: 3,
    RotDrivingFeeTotalAmount: 0,
    IsRot: false, IsRut: false,
    IsRotRut: false,
    RotProperty: null,
    RotDrivingFeeKm: 0,
    Marking: '',
    RecieverEmailCopy: '',
    CreditInvoiceFor: null,
    ExpressPayout: false,
    Status: { TechnicalName: 'Draft', Order: 0 },
    User: { Id: "0" },
    InvoiceGroups: [],
    Messages: [],
    Message: undefined,
    TravelExpenses: { Reciepts: [], TravelReportTotalAmount: 0, DriveJournalTotalAmount: 0 },
    PaymentTerm: "30",
    SalaryShares: [],
    selectedReference: "",
    ShowDeductions: false,
    ShowMileDeduction: false,
    TravelDistance: undefined,
    Deductions: [],
    invoiceItemGroup: [],
    Reference: this.ref,
    RecieverEmail: '',
    termsAgreed: false,
    Customer: { Id: 0, Active: true, Name: '', OrgNumber: '', AcceptsInvoiceFee: true, TimeReportMandatory: false, NameLocked: false, AcceptsInvoiceFeeLocked: false, Address: '', AddressLocked: false, Zip: '', ZipLocked: false, City: '', CityLocked: false, ElectronicReference: '', ElectronicReferenceMandatory: false, GlnNumber: null, GlnNumberLocked: false, Country: { Id: null, Name: null }, Reference: {}, InvoiceEmail: '', InvoiceEmailLocked: false, InvoiceSendType: 0, PaymentTerm: 0, PaymentTermLocked: false, InvoiceSendTypeLocked: false, Contacts: [], IsCompany: false, PublicInfo: "" },
    Currency: { Id: "1", Name: "SEK" },
    InvoiceSendMethodEmail: true,
    InvoiceSendMethodMail: false,
    InvoiceSendType: 1, action: '',
    RecieverCompanyName: '',
    RecieverAddress1: '',
    RecieverZip: '',
    RecieverCity: '',
    SelectedCurrency: 'SEK',
    SubInvoices: [],
    FeePaidByReciever: true,
    TotalInvoiceAmount: 0,
    SubInvoice: false,
    PensionSavingsAmount: 0,
    Documents: [],
    Type: { Name: '', TechnicalName: ''},
    UserSettings: {
      Id: 0,
      PensionSavingsSettings: {
        Id: 0,
        Amount: 0,
        Unit: {
          Id: 0,
          TechnicalName: ''
        }
      }
    },
    CustomerCopy: { Id: 0, Active: true, Name: '', OrgNumber: '', AcceptsInvoiceFee: true, TimeReportMandatory: false, NameLocked: false, AcceptsInvoiceFeeLocked: false, Address: '', AddressLocked: false, Zip: '', ZipLocked: false, City: '', CityLocked: false, ElectronicReference: '', ElectronicReferenceMandatory: false, GlnNumber: null, GlnNumberLocked: false, Country: { Id: null, Name: null }, Reference: {}, InvoiceEmail: '', InvoiceEmailLocked: false, InvoiceSendType: 0, PaymentTerm: 0, PaymentTermLocked: false, InvoiceSendTypeLocked: false, Contacts: [], IsCompany: false, PublicInfo: "" },
  };
  invoiceQuickGroupTemplate = {
    Description: '',
    Vat: "25",
    ReverseTax: false,
    VatNoSwe: "0",
    TimeReportStatus: { DisplayName: "Ingen tid", Id: "3", Name: "NoInfo" },
    PaymentReciever: { Id: 0, Name: null, PersonalNumber: null, OrgNumber: null, IsCompany: false, LastLogin: null, User: null },
    Type: { Value: "1", Name: "Arbetad tid" },
    rowTypeSelected: "1",
    InvoiceRows: []
  };
  invoiceQuickGroup = JSON.parse(JSON.stringify(this.invoiceQuickGroupTemplate));
  vatList = [];
  salaryShare = [];
  invoiceShareTypes = [];
  addToInvoiceList = [{ Name: 'Ja', Value: true }, { Name: 'Nej', Value: false }];
  paymentTerms = [10, 15, 20, 25, 30, 45, 60];
  sendTypes: any[] = [{ Id: 2, Name: "Post" }, { Id: 1, Name: "Epost (pdf)" }, { Id: 3, Name: "E-faktura (ingen pdf)" }];
  isLoading = true;
  isAdmin = false;
  feePaidByReciever: boolean = true;
  feePaidBySender: boolean = false;
  showValidationSummary: boolean = false;
  submitType: number = 0;
  invoiceSendTypeMail: boolean = false;
  invoiceSendTypeEmail: boolean = true;
  DueDate: number;
  today: Date;
  PaymentTermInt: any;
  //statusOrder = 0;
  //DiscountCode = "Corona2020";
  ReadOnlyCheckbox: boolean;
  resultMarking: number;
  resultDescription: number;
  txt: any;
  text: any;
  str: any;
  sumNetExpenses: number = 0;
  sumFees: number = 0;
  preliminaryPayout: number = 0;
  ExpressPayoutLocal: boolean = false;
  dialogSpinner: any = null;
  RotDrivingFee: number = 3;
  ErrorMessageTab: any;
  ErrorMessageDesc: string = '';
  ErrorMessageSend: any;
  errorMessagePension: any;
  pensionValidates: boolean = false;
  errorMessageSaveQuickRow: string = '';
  hasSubInvoices: boolean = false;
  sendInfo: boolean = false;
  personalOrOrgNumberValid: boolean = false;
  invoiceFeeLocked: boolean = false;
  recieverEmailLocked: boolean = false;
  invoiceSendTypeLocked: boolean = false;
  electronicReferenceMandatory: boolean = false;
  quickrowValid: boolean = false;
  sendInvoiceValidates: boolean = false;
  errorMessageFiles: string = '';
  totalFileSize: number = 0;
  attachedFilesCount: number = 0;
  pensionSavingsFactorPreset: number = 0;
  pensionSavingsAmountPreset: number = 0;
  pensionSavingsFactor: number = 0;
  pensionSavingsPercent: number = 0;
  pensionSavingsAmount: number = 0;
  user: IUser;
  CustomServiceFee = 0;
  Commission = 0;
  pensionSavingsTotalAmount = 0;
  pensionSavingsTaxFactor = 0.2426;
  IsCommissionInvoice = false;
  showExpressDialog: boolean = false;
  recieverEmailValid: boolean = false;
  recieverEmailCopyValid: boolean = false;
  ErrorMessageRecieverEmail: string = '';
  ErrorMessageRecieverCopyEmail: string = '';

  @ViewChild('rbExpressPayoutYes', { static: true }) rbExpressPayoutYes: MatRadioButton;
  @ViewChild('rbExpressPayoutNo', { static: true }) rbExpressPayoutNo: MatRadioButton;

  constructor(
    private router: Router,
    private _location: Location,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private companyService: CompanyService,
    private invoiceService: InvoiceService,
    private currencyService: CurrencyService,
    private loadingService: LoadingBarService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {

    this.openLoadingSpinner();
    this.loadingService.start();
    this.loadCurrencies();
    this.resultDescription = 0;
    this.resultMarking = 0;

    this.route.queryParams.subscribe(params => {
      this.showExpressDialog = params['showExpressDialog'] ? true : false;
    });

    this.route.params.subscribe(params => {
      this.id = params['id'] ? params['id'] : 0;
      this.loadInvoice(this.id);
    });

    this.isAdmin = this.userService.userHasRole("admin");
    this.PaymentTermInt = parseInt(this.invoice.PaymentTerm);
    this.today = new Date();
    this.DueDate = new Date().setDate(this.today.getDate() + this.PaymentTermInt);
    this.ReadOnlyCheckbox = true;
    
    if (this.isAdmin) {
      this.ReadOnlyCheckbox = false;
    }

    this.loadUser();
    this.updateTotalInvoiceAmount();
    this.sendValidates();    
  }

  sendMessage(message: string) {
    this.invoiceService.sendMessage(message, this.id)
      .subscribe(
        response => this.messageSent(response),
        error => console.log(error));
  }

  cancel() {
    if (confirm("Vill du lämna utan att spara några ändringar i fakturan?")) {
      this._location.back();
    }
  }

  messageSent(message: any) {
    this.invoice.Message = undefined;
    this.invoice.Messages.push(message);
  }

  loadUser() {
    this.userService.getCurrentUser()
      .subscribe(value => this.userLoaded(value),
        error => console.log(error)
      );
  }

  userLoaded(user: IUser) {
    this.user = user;
    this.CustomServiceFee = user.AccountSettings.CustomServiceFee * 100;
    this.Commission = user.AccountSettings.Commission * 100;

    this.setPensionSavingsAmount();
  }

  setPensionSavingsAmount() {

    if (this.invoice.Id == 0) {
      if (this.user.AccountSettings.PensionSavingsSettings.Unit.TechnicalName == 'Percent') {
        this.pensionSavingsFactor = this.user.AccountSettings.PensionSavingsSettings.Amount / 100;
        this.pensionSavingsFactorPreset = this.user.AccountSettings.PensionSavingsSettings.Amount;
      }
      else if (this.user.AccountSettings.PensionSavingsSettings.Unit.TechnicalName == 'Share') {
        this.pensionSavingsAmount = this.user.AccountSettings.PensionSavingsSettings.Amount;
        this.pensionSavingsAmountPreset = this.user.AccountSettings.PensionSavingsSettings.Amount;
        this.pensionSavingsTotalAmount = Math.round(this.pensionSavingsAmountPreset * (1 + this.pensionSavingsTaxFactor));
      }
    }
    else if (this.invoice.Id != 0 && this.invoice.PensionSavingsAmount == 0) {
      if (this.invoice.UserSettings.PensionSavingsSettings.Unit.TechnicalName == 'Percent') {
        this.pensionSavingsFactor = this.invoice.UserSettings.PensionSavingsSettings.Amount / 100;
        this.pensionSavingsFactorPreset = this.invoice.UserSettings.PensionSavingsSettings.Amount;
        this.pensionSavingsPercent = this.pensionSavingsFactorPreset;
        this.pensionSavingsTotalAmount = Math.round(this.pensionSavingsAmount * (1 + this.pensionSavingsTaxFactor));
      }
      else if (this.invoice.UserSettings.PensionSavingsSettings.Unit.TechnicalName == 'Share') {
        this.pensionSavingsAmount = this.invoice.UserSettings.PensionSavingsSettings.Amount;
        this.pensionSavingsAmountPreset = this.invoice.UserSettings.PensionSavingsSettings.Amount;
        this.pensionSavingsAmount = this.pensionSavingsAmountPreset;
        this.pensionSavingsTotalAmount = Math.round(this.pensionSavingsAmount * (1 + this.pensionSavingsTaxFactor));
      }      
    }
    else {
      //If invoice exist, get amount from saved invoice
      this.pensionSavingsAmount = this.invoice.PensionSavingsAmount;
      this.pensionSavingsTotalAmount = Math.round(this.pensionSavingsAmount * (1 + this.pensionSavingsTaxFactor));
    }

    this.updatePensionSavingsAmount();
    this.invoice.PensionSavingsAmount = this.pensionSavingsAmount;
    
  }

  updatePensionSavingsAmount(type: string = null) {
    
    this.pensionValidates = true;

    if (this.TotalInvoiceAmount > 0) {

      this.pensionSavingsFactor = this.pensionSavingsPercent / 100;

      //If percent is chosen as calculating factor
      if ((type == 'Share' || this.pensionSavingsFactor > 0) && type != 'Amount') {
        this.pensionSavingsAmount = Math.round(this.TotalInvoiceAmount * this.pensionSavingsFactor);
        this.pensionSavingsTotalAmount = Math.round(this.pensionSavingsAmount * (1 + this.pensionSavingsTaxFactor));
      }
      else {
        this.pensionSavingsTotalAmount = Math.round(this.pensionSavingsAmount * (1 + this.pensionSavingsTaxFactor));
        this.pensionSavingsPercent = 0;
      }

      this.calculatePreliminaryPayout();

      if (this.invoice.CreditInvoiceFor == null) {
        if (this.pensionSavingsTotalAmount != 0 && (this.preliminaryPayout - this.pensionSavingsTotalAmount < 0)) {
          this.errorMessagePension = "Din avsättning till pension får inte överstiga det belopp vi beräknar din lön från (minus våra avgifter)";
          this.pensionValidates = false;
        }
        else {
          this.errorMessagePension = "";
          this.pensionValidates = true;
          this.invoice.PensionSavingsAmount = this.pensionSavingsAmount;
        }
      }
    }
    else {
      this.errorMessagePension = "Du behöver fakturera något innan du kan ange pension";
    }
  }

  getVatList() {

    this.vatList = [];

    if (this.isAdmin) {
      this.vatList.push({ Value: "0" }, { Value: "25" }, { Value: "12" }, { Value: "6" })
    }
    else if (!this.isAdmin && this.invoice.Customer.Country.Name != 'Sverige') {
      this.vatList.push({ Value: "0" })
    }
    else {
      this.vatList.push({ Value: "25" }, { Value: "12" }, { Value: "6" })
    }
}

  currencyChanged() {
    for (var i = 0; i < this.currencies.length; i++) {
      var curr = this.currencies[i];
      if (curr.Id == this.invoice.Currency.Id) {
        this.invoice.Currency.Name = curr.Name;
      }
    }
  } 

  shareTypeChanged(event: any, share: any) {

    if (event == 1) {
      share.Salary = 0;
    }
    else {
      share.Share = 0;
    }

  }

  reverseTaxChange(group: any, value) {
    this.invoiceQuickGroup.ReverseTax = value.checked;
    this.invoiceQuickGroup.Vat = value.checked ? "0" : "25";
  }

  openLoadingSpinner() {
    this.dialogSpinner = this.dialog.open(ModalSpinnerDialogComponent, this.configSpinner);
  }

  // WordCounters
  wordCountDescription() {
    this.resultDescription = this.invoiceQuickGroup.Description.length;

    //this.txt = this.invoiceQuickGroup.Description.value;
    //this.text = this.txt.split(".");
    //this.str = this.text.join('.</br>');
  }

  wordCountMarking() {
    this.resultMarking = this.invoice.Marking.length;
  }

  loadInvoice(id: number) {

    this.invoiceService.getInvoiceShareTypes()
      .subscribe(
        response => this.invoiceShareTypes = response,
        error => console.log(error));

    this.invoiceService.getInvoice(id)
      .subscribe(
        response => this.onGetInvoiceCompleted(response),
        error => console.log(error));    
  }

  loadCurrencies() {
    this.currencyService.getCurrencies()
      .subscribe(
        response => this.currencies = response,
        error => console.log(error));
  }

  getGroupTotalHours(group: any) {
    var totalMinutes = 0;

    for (var i = 0; i < group.InvoiceRows.length; i++) {
      totalMinutes += parseInt(group.InvoiceRows[i].HoursWorked) * 60;
      totalMinutes += parseInt(group.InvoiceRows[i].MinutesWorked);
    }

    return this.time_convert(totalMinutes);
  }

  time_convert(num) {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;

    if (minutes > 0) {
      return hours + " h " + minutes + " min";
    }
    else {
      return hours + " h";
    }

  }

  onGetInvoiceCompleted(invoice: any) {
    this.invoice = invoice;    
    this.isLoading = false;
    this.dialogSpinner.close();
    this.invoice.Currency.Id = this.invoice.Currency.Id.toString();
    this.ExpressPayoutLocal = this.invoice.ExpressPayOut;

    if (this.invoice.Allowances == null)
      this.invoice.Allowances = [];     

    this.setTotalHoursWorked();
    this.getInvoicePaymentTerm();
    //this.invoice.PaymentTerm = (this.invoice.PaymentTerm == undefined || this.invoice.PaymentTerm == "0") ? "30" : this.invoice.PaymentTerm.toString();
    this.getCompanies();

    if (this.invoice.TravelDistance > 0) {
      this.invoice.ShowMileDeduction = true;
    }

    if (this.invoice.Deductions.length > 0) {
      this.invoice.ShowDeductions = true; 
    }

    for (let deduction of this.invoice.Deductions) {
      deduction.IncludeInInvoice = deduction.IncludeInInvoice.toString();
    }

    if (this.invoice.FeePaidByReciever == undefined || this.invoice.FeePaidByReciever) {
      this.feePaidByReciever = true;
      this.feePaidBySender = false;
    }
    else {
      this.feePaidBySender = true;
      this.feePaidByReciever = false;
    }

    this.invoice.TravelExpenses.TravelReportTotalAmount = this.invoice.TravelExpenses.TravelReportTotalAmount == 0 ? undefined : this.invoice.TravelExpenses.TravelReportTotalAmount;
    this.invoice.TravelExpenses.DriveJournalTotalAmount = this.invoice.TravelExpenses.DriveJournalTotalAmount == 0 ? undefined : this.invoice.TravelExpenses.DriveJournalTotalAmount;

    this.loadingService.complete();

    if (this.invoice.SubInvoices.length > 0) {
      this.hasSubInvoices = true;
    }

    if (this.invoice.Customer != null) {
      this.setLockedValues(this.invoice.Customer);

      if (this.invoice.Customer.InvoiceSendType > 0) {
        this.invoice.InvoiceSendType = this.invoice.Customer.InvoiceSendType;
      }
      else {
        this.invoice.InvoiceSendType = 1;
      }
    }
    
    if (this.invoice.Type != null && this.invoice.Type.TechnicalName == "CommissionCashInvoice") {
      
      this.IsCommissionInvoice = true;
    }

    this.checkTotalAttachmentSize(this.invoice.Documents);
    this.getVatList();
    this.setPensionSavingsAmount();
    debugger
    this.checkCorrectEmailFormat(this.invoice.RecieverEmail, 'RecieverEmail');
    this.checkCorrectEmailFormat(this.invoice.RecieverEmailCopy, 'RecieverEmailCopy');


    if (this.showExpressDialog)
      this.expressPayoutClick(true);
  }

  getInvoicePaymentTerm() {
    
    this.invoice.PaymentTerm = (this.invoice.PaymentTerm == undefined || this.invoice.PaymentTerm == "0") ? "30" : this.invoice.PaymentTerm.toString();

    if (this.invoice.Customer.PaymentTermLocked) {
      this.invoice.PaymentTerm = this.invoice.Customer.PaymentTerm.toString();
    }
  }

  // Gets the duedate for invoice
  getDueDate() {
    this.PaymentTermInt = parseInt(this.invoice.PaymentTerm);
    this.today = new Date();
    this.DueDate = new Date().setDate(this.today.getDate() + this.PaymentTermInt);
  }

  getAllowanceTypeName(id: string) {
    if (id == "1") {
      return "Milersättning";
    }
    else if (id == "2") {
      return "Traktamente inrikes"
    }
    else if (id == "3") {
      return "Traktamente utrikes"
    }
  }

  updateSumNetExpenses() {

    this.sumNetExpenses = 0;

    //Sums all deductions
    for (var i = 0; i < this.invoice.Deductions.length; i++) {
      this.sumNetExpenses += this.invoice.Deductions[i].Amount;
    }

    //Sums all allowances
    for (var i = 0; i < this.invoice.Allowances.length; i++) {
      this.sumNetExpenses += this.invoice.Allowances[i].Sum;
    }
  }

  expressPayoutClick(express: boolean) {

    // // stop the click from unchecking the checkbox
    event.preventDefault();

    if (express) {

      // Personal number and not logged in via BankID
      if (!this.authenticationService.currentUserIsLoggedInWithBankId() && !this.authenticationService.currentUserHasCoordinationNumber()) {

        var config = JSON.parse(JSON.stringify(this.config));
        config.height = 310;
        config.width = 400;
        config.data.hideInput = true;
        config.data.requireInput = false;
        config.data.headline = "BankID-inloggning krävs!"
        config.data.confirmButtonText = "Ok!";
        config.data.confirmButtonIcon = "check";
        config.data.text = "För att kunna välja alternativet Expressutbetalning krävs att du är inloggad med BankID.<br><br>Du kommer nu att skickas till BankID-inloggningen och sedan tillbaka till din nystartade faktura.";

        let dialogRef = this.dialog.open(ConfirmDialogComponent, config);

        dialogRef.afterClosed().subscribe((result: IConfirmResponse) => {
          dialogRef = null;

          if (result.Confirmed) {
            // If it´s not saved, save a draft so we can return to the page later
            this.saveDraftAndSurpressErrors(this.invoice).subscribe(response => {
              this.localStorageService.removeItem("AUTH");
              this.localStorageService.setItem("editInvoice", response.Id);
              this.authenticationService.logout();
            }, err => alert(err));

          }
        });

      }
      // No personal number or allready logged in
      else {
        // ask for confirmation
        var config = JSON.parse(JSON.stringify(this.config));
        config.height = 405;
        config.width = 400;
        config.data.hideInput = true;
        config.data.requireInput = false;
        config.data.confirmButtonText = "Godkänn";
        config.data.confirmButtonIcon = "check";
        config.data.headline = "Expressutbetalning"
        config.data.text = "För att få en djupare kunskap kring riskerna med expressutbetalning, vänligen kontakta oss för utförlig information. Viktigast att veta är att man eventuellt kan bli återbetalningsskyldig för de eventuella utgifter SAMpoolen haft för löneutbetalningen, om fakturan förblir obetald efter alla gängse påtryckningar. Om du redan är införstådd med riskerna kan du klicka dig vidare. Härmed intygar jag att jag är införstådd med riskerna kring expressutbetalning";
        
        let dialogRef = this.dialog.open(ConfirmDialogComponent, config);

        dialogRef.afterClosed().subscribe((result: IConfirmResponse) => {
          dialogRef = null;

          if (result.Confirmed) {
            this.invoice.ExpressPayOut = true;
            setTimeout(() => this.updateExpressPayoutStatusUI(express));
          }
        });
      }

    }
    else {
      this.invoice.ExpressPayOut = false;
    }
  }

  updateExpressPayoutStatusUI(express: boolean) {
    this.rbExpressPayoutYes.checked = express;
    this.rbExpressPayoutNo.checked = !express;
  }

  calculatePreliminaryPayout() { 

    var sampoolenFee = 0;
    var invoiceFee = 0;
    this.updateSumNetExpenses();

    //Calculates SAMpoolen-fee    
    if (this.ExpressPayoutLocal == true) {
      sampoolenFee = Math.round(this.invoice.TotalInvoiceAmount * 0.05);
    }
    else {
      sampoolenFee = Math.round(this.invoice.TotalInvoiceAmount * 0.02);
    }

    //Calculate invoice fee
    if (this.invoice.FeePaidByReciever == false) {
      invoiceFee = 29;
    }

    this.sumFees = sampoolenFee + invoiceFee;
    this.preliminaryPayout = this.invoice.TotalInvoiceAmount - this.sumFees;

  }

  saveDraftAndSurpressErrors(invoice: any) {
    this.calculatePreliminaryPayout();

    if (!this.canUpdateGeneral()) {
      return this.invoiceService.updateInvoice(invoice, 'Fakturan uppdaterad!', undefined);
    }
    else if (this.canUpdateInvoiceDraft()) {

      if (this.invoice.Status == null) {
        invoice.Status = { Name: 'Utkast' };
      }

      if (invoice.Id == undefined || invoice.Id == 0) {
        return this.invoiceService.createInvoice(invoice, 'Fakturan skapad!', undefined);
      }
      else {
        return this.invoiceService.updateInvoice(invoice, 'Fakturan uppdaterad!', undefined);
      }

    }
    else {
      alert('Ett ohanterat fel inträffade, vänligen kontakta support!');
    }

  }

  fileChange(event: any, file: any) {

    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let f: File = fileList[0];

      this.invoiceService.uploadDocument(f, file.Attachment.Guid)
        .subscribe(
          response => this.onDocumentSaved(response),
          error => console.log(error));

    }
  }

  uploadAddFile(event: any, file: any) {

    if (event.target.files.length > 0) {

      let fileList: FileList = event.target.files;
      let f: File = fileList[0];

      if (Array.isArray(file)) {

        var newFile = { Guid: this.newGuid(), Name: f.name };
        file.push(newFile);

        this.invoiceService.uploadDocument(f, newFile.Guid)
          .subscribe(
            response => this.fileUploaded(response, newFile),
            error => console.log(error));

      }
      else {
        if (file.Guid == undefined) {
          file.Guid = this.newGuid();
        }

        this.invoiceService.uploadDocument(f, file.Guid)
          .subscribe(
            response => this.fileUploaded(response, file),
            error => console.log(error));

      }

    }
  }

  fileUploaded(response: any, file: any) {
    file.Guid = response.Guid;
    file.Url = response.Url;
  }

  deleteReciept(guid: string) {

    for (var i = 0; i < this.invoice.TravelExpenses.Reciepts.length; i++) {
      if (this.invoice.TravelExpenses.Reciepts[i].Guid == guid)
        this.invoice.TravelExpenses.Reciepts.splice(i, 1);
    }
  }

  newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  showDocument(url: string) {
    console.log(url);

    window.open(url);
  }

  editDeduction(sender, deduction: IDeduction) {

    if (deduction != null) {
      this.config.data.deduction = deduction;

      if (this.config.data.deduction.AttachmentReciept == null)
        this.config.data.deduction.AttachmentReciept = <IFile>{ Id: 0, Guid: null }

      if (this.config.data.deduction.Attachment == null)
        this.config.data.deduction.Attachment = <IFile>{ Id: 0, Guid: null }
    }
    else {
      this.config.data.deduction = { Id: this.newGuid(), Attachment: <IFile>{ Id: 0, Guid: null }, AttachmentReciept: <IFile>{ Id: 0, Guid: null }, VatPercentage: '25', TravelExpense: false, AddedToInvoice: false } as IDeduction;


      if (sender == "addedToInvoice") {
        this.config.data.deduction.AddedToInvoice = true;
      }
    }

    let dialogRef = this.dialog.open(EditDeductionDialogComponent, this.config);

    dialogRef.afterClosed().subscribe((result: IDeduction) => {


      if (result != null) {
        for (var i = 0; i < this.invoice.Deductions.length; i++) {
          var d = this.invoice.Deductions[i];

          if (d.Id == result.Id) {
            this.invoice.Deductions[i] = result;

            this.updateTotalInvoiceAmount();
            return;

          }
        }

        this.invoice.Deductions.push(result);
        this.updateTotalInvoiceAmount();
      }
      dialogRef = null;

    });
  }

  deleteDeduction(deduction: any) {

    for (var i = 0; i < this.invoice.Deductions.length; i++) {
      var d = this.invoice.Deductions[i];

      if (d.Attachment.Guid == deduction.Attachment.Guid) {
        this.invoice.Deductions.splice(i, 1);
        this.updateTotalInvoiceAmount();
        return;
      }

    }

    this.updateSumNetExpenses();
  }

  editAllowance(sender, allowance: IAllowance) {


    if (allowance != null) {

      this.config.data.allowance = allowance;

      if (this.config.data.allowance.AttachmentReciept == null)
        this.config.data.allowance.AttachmentReciept = <IFile>{ Id: 0, Guid: null }

      if (this.config.data.allowance.Attachment == null)
        this.config.data.allowance.Attachment = <IFile>{ Id: 0, Guid: null }
    }
    else {
      this.config.data.allowance = { Id: this.newGuid(), Attachment: <IFile>{ Id: 0, Guid: null }, AttachmentReciept: <IFile>{ Id: 0, Guid: null }, VatPercentage: '25' } as IAllowance;

      if (sender == "addedToInvoice") {
        this.config.data.allowance.AddedToInvoice = true;
      }
    }

    let dialogRef = this.dialog.open(EditAllowanceDialogComponent, this.config);

    dialogRef.afterClosed().subscribe((result: IAllowance) => {

      if (result != null) {

        for (var i = 0; i < this.invoice.Allowances.length; i++) {
          var d = this.invoice.Allowances[i];

          if (d.Id == result.Id) {
            this.invoice.Allowances[i] = result;
            return;
          }
        }

        if (result.CustomReimbursementPerKm > 0) {
          
        }

        this.invoice.Allowances.push(result);
        this.updateTotalInvoiceAmount();
      }

      dialogRef = null;

    });
  }

  deleteAllowance(allowance: any) {
    for (var i = 0; i < this.invoice.Allowances.length; i++) {
      var a = this.invoice.Allowances[i];

      if (a.Id == allowance.Id) {
        this.invoice.Allowances.splice(i, 1);
        return;
      }

      this.updateSumNetExpenses();
      this.updateTotalInvoiceAmount();

    }
  }

  onDocumentSaved(attachment: any) {
    for (var i = 0; i < this.invoice.Deductions.length; i++) {
      var doc = this.invoice.Deductions[i];

      
      if (doc.Attachment.Guid == attachment.Guid) {
        doc.Attachment.Url = attachment.Url;
        doc.Attachment.FileType.Id = attachment.FileType.Id;
      }
    }
    
  }

  sendValidates() {
    
    //You can only send if the status is Draft
    if (this.invoice.Status.TechnicalName != 'Draft') {
      this.ErrorMessageSend = 'Den här fakturan har skickats';
      this.sendInvoiceValidates = false;
      return false;
    }    

    //Customer is mandatory 
    else if (!this.invoice.Customer.Id) {
      this.ErrorMessageSend = 'Du behöver lägga till en kund';
      return false;
    }

    //Customer needs to have the active status 
    else if (!this.invoice.Customer.Active) {
      this.ErrorMessageSend = 'Du kan inte använda kund med detta organisationsnummer. Kontakta SAMpoolen vid frågor.';
      return false;
    }

    //Customer reference is mandatory
    else if (this.invoice.Customer.IsCompany && !this.invoice.Reference.Id) {
      this.ErrorMessageSend = 'Du behöver lägga till en kontaktperson till din kund';
      this.sendInvoiceValidates = false;
      return false;
    }

    //Only credit invoices can be 0 or less
    else if ((this.invoice.TotalInvoiceAmount <= 0 || this.TotalInvoiceAmount <= 0) && this.invoice.CreditInvoiceFor == null) {
      this.ErrorMessageSend = 'Du måste fakturera mer än 0 kr i punkt nr 2';
      this.sendInvoiceValidates = false;
      return false;
    }

    //Total filesize maximum is 15Mb
    else if (this.totalFileSize > 15728640) {
      this.ErrorMessageSend = "Dina bifogade filer får inte överstiga 15Mb tillsammans";
      this.errorMessageFiles = "Dina bifogade filer får inte överstiga 15Mb tillsammans";
      this.sendInvoiceValidates = false;
      return false;
    }
    
    else if (this.attachedFilesCount > 5) {
      this.ErrorMessageSend = "Du får bifoga högst 5 st dokument";
      this.errorMessageFiles = "Du får bifoga högst 5 st dokument";
      this.sendInvoiceValidates = false;
      return false; 
    }

    //You need an invoice send type
    else if (this.invoice.InvoiceSendType == 0) {
      this.ErrorMessageSend = 'Du har inte angivit någon leveransmetod för din faktura, se punkt 3';
      this.sendInvoiceValidates = false;
      return false;
    }

    //You need a reciever email if sendtype is by email
    else if (this.invoice.InvoiceSendType == 1 && !this.invoice.RecieverEmail) {
      this.ErrorMessageSend = 'Du har valt e-post som leverenssätt, men mottagaren saknar e-postadress';
      this.sendInvoiceValidates = false;
      return false; 
    }

    else if (this.invoice.RecieverEmail != '' && this.recieverEmailValid == false) {
      this.ErrorMessageSend = 'Fakturamottagarens epost har fel format';
    }

    else if (this.invoice.RecieverEmail != '' && this.recieverEmailCopyValid == false) {
      this.ErrorMessageSend = 'Epost-adress till mottagare av fakturakopia har fel format';
    }

    //If RUT or ROT you need to add km to invoice
    else if ((this.invoice.IsRot && this.invoice.RotDrivingFeeKm < 1) || (this.invoice.IsRut && this.invoice.RotDrivingFeeKm < 1)) {
      this.ErrorMessageSend = 'När du valt RUT/ROT behöver du ange framkörningssträckan';
      this.sendInvoiceValidates = false;
      return false;
    }
    //If ROT, you need a property
    else if (this.invoice.IsRot && (this.invoice.RotProperty == null || this.invoice.RotProperty == '' || this.invoice.RotProperty == ' ')) {
      this.ErrorMessageSend = 'När du valt ROT behöver du ange fastighet/lägenhetsnummer.';
      this.sendInvoiceValidates = false;
      return false;
    }

    //Terms of use must be checked
    else if (!this.invoice.termsAgreed && !this.isAdmin) {
      this.ErrorMessageSend = 'Du måste godkänna användarvillkoren';
      this.sendInvoiceValidates = false;
      return false;
    }
    
    //If electronic reference is mandatory it needs to be filled out
    else if ((!this.invoice.ReferenceText && this.invoice.Customer.ElectronicReferenceMandatory) && !this.isAdmin) {
      this.ErrorMessageSend = 'Din kund kräver en elektronisk referens, se punkt 3';
      this.sendInvoiceValidates = false;
      return false;
    }

    //Reciever email and email copy can't be the same
    else if (this.invoice.RecieverEmailCopy != null && (this.invoice.RecieverEmailCopy == this.invoice.RecieverEmail)) {
      this.ErrorMessageSend = 'Mottagare av fakturakopia får inte vara densamma som mottagare av faktura, se punkt 3';
      this.sendInvoiceValidates = false;
      return false;
    }
           
      //Pension can't be higher than preliminary payout
    else if (!this.pensionValidates && this.invoice.CreditInvoiceFor == null) {
        this.ErrorMessageSend = 'Du har gjort en för hög avsättning till pension';
        this.sendInvoiceValidates = false;
        return false;
    }
    
    else if (this.checkDeductionAttachments() == false) {
      this.ErrorMessageSend = 'Ett avdrag saknar uppladdat kvitto';
      this.sendInvoiceValidates = false;
      return false;
    }
    
    else {      
      this.ErrorMessageSend = '';
      this.errorMessageFiles = '';
      this.sendInvoiceValidates = true;
      return true;
    }

    
  }

  checkDeductionAttachments() {    
    for (var i = 0; i < this.invoice.Deductions.length; i++) {
      if (this.invoice.Deductions[i].Attachment == null || this.invoice.Deductions[i].Attachment.Id == 0) {
        return false;
      }
    }
  }

  personalOrCompanyNumberValid(personalNumber: string) {
    const value: string = personalNumber || '';

    this.personalOrOrgNumberValid = value.match(/^\d{12}$/) != null || value.match(/^\d{6,8}[-|(\s)]{0,1}\d{4}$/) != null ? true : false;
 
    return this.personalOrOrgNumberValid;
    
  }

  onDraftSaved(response: any) {
    this.router.navigate(['/mypages/invoices']);
  }

  canUpdateInvoice() {
    // You have to be able to save payout information untill it's been paid out
    let statuses = ["Draft", "UnderReview", "SentUnpaid", "SentReminder", "SentCollection", "SentBailiff"];

    if (this.isAdmin) {
      return !this.isLoading;
    }
    else {
      return this.isLoading == false &&
        this.invoice.Customer.Id > 0 &&
        (this.TotalInvoiceAmount >= 0 || (this.TotalInvoiceAmount < 0 && this.invoice.CreditInvoiceFor != null)) &&
        this.invoice.termsAgreed &&
        this.referenceValidates() &&
        statuses.includes(this.invoice.Status.TechnicalName)
    }
  }

  canUpdateInvoiceDraft() {

    let statuses = ["Draft", "UnderReview", "SentUnpaid", "SentReminder", "SentCollection", "SentBailiff"];

    if (this.isAdmin) {
      return true;
    }
    else {
      return this.isLoading == false &&
        //this.invoice.Customer.Id > 0 &&
        //this.TotalInvoiceAmount > 0 &&
        //this.referenceValidates() &&
        statuses.includes(this.invoice.Status.TechnicalName)
    }
  }

  canUpdatePayout() {
    let statuses = ["Draft", "UnderReview", "SentUnpaid", "SentReminder", "SentCollection", "SentBailiff"];

    if (this.isAdmin) {
      return true;
    }
    else {
      return statuses.includes(this.invoice.Status.TechnicalName)
    }
  }

  canUpdateGeneral() {

    if (this.isAdmin) {
      return true && !this.isLoading;
    }
    else {
      let statuses = ["Draft"];

      if (this.invoice.CreditInvoiceFor != null) {
      }
      else {
        return !this.isLoading && statuses.includes(this.invoice.Status.TechnicalName)
      }
    }
  }

  referenceValidates() {
    // Reference required when customer is company
    return !(this.invoice.Customer.Id != undefined && this.invoice.Customer.IsCompany && this.invoice.Reference.Id == 0);
  }

  validPreview() {
    return true;
  }

  saveQuickRow(group: any, openEdit: boolean) {

    group.Id = this.newGuid();
    group.isNew = true;

    this.invoice.InvoiceGroups.push(group);
    this.setTotalHoursWorked();
    this.invoiceQuickGroup = JSON.parse(JSON.stringify(this.invoiceQuickGroupTemplate));

    if (openEdit) {
      this.editInvoiceCalendar(this.invoice.InvoiceGroups[this.invoice.InvoiceGroups.length - 1]);
    }
    
    if (this.invoice.Customer.Country.Name != "Sverige" && !this.isAdmin) {
      group.Vat = "0";
    }

    this.updateTotalInvoiceAmount();

  }

  public quickRowValidate() {

    if (this.invoiceQuickGroup.Description == '' || this.invoiceQuickGroup.Description == ' ') {
      this.ErrorMessageTab = '';
    }  

    this.checkForbiddenSymbols(this.invoiceQuickGroup.Description);

  }

  public checkForbiddenSymbols(description: any) {

    var regex = /^[-a-öA-Ö0-9,./&€%;+:@!?() \r\n]*[^|¤"–]$/g;
    var inputIsValid = regex.test(description);

    if (inputIsValid) {
      this.quickrowValid = true;
      this.ErrorMessageDesc = '';
      return true;
    }
    else {
      this.quickrowValid = false;
      this.ErrorMessageDesc = 'Beskrivningen innehåller otillåtna tecken. Du behöver radera otillåtna tecken och tabbar. Godkänt innehåll är a-ö, A-Ö, 0-9 samt , . ! ( ) ? / -';
      return false;
    }
  }

  public checkCorrectEmailFormat(email: any, type: string) {
    debugger
    var regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    var inputIsValid = regex.test(email);

    if (inputIsValid || email == '') {

      if (type == 'RecieverEmail') {
        this.ErrorMessageRecieverEmail = '';
        this.recieverEmailValid = true;
      }
      if (type == 'RecieverEmailCopy') {
        this.ErrorMessageRecieverCopyEmail = '';
        this.recieverEmailCopyValid = true;
      }

      return true;
    }
    else {
      if (type == 'RecieverEmail') {
        this.ErrorMessageRecieverEmail = 'E-postadressen har fel format';
        this.recieverEmailValid = false;
      }
      if (type == 'RecieverEmailCopy') {
        this.ErrorMessageRecieverCopyEmail = 'E-postadressen har fel format';
        this.recieverEmailCopyValid = false;
      }
      return false;
    }
  }

  saveQuickRowValidates() {
    
    if (this.invoiceQuickGroup.Description == '' || this.invoiceQuickGroup.Description == ' ' && this.invoiceQuickGroup.Amount > 0) {
      this.errorMessageSaveQuickRow = 'Du har inte angivit någon beskrivning.'
    }
    else {
      this.errorMessageSaveQuickRow = '';
    }

    if (this.invoiceQuickGroup.Amount < 0 || this.invoiceQuickGroup.Amount > 0)  {
      this.errorMessageSaveQuickRow = '';
    } 
    else {
      this.errorMessageSaveQuickRow = 'Du har inte angivit något belopp.'
    }    

    if (this.invoiceQuickGroup.Description != '' && this.invoiceQuickGroup.Description != ' ' && (this.invoiceQuickGroup.Amount < 0 || this.invoiceQuickGroup.Amount > 0)) {
      this.errorMessageSaveQuickRow = '';
      return true;
    }
    else {
      return false;
    }
  }

  timeReportValidates() {
    if (this.invoice.Customer.TimeReportMandatory) {
      if (this.invoiceQuickGroup.TimeReportStatus.Name == 'DetailedInfo') {
        this.errorMessageSaveQuickRow = '';
        return true;
      }
      else {
        this.errorMessageSaveQuickRow = 'Din kund kräver att du uppger detaljerad tid.'
        return false;
      }
    }
    else {
      return true;
    }
  }

  editInvoiceCalendar(calendarGroup: any) {
    this.config.data.invoiceItemGroup = calendarGroup;
    this.config.data.status = this.invoice.Status.TechnicalName;
    this.config.data.customerCountryName = this.invoice.Customer.Country.Name;
    this.config.data.customer.TimeReportMandatory = this.invoice.Customer.TimeReportMandatory;
    let dialogRef = this.dialog.open(InvoiceToolCalendarDialogComponent, this.config);



    dialogRef.afterClosed().subscribe((result: any) => {

      var updated = false;
      if (result != undefined) {

        for (var i = 0; i < this.invoice.InvoiceGroups.length; i++) {
          if (this.invoice.InvoiceGroups[i].Id === result.Id) {
            if (result.action == 'delete') {
              this.invoice.InvoiceGroups.splice(i, 1);
            }
            else {
              this.invoice.InvoiceGroups[i] = result;
            }

            if (this.invoice.Customer.Country.Name != "Sverige" && !this.isAdmin) {
              this.invoice.InvoiceGroups[i].Vat = "0";
            }

            updated = true;
            break;
          }



        }

        if (!updated && this.invoice.action != 'delete')
          this.invoice.InvoiceGroups.push(result);

        //this.workCalendarList = result;
        this.setTotalHoursWorked();

      }

      this.updateTotalInvoiceAmount();
      this.updatePensionSavingsAmount();


      dialogRef = null;
    });
  }

  preview(invoice: any) {

    var config = JSON.parse(JSON.stringify(this.config));
    config.height = '80%';
    config.width = '80%';
    config.data = this.invoice;
    config.data.TotalInvoiceAmount = this.TotalInvoiceAmount;


    let dialogRef = this.dialog.open(InvoicePreviewDialogComponent, config);

    dialogRef.afterClosed().subscribe((result: any) => {

      dialogRef = null;
    });
  }

  InvoiceSendMethodChange(type: any) {
    
    if (type == undefined || type == 0)
      type = 1;

    this.invoice.InvoiceSendType = type;

    if (type == 2) { // Mail
      this.invoice.InvoiceSendMethodMail = true;
      this.invoice.InvoiceSendMethodEmail = false;
    }
    else if (type == 1) { // Email
      this.invoice.InvoiceSendMethodMail = false;
      this.invoice.InvoiceSendMethodEmail = true;
    }

  } 

  setTotalHoursWorked() {
    this.hoursWorkedTotal = 0;
    this.TotalInvoiceAmount = 0;

    for (var i = 0; i < this.invoice.InvoiceGroups.length; i++) {

      this.TotalInvoiceAmount += this.invoice.InvoiceGroups[i].Amount;

      // Quick add don´t have rows
      if (this.invoice.InvoiceGroups[i].InvoiceRows != undefined) {

        for (var e = 0; e < this.invoice.InvoiceGroups[i].InvoiceRows.length; e++) {

          this.hoursWorkedTotal += parseFloat(this.invoice.InvoiceGroups[i].InvoiceRows[e].HoursWorked);
          this.hoursWorkedTotal += parseFloat(this.invoice.InvoiceGroups[i].InvoiceRows[e].MinutesWorked) / 60;
        }
      }
    }
  }

  updateTotalInvoiceAmount() {
    
    //Update total invoice amount when allowances or deductions are added to invoice (for customer to pay)
    this.setTotalHoursWorked();

    this.TotalInvoiceAmount = Number(this.TotalInvoiceAmount);
        
    for (var i = 0; i < this.invoice.Deductions.length; i++) {
      if (this.invoice.Deductions[i].AddedToInvoice == true) {
        this.TotalInvoiceAmount = Number(this.TotalInvoiceAmount + this.invoice.Deductions[i].Amount);
      }
    }

    for (var i = 0; i < this.invoice.Allowances.length; i++) {
      if (this.invoice.Deductions[i].AddedToInvoice == true) {
        this.TotalInvoiceAmount = Number(this.TotalInvoiceAmount + this.invoice.Allowances[i].Sum);
      }
    }
    
    this.calculateRotRut();
    
    this.invoice.TotalInvoiceAmount = Number(this.TotalInvoiceAmount);

  }

  calculateRotRut() {

    //If changed to foreign country / company, invoice can't have rot/rut
    if (this.invoice.Customer.Country.Name != 'Sverige' || this.invoice.Customer.IsCompany) {
      this.invoice.IsRot = false;
      this.invoice.IsRut = false;
    }

    if (this.invoice.RotDrivingFeeTotalAmount > 0 && (this.invoice.IsRot || this.invoice.IsRut)) {
      this.TotalInvoiceAmount += this.invoice.RotDrivingFeeTotalAmount;
    }


  }

  addCustomer() {

    var ownerId = this.invoice.User != undefined ? this.invoice.User.Id : undefined;

    // remove previous edit
    this.config.data.customer = undefined;

    this.config.data.customerTemplate = { Id: 0, User: { Id: ownerId }, Country: { Id: 1, Name: 'Sverige' }, IsCompany: true };


    let dialogRef = this.dialog.open(EditCustomerDialogComponent, this.config);

    dialogRef.afterClosed().subscribe((result: ICompany) => {
      
      if (result != undefined) {
        
        this.companies.push(result);

        // Remove this, check for empty
        this.invoice.Customer = {
          Active: result.Active,
          AcceptsInvoiceFee: result.AcceptsInvoiceFee,
          AcceptsInvoiceFeeLocked: result.AcceptsInvoiceFeeLocked,
          Address: result.Address,
          AddressLocked: result.AddressLocked,          
          City: result.Address,
          CityLocked: result.CityLocked,
          ElectronicReference: result.ElectronicReference,
          ElectronicReferenceMandatory: result.ElectronicReferenceMandatory,
          GlnNumber: result.GlnNumber,
          GlnNumberLocked: result.GlnNumberLocked,          
          InvoiceSendType: result.InvoiceSendType,
          InvoiceSendTypeLocked: result.InvoiceSendTypeLocked,  
          Id: result.Id,
          InvoiceEmail: result.InvoiceEmail,
          InvoiceEmailLocked: result.InvoiceEmailLocked,
          Name: result.InvoiceEmail,
          NameLocked: result.NameLocked,
          OrgNumber: result.OrgNumber,
          PaymentTerm: result.PaymentTerm,
          PaymentTermLocked: result.PaymentTermLocked,
          PublicInfo: result.PublicInfo,
          Reference: result.Reference,
          TimeReportMandatory: result.TimeReportMandatory,
          Zip: result.Zip,
          ZipLocked: result.ZipLocked,
          Contacts: [],
          IsCompany: result.IsCompany,
          Country: { Id: null, Name: null }
        };

        debugger
        this.UpdateCompany(result, this.invoice.Customer);

        this.setInvoiceEmail(this.invoice.Customer.InvoiceEmail);
        this.setRecieverAddress();
        this.setElectronicReferenceText();
        this.getInvoicePaymentTerm();
      }
            
      this.setLockedValues(this.invoice.Customer);

      dialogRef = null;
    });
  }

  addContact() {

    this.config.data.contact = undefined;
    this.config.data.companyId = this.invoice.Customer.Id;
    let dialogRef = this.dialog.open(EditContactDialogComponent, this.config);

    dialogRef.afterClosed().subscribe((result: IContact) => {

      if (result != undefined) {
        this.addSavedContact(result, this.invoice.Customer.Id);
      }

      dialogRef = null;
    });
  }

  editContact(contact: IContact) {

    this.config.data.contact = contact;
    this.config.data.companyId = this.invoice.Customer.Id;
    let dialogRef = this.dialog.open(EditContactDialogComponent, this.config);

    dialogRef.afterClosed().subscribe((result: IContact) => {

      this.invoice.Reference.Name = result.Name;
      this.invoice.Reference.Email = result.Email;
      this.invoice.Reference.Phone = result.Phone;

      for (var i = 0; this.invoice.Customer.Contacts.length; i++) {
        var contact = this.invoice.Customer.Contacts[i];
        if (contact.Id == result.Id) {
          contact.Name = result.Name;
          contact.Email = result.Email;
          contact.Phone = result.Phone;
        }
      }

      dialogRef = null;
    });

  }

  setInvoiceEmail(email: string) {
    
    this.invoice.RecieverEmail = JSON.parse(JSON.stringify(email));
    this.invoice.InvoiceSendMethodEmail = true;
    this.invoice.InvoiceSendMethodMail = false;
  } 

  setLockedValues(customer) {
        
    if (this.invoice.Customer.InvoiceSendTypeLocked == true) {
      this.invoice.InvoiceSendType = customer.InvoiceSendType;
      this.invoiceSendTypeLocked = true;
    }
    else {
      this.invoiceSendTypeLocked = false;
    }

    if (customer.AcceptsInvoiceFeeLocked) {
      if (!customer.AcceptsInvoiceFee) {
        this.invoice.FeePaidByReciever = false;
        this.invoiceFeeLocked = true;
      }
    }
    else {
      this.invoiceFeeLocked = false;
    }

    if (customer.InvoiceEmailLocked) {
      this.invoice.RecieverEmail = customer.InvoiceEmail;
      this.recieverEmailLocked = true;
    }
    else {
      this.recieverEmailLocked = false;
    }

    if (customer.InvoiceSendTypeLocked) {
      this.invoice.InvoiceSendType = customer.InvoiceSendType;
      this.invoiceSendTypeLocked = true;
    }
    else {
      this.invoiceSendTypeLocked = false;
    }
    
    if (customer.ElectronicReferenceMandatory) {
      this.invoice.ReferenceText = this.invoice.Customer.ElectronicReference;
      this.electronicReferenceMandatory = true;
    }
    else {
      this.electronicReferenceMandatory = false;
    }

    this.getInvoicePaymentTerm();

  }

  setRecieverAddress() {
    this.invoice.RecieverCompanyName = this.invoice.Customer.Name;
    this.invoice.RecieverAddress1 = this.invoice.Customer.Address;
    this.invoice.RecieverZip = this.invoice.Customer.Zip;
    this.invoice.RecieverCity = this.invoice.Customer.City;
  }

  setElectronicReferenceText() {    
    this.invoice.ReferenceText = this.invoice.Customer.ElectronicReference;
  }

  setCountryName(country: string) {
    this.invoice.Customer.Country.Name = JSON.parse(JSON.stringify(country));
  }

  editCustomer() {
    
    this.config.data.customer = this.invoice.Customer;
    let dialogRef = this.dialog.open(EditCustomerDialogComponent, this.config);

    dialogRef.afterClosed().subscribe((result: ICompany) => {
      this.updateCustomer(result); 
      this.setInvoiceEmail(result.InvoiceEmail);
      this.setLockedValues(result);
      dialogRef = null;
    });

    
  }

  getCompanies() {

    // Get contacts from invoice data if invoice allready exists
    // Get from logged in user if new 

    if (this.invoice.User != undefined) {

      this.companyService.getCompanies(this.invoice.User.Id)
        .subscribe(value => this.onGetCompaniesFinished(value),
          error => this.onGetCompaniesError(error)
        );
    }
    else {
      this.companyService.getMyCompanies()
        .subscribe(value => this.onGetCompaniesFinished(value),
          error => this.onGetCompaniesError(error)
        );
    }
  }

  onGetCompaniesError(error: any) {
  }

  onGetCompaniesFinished(companies: any) {    
    this.companies = companies;
    //console.log("Kunder");
    //console.log(companies);
  }

  customerChange(id) {
    
    this.invoice.Customer = this.getCustomer(id);
    this.setInvoiceEmail(this.invoice.Customer.InvoiceEmail);
    this.setRecieverAddress();
    this.setElectronicReferenceText();
    this.invoice.Reference.Id = 0;

    if (this.invoice.Customer.InvoiceSendType > 0) {
      this.invoice.InvoiceSendType = this.invoice.Customer.InvoiceSendType;
    }
    else {
      this.invoice.InvoiceSendType = 1;
    }
    this.setLockedValues(this.invoice.Customer);
    this.getInvoicePaymentTerm();
    this.getVatList();

  }

  contactChange(contactId: number) {
    for (var i = 0; i < this.invoice.Customer.Contacts.length; i++) {
      var contact = this.invoice.Customer.Contacts[i];

      if (contactId == contact.Id) {
        this.invoice.Reference.Name = contact.Name;
        this.invoice.Reference.Phone = contact.Phone;
        this.invoice.Reference.Email = contact.Email;
        //console.log(this.invoice.Reference);
      }
    }
  }

  addSavedContact(contact: IContact, companyId: number) {
    this.invoice.Customer.Contacts.push(contact);
    this.invoice.Reference = contact;
  }

  getCustomer(id) {
    for (var i = 0; i < this.companies.length; i++) {
      if (this.companies[i].Id == this.invoice.Customer.Id) {
        //console.log(this.companies[i]);
        return JSON.parse(JSON.stringify(this.companies[i]));
      }
    }
  }

  updateCustomerGlnNumber(glnNumber: string, customerID: number) {
    var customer = this.getCustomer(customerID) as ICompany;
    customer.GlnNumber = glnNumber;
    this.companyService.updateCompany(customer)
      .subscribe(response => this.onCustomerUpdated(response), error => alert('Ett fel inträffade när GLN skulle sparas!'));
  }

  onCustomerUpdated(response: any) {
    alert('Kundens GLN är uppdaterat!');
  } 

  updateCustomer(company: ICompany) {
    
    for (var i = 0; i < this.companies.length; i++) {

      var currentCompany = this.companies[i];

      if (currentCompany.Id == company.Id) {
        this.UpdateCompany(company, currentCompany);
        this.UpdateCompany(company, this.invoice.Customer);
        return;
      }
    }
  }
  
  uploadDocument(event: any, documentList: any) {

    // Created new file placeholder
    let file = {
      FileType: {} as IFileType
    } as IFile;
    // Give it an id
    file.Guid = this.newGuid();
    file.FileType.TechnicalName = "AttachedInvoiceDocument"; 


    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let f: File = fileList[0];
      file.Name = f.name;

      this.invoiceService.uploadDocument(f, file.Guid, 'AttachedInvoiceDocument')
        .subscribe(
          response => this.onAttachedDocumentSaved(response, file),
          error => console.log(error));
    }
  }

  onAttachedDocumentSaved(response: any, file: IFile) {
    file.Id = response.Id;
    file.Url = response.Url;
    file.FileSize = response.FileSize;
    file.FileType = response.FileType;

    var fileExtension = file.Name.split('.').pop();
    debugger
    if (fileExtension != 'pdf') {
      alert("Du får enbart ladda upp pdf-filer")
    }
    else { 
      //Must be smaller than 5 Mb to be added
      if (file.FileSize < 5242880) {
        this.invoice.Documents.push(file);
      }
      else {
        alert("Din fil måste vara mindre än 5 Mb")
      }
    }

    this.checkTotalAttachmentSize(this.invoice.Documents);

  }

  checkTotalAttachmentSize(documentList: Array<IFile>) { 

    this.totalFileSize = 0;
    this.attachedFilesCount = 0;

    for (var i = 0; i < documentList.length; i++) {

      if (documentList[i].FileType.TechnicalName == 'AttachedInvoiceDocument') {
        this.totalFileSize = this.totalFileSize += documentList[i].FileSize;

        this.attachedFilesCount = this.attachedFilesCount + 1;
      }

    }
  }

  checkFileExtension(documentList: Array<IFile>) {
    for (var i = 0; i < documentList.length; i++) {

      if (documentList[i].FileType.TechnicalName == 'AttachedInvoiceDocument') {
        var fileName = documentList[i].Name;
        var fileExtension = fileName.split('.').pop();
      }

      alert(fileExtension);
    }
  }

  deleteDocumentFromList(file: IFile, documentList: Array<IFile>) {

    if (confirm("Vill du radera filen " + file.Name + " ?")) {
      for (var i = 0; i < documentList.length; i++) {
        if (documentList[i].Guid == file.Guid) {
          documentList.splice(i, 1);
        }
      }
    }

    this.checkTotalAttachmentSize(documentList);
  }

  showFile(file: IFile) {
    window.open(file.Url); 
  }

  addDeduction() {
    this.invoice.Deductions.push({ Attachment: { Guid: this.newGuid() }, VatPercentage: "25", Description: '', IncludeInInvoice: "true", TravelExpense: "false" });
  }

  addSalaryShare() {

    var newShare = {
      Guid: this.newGuid(),
      PersonalNumber: '',
      Share: undefined,
      ShareType: { 
        Id: undefined
      },
      SalaryReciever: {
        Id: undefined
      }
    };

    this.searchAccountValue = "";
    this.personalOrOrgNumberValid = false;
    
    this.invoice.SalaryShares.push(newShare);    
  }

  addInvoiceDocument() {

    var newDocument = {
      Guid: this.newGuid(),
      FileType: {
        TechnicalName: 'AttachedInvoiceDocument'
      },
    };

    this.searchAccountValue = "";
    this.personalOrOrgNumberValid = false;

    this.invoice.SalaryShares.push(newDocument);
  }

  openAddUserAccountModal(salaryShare) {

    this.config.data.contact.IDNumber = this.searchAccountValue;

    let dialogRef = this.dialog.open(AddMissingRecieverDialogComponent, this.config);

    dialogRef.afterClosed().subscribe(accountSetting => {
      this.SearchAccount(this.searchAccountValue, salaryShare)
    });

  }

  SearchAccount(term: string, salaryShare: any) {
    
    if (term == null || term == "") {
      salaryShare.SalaryReciever = null;
    }

    this.userService.getUserByPersonalOrOrgNumber(term)
      .subscribe(
        response => this.onSearchAccountCompleted(response, salaryShare),
        error => console.log(error));
  }

  onSearchAccountCompleted(response: IAccountSettings, salaryShare: any) {


    salaryShare.SalaryReciever = response;


    // Check if user is not allready in list
    //if (response.Id > 0) {

    //  debugger

    //  for (var i = 0; i < this.invoice.SalaryShares.length; i++) {
    //    var share = this.invoice.SalaryShares[i];

    //    if (share.SalaryReciever.Id > 0 && share.SalaryReciever.Id == response.Id) {
    //      salaryShare.Delete = true;
    //      alert('Användaren finns redan i listan!');
    //    }
    //    else {
    //      salaryShare.SalaryReciever = response;
    //    }
    //  }


    //  for (var i = 0; i < this.invoice.SalaryShares.length; i++) {
    //    var share = this.invoice.SalaryShares[i];

    //    if (share.Delete) {
    //      this.deleteShare(i);
    //    }
    //  }
    //}
  }

  deleteShare(shareIndex: number) {
    this.invoice.SalaryShares.splice(shareIndex, 1);
  }

  invoiceSendTypeOnChange(sendTypeId: number) {

  }

  UpdateCompany(from: any, to: any) {
    to.Name = from.Name;
    to.Address = from.Address;
    to.Address2 = from.Address2;
    to.Co = from.Co;
    to.Zip = from.Zip;
    to.City = from.City;
    to.Phone = from.Phone;
    to.Email = from.Email;
    to.InvoiceEmail = from.InvoiceEmail;
    to.OrgNumber = from.OrgNumber;
    to.Reference = from.Reference;
    to.IsCompany = from.IsCompany;
    to.GlnNumber = from.GlnNumber;
    to.Country = from.Country;
    to.InvoiceSendType = from.InvoiceSendType;

    if (from.InvoiceSendType > 0) {
      this.invoice.InvoiceSendType = from.InvoiceSendType;
    }
  } 

  openLink(link: string) {
    window.open(link, '_blank');
  }

  saveDraft(invoice: any) {
    this.calculatePreliminaryPayout();

    if (this.invoice.Currency.Name == 'SEK' && this.sumNetExpenses > this.preliminaryPayout && this.invoice.Status.TechnicalName != 'Draft' && !this.isAdmin) {
      alert("Summan av dina utlägg/ersättningar är större än den summa du har fakturerat (minus våra avgifter). Du kan inte göra avdrag som är större än " + this.preliminaryPayout + " SEK.");
    }
    else if (!this.pensionValidates) {
      alert("Din avsättning till pension (inkl skatt) övergår det fakturerade beloppet efter våra avgifter.");
    }
    else {
      if (!this.canUpdateGeneral()) {

        this.invoiceService.updateInvoice(invoice, 'Fakturan uppdaterad!', undefined)
          .subscribe(
            response => this.onDraftSaved(response),
            error => console.log(error));
      }
      else if (this.canUpdateInvoiceDraft()) {

        this.isLoading = true;
        if (this.invoice.Status == null) {
          invoice.Status = { Name: 'Utkast' };
        }

        if (invoice.Id == undefined || invoice.Id == 0) {
          this.invoiceService.createInvoice(invoice, 'Fakturan skapad!', undefined)
            .subscribe(
              response => this.onDraftSaved(response),
              error => console.log(error));
        }
        else {
          this.invoiceService.updateInvoice(invoice, 'Fakturan uppdaterad!', undefined)
            .subscribe(
              response => this.onDraftSaved(response),
              error => console.log(error));
        }

      }
      else {
        this.submitType = 1;
        this.showValidationSummary = true;
      }
    }
  }

  send(invoice: any) {

    this.calculatePreliminaryPayout();


    if ((this.invoice.CreditInvoiceFor == null || this.invoice.CreditInvoiceFor == 0) && this.invoice.Currency.Name == 'SEK' && this.sumNetExpenses > this.preliminaryPayout && !this.isAdmin) {
      alert("Summan av dina utlägg/ersättningar är större än den summa du har fakturerat (minus våra avgifter). Du kan inte göra avdrag som är större än " + this.preliminaryPayout + " SEK.");

    }
    else {
      if (this.canUpdateInvoice()) {
        this.isLoading = true;

        if (this.invoice.Status.TechnicalName == "Draft") {
          invoice.Status = { Name: 'Granskas' };
        }

        if (invoice.Id == undefined || invoice.Id == 0) {
          this.invoiceService.createInvoice(invoice, 'Fakturan skapad!', undefined)
            .subscribe(
              response => this.onDraftSaved(response),
              error => console.log(error));
        }
        else {

          this.invoiceService.updateInvoice(invoice, 'Fakturan uppdaterad!', undefined)
            .subscribe(
              response => this.onDraftSaved(response),
              error => console.log(error));
        }
      }
      else {
        this.submitType = 2;
        this.showValidationSummary = true;
      }
    }

    if (this.rbExpressPayoutYes.checked == true) {
      this.invoice.ExpressPayOut = true;
    }
    else {
      this.invoice.ExpressPayout = false;
    }

  }

  config = {
    disableClose: false,
    panelClass: 'full-screen-modal',
    hasBackdrop: true,
    backdropClass: '',
    width: '500px',
    maxWidth: '100vw',
    height: '100%',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    data: {
      invoiceItemGroup: {},
      customerTemplate: {},
      customer: { TimeReportMandatory: this.invoice.Customer.TimeReportMandatory},
      contact: { IDNumber: null },
      companyId: 0,
      status: {},
      deduction: {} as IDeduction,
      allowance: {} as IAllowance,
      customerCountryName: null,
    },
    dataTemplate: {
      Id: 0,
      Country: { Id: 1 },
      Type: {},
      HoursWorked: undefined,
      StartDate: undefined,
      EndDate: undefined,
      Comment: undefined,
      InvoiceId: 0
    }
  };

  configSpinner = {
    disableClose: true,
    panelClass: 'full-screen-modal',
    hasBackdrop: true,
    backdropClass: '',
    width: '400px',
    minHeight: '200px',
    position: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
  };

}
