import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared';
import { StaticRoutingModule } from './static-routing.module';
import { AboutComponent } from './about/about.component';
import { FeaturesComponent } from './features/features.component';
import { InvoiceToolComponent } from './invoice-tool/invoice-tool.component';
import { CombinedInvoiceToolComponent } from './invoice-tool/combined-invoice-tool.component';
import { LoginComponent } from './login/login.component';
import { CreateAccountComponent } from './create-account/create-account.component';
import { EditCustomerDialogComponent } from '../shared/customer/EditCustomerDialog.Component';
import { EditContactDialogComponent } from '../shared/contact/EditContactDialog.Component';
import { ModalSpinnerDialogComponent } from '../shared/modal-spinner/ModalSpinnerDialog.Component';
import { InvoiceToolCalendarDialogComponent } from './invoice-tool/invoice-tool.calendarDialog.Component';
import { InvoiceAdminDialogComponent } from '../mypages/invoices/invoice-admin.Dialog.Component';
import { InvoicePreviewDialogComponent } from './invoice-tool/invoice-tool.invoicePreviewDialog.Component';
import { EditDeductionDialogComponent } from './invoice-tool/deduction/edit-deduction-Dialog.Component';
import { EditAllowanceDialogComponent } from './invoice-tool/allowance/edit-allowance-Dialog.Component';
import { AddMissingRecieverDialogComponent } from './invoice-tool/addreciever/add-missing-reciever-Dialog.Component';
import { ResetPasswordComponent} from './resetpassword/resetpassword.component';
import { InvoiceSummaryComponent } from './invoice-summary/invoice-summary.component';
import { CombinedInvoiceSummaryComponent } from './combined-invoice-summary/combined-invoice-summary.component';
import { ContactComponent } from './contact/contact.component';
import { UsersComponent } from './users/users.component';
import { UserEditComponent } from './users/edit-user/user-edit.component';
import { EditTaxAdjustmentDialogComponent } from './../settings/settings/tax-adjustment/edit-tax-adjustment-Dialog.Component';
import { TransactionDialogComponent } from '../shared/transaction/TransactionDialog.Component';
import { TransactionLogsComponent, TransactionLogMemberDialog, TransactionLogAdminDialog } from './transaction-logs/transaction-logs.component';
import { BalanceAccountComponent } from './balance-account/balance-account.component';
import { MessagesDialogComponent } from '../shared/messages/messages-dialog-component';
import { ConfirmDialogComponent } from '../shared/confirm-dialog/ConfirmDialog';
import { LoginDialogComponent } from '../shared/login-dialog/LoginDialog.Component';

//Angular Material Components
import { MatTableModule } from '@angular/material';
import { MatSortModule } from '@angular/material';
import { MatPaginatorModule } from '@angular/material';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material';
import { MatButtonModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRippleModule } from '@angular/material/core';
import { InvoiceWorkCertificateDialogComponent } from '../mypages/invoices/invoice-work-cert.Dialog.Component';
import { InvoiceItemDialogComponent } from './invoice-tool/invoice-items/invoice-item-Dialog.Component';
import { MatBadgeModule } from '@angular/material/badge';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AttachmentOfSalaryDialogComponent } from '../settings/settings/attachment-of-salary/attachment-of-salary-Dialog.Component';
//import { PayoutReportsComponent, PayoutReportsMemberDialog } from '../admin/payout-reports/payout-reports.component';

@NgModule({
  imports: [
    FormsModule, ReactiveFormsModule, SharedModule, MatRippleModule, StaticRoutingModule, MatDatepickerModule, MatNativeDateModule, MatTableModule, MatPaginatorModule, MatSortModule, MatCheckboxModule, MatCheckboxModule, MatButtonModule, MatInputModule, MatAutocompleteModule, MatDatepickerModule, MatFormFieldModule, MatRadioModule, MatSelectModule, MatSliderModule, MatSlideToggleModule, MatMenuModule, MatSidenavModule, MatToolbarModule, MatListModule, MatGridListModule, MatCardModule, MatStepperModule, MatTabsModule, MatExpansionModule, MatButtonToggleModule, MatChipsModule, MatIconModule, MatProgressSpinnerModule, MatProgressBarModule, MatDialogModule, MatTooltipModule, MatSnackBarModule, MatTableModule, MatSortModule, MatPaginatorModule, MatBadgeModule],
    declarations: [
        TransactionLogsComponent,
        BalanceAccountComponent,
        AboutComponent,
        UsersComponent,
        UserEditComponent,
        FeaturesComponent,
        InvoiceToolComponent,
        CombinedInvoiceToolComponent,
        LoginComponent,
        CreateAccountComponent,
        EditContactDialogComponent,
        ModalSpinnerDialogComponent,
        ResetPasswordComponent,
        EditCustomerDialogComponent,
        InvoiceToolCalendarDialogComponent,
        InvoicePreviewDialogComponent,
        EditDeductionDialogComponent,
        EditAllowanceDialogComponent,
        AddMissingRecieverDialogComponent,
        InvoiceAdminDialogComponent,
        InvoiceSummaryComponent,
        CombinedInvoiceSummaryComponent,
        ContactComponent,
        EditTaxAdjustmentDialogComponent,
        AttachmentOfSalaryDialogComponent,
        InvoiceWorkCertificateDialogComponent,
        TransactionDialogComponent,
        TransactionLogMemberDialog,
        TransactionLogAdminDialog,
        //PayoutReportsComponent,
        //PayoutReportsMemberDialog,
        InvoiceItemDialogComponent,
      MessagesDialogComponent,
      LoginDialogComponent,
      ConfirmDialogComponent
    ],

    entryComponents: [
        EditCustomerDialogComponent,
        InvoiceToolCalendarDialogComponent,
        InvoiceAdminDialogComponent,
        EditDeductionDialogComponent,
        EditAllowanceDialogComponent,
        AddMissingRecieverDialogComponent,
        InvoicePreviewDialogComponent,
        EditContactDialogComponent,
        ModalSpinnerDialogComponent,
        InvoiceSummaryComponent,
        CombinedInvoiceSummaryComponent,
        EditTaxAdjustmentDialogComponent,
        AttachmentOfSalaryDialogComponent,
        InvoiceWorkCertificateDialogComponent,
        TransactionDialogComponent,
        TransactionLogsComponent,
        TransactionLogMemberDialog,
        BalanceAccountComponent,
        //PayoutReportsComponent,
        //PayoutReportsMemberDialog,
        TransactionLogAdminDialog,
        InvoiceItemDialogComponent,
      MessagesDialogComponent,
      LoginDialogComponent,
      ConfirmDialogComponent
    ]
})
export class StaticModule {}
