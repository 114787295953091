
<h2 mat-dialog-title>Redigera kontakt</h2>
<mat-dialog-content>

<form>
        
    <mat-form-field  style="width:100%;">
        <input matInput type="text" pattern="[a-öA-Ö_&-]+([ ]?[a-öA-Ö_&-]+)*" [(ngModel)]='data.contact.Name' placeholder="Namn" required name="name">
    </mat-form-field>
    <mat-form-field  style="width:100%;">
        <input matInput type="email" pattern="[A-Öa-ö0-9._%+-]+@[A-Öa-ö0-9.-]+.[A-Öa-ö]{1,63}$" [(ngModel)]='data.contact.Email' placeholder="Epost" required name="Email">
    </mat-form-field>    
    <mat-form-field  style="width:100%;">
        <input matInput type="text" [(ngModel)]='data.contact.Phone' placeholder="Telefon" required name="Phone">
    </mat-form-field>
    
</form>

</mat-dialog-content>

<div mat-dialog-actions align="end">
  <button mat-stroked-button (click)="cancel()">Stäng</button>
  <button mat-raised-button color="accent" (click)="save(data.contact)">
      &nbsp;Spara&nbsp;
  </button>
<p style="width: 100%; font-size: 80%; color: red; margin-top: 15px;">
{{ErrorMessageDesc}}
</p>
  
</div>


