import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, NativeDateAdapter } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { UserService } from '../../../core/services/UserService';
import { IAccountSettings } from '../../../core/interfaces/user/IAccountSettings';
import { IUser } from '../../../core/interfaces/user/IUser';

@Component({
    selector: 'add-missing-reciever-dialog',
  templateUrl: 'add-missing-reciever-Dialog.Component.html'
})

export class AddMissingRecieverDialogComponent {

  private _formValid = false;
  accountSettings: IAccountSettings = {} as IAccountSettings;
  IsCompanyLocal: any;

    constructor(
      public dialogRef: MatDialogRef<AddMissingRecieverDialogComponent>,
      private userService: UserService  ,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) 
    {}

  ngOnInit() {

    this.accountSettings.PersonalNumber = this.data.contact.IDNumber;
    this.accountSettings.OrgNumber = this.data.contact.IDNumber;
  }

  onSubmit() {
    this.registerAccount();
  }

  registerAccount() {
    var user = { UserName: this.accountSettings.Email, Email: this.accountSettings.Email, EmailTemplate: "" } as IUser;

    user.AccountSettings = this.accountSettings;
    user.EmailTemplate = "WelcomeUserAddedBy3P";
    user.FirstName = this.accountSettings.FirstName;
    user.LastName = this.accountSettings.LastName;

    if (this.IsCompanyLocal) {
      user.AccountSettings.IsCompany = true;
      user.AccountSettings.PersonalNumber = null;
    }
    else {
      user.AccountSettings.IsCompany = false;
      user.AccountSettings.OrgNumber = null;
    }

    this.userService.addUser(user)
      .subscribe(value => this.accountRegistered(user),
        error => console.log(error)
      );
  }

  accountRegistered(response: any) {
    this.dialogRef.close(response);
  }

}
