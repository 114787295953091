import { Routes } from '@angular/router';
import { AuthGuardService } from '@app/core';
import { TransactionLogsComponent } from './transaction-logs/transaction-logs.component';
import { BalanceAccountComponent } from './balance-account/balance-account.component';
var ɵ0 = {
    title: 'Ekonomi'
}, ɵ1 = {
    title: 'Kontosaldo'
};
var routes = [
    //{ path: 'about', component: AboutComponent, data: { title: 'About..' } },
    //{
    //  path: 'features',
    //  component: FeaturesComponent,
    //  data: { title: 'Features' }
    //}
    {
        path: 'transactions',
        component: TransactionLogsComponent,
        data: ɵ0,
        canActivate: [AuthGuardService] // Only logged in can access this route 
    },
    {
        path: 'balance-account',
        component: BalanceAccountComponent,
        data: ɵ1,
        canActivate: [AuthGuardService] // Only logged in can access this route 
    },
];
var StaticRoutingModule = /** @class */ (function () {
    function StaticRoutingModule() {
    }
    return StaticRoutingModule;
}());
export { StaticRoutingModule };
export { ɵ0, ɵ1 };
