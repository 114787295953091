<style>
  .mat-cell, .mat-header-cell {
    padding: 10px;
  }

  .nomarginbutton {
    margin: 0 !important;
  }

  .fix-static-bug {
    width: 100% !important;
    position: static !important;
  }

  h3 {
    margin-bottom: 25px;
  }

  .card-margin {
    margin-bottom: 15px;
  }
</style>

<div class="fix-static-bug">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 entry">
        <div class="title">
          <h3>Kontoinställningar</h3>
        </div>


        <div class="row" *ngIf="appId == 1 && !user?.AccountSettings?.IsCompany">
          <div class="col-md-12 entry backgroundcolor">
          <!-- ********************* -->
          <!-- Private user settings -->
          <!-- ********************* -->
          <form name="form" *ngIf="!isLoading" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
            <mat-card class="card-margin">
              <div class="row" style="margin-bottom: 15px;">

                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput required [ngModel]="user?.AccountSettings.FirstName" (ngModelChange)="user.AccountSettings.FirstName = $event" placeholder="Förnamn" id="firstname" name="firstname">
                  </mat-form-field>
                </div>
                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput required [ngModel]="user?.AccountSettings.LastName" (ngModelChange)="user.AccountSettings.LastName = $event" placeholder="Efternamn" id="lastname" name="lastname">
                  </mat-form-field>
                </div>
                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.Phone" (ngModelChange)="user.AccountSettings.Phone = $event" placeholder="Telefon" id="telephone" name="telephone">
                  </mat-form-field>
                </div>
                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.Email" (ngModelChange)="user.AccountSettings.Email = $event" placeholder="E-post" id="email" name="email">
                  </mat-form-field>
                </div>
                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput required [ngModel]="user?.AccountSettings.PersonalNumber" (ngModelChange)="user.AccountSettings.PersonalNumber = $event" ngModel pattern="[0-9]{12}" maxlength="12" placeholder="Personnummer / samordningsnummer" id="PersonalNumber" name="PersonalNumber">
                    <mat-hint>12 siffror utan bindestreck</mat-hint>
                  </mat-form-field>
                </div>
                <div class="col-sm-6">
                  <mat-checkbox matInput [ngModel]="user?.AccountSettings.HasCoordinationNumber" ngModel (change)="changeCoordinationNumberValue($event)" id="hascono" name="hascono">Jag har enbart samordningsnummer,<br /> INGET svenskt personnummer</mat-checkbox>
                </div>
                <div *ngIf="user.AccountSettings.HasCoordinationNumber" class="col-sm-6">
                </div>
                <div *ngIf="user.AccountSettings.HasCoordinationNumber" class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never" ngModel>
                    <input matInput [ngModel]="user?.AccountSettings.BirthYear" (ngModelChange)="user.AccountSettings.BirthYear = $event" ngModel pattern="[0-9]{8}" maxlength="8" placeholder="ÅÅÅÅMMDD" id="BirthYear" name="BirthYear">
                    <mat-hint>Ange födelsedatum (8 siffror)</mat-hint>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
            <mat-card class="card-margin">
              <div class="row" style="margin-bottom: 15px;">
                <!-- Adress -->
                <div class="col-12">
                  <h4>Adress</h4>
                </div>
                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput required [ngModel]="user?.AccountSettings.Address" (ngModelChange)="user.AccountSettings.Address = $event" placeholder="Folkbokföringsadress" id="address" name="address">
                  </mat-form-field>
                </div>
                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.Co" (ngModelChange)="user.AccountSettings.Co = $event" placeholder="c/o" id="coaddress" name="coaddress">
                  </mat-form-field>
                </div>
                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput required [ngModel]="user?.AccountSettings.Zip" (ngModelChange)="user.AccountSettings.Zip = $event" placeholder="Postnummer" id="zip" name="zip">
                  </mat-form-field>
                </div>
                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput required [ngModel]="user?.AccountSettings.City" (ngModelChange)="user.AccountSettings.City = $event" placeholder="Ort" id="city" name="city">
                  </mat-form-field>
                </div>
                <div class="col-sm-12 col-md-6">
                  <mat-form-field>
                    <mat-select name="companies" placeholder="Kommun" [(ngModel)]="user.AccountSettings.Municipality.Id" (ngModelChange)="municipalityChange($event)" required>
                      <mat-option *ngFor="let c of municipalities" [value]="c.Id">
                        {{ c.Name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <span style="margin-left:25px;">Skatt: {{user.AccountSettings.Municipality.Tax * 100 | number:'1.2-2'}} %</span>
                </div>
              </div>
              <!-- Utdelningsadress -->

              <div class="row" style="margin-bottom: 15px;">
                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.DeliveryAddress" (ngModelChange)="user.AccountSettings.DeliveryAddress = $event" placeholder="Utdelningsadress (om annan än ovan)" id="levaddress" name="levaddress">
                  </mat-form-field>
                </div>
                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.DeliveryCo" (ngModelChange)="user.AccountSettings.DeliveryCo = $event" placeholder="c/o" id="delcoaddress" name="delcoaddress">
                  </mat-form-field>
                </div>
                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.DeliveryZip" (ngModelChange)="user.AccountSettings.DeliveryZip = $event" placeholder="Postnummer" id="levzip" name="levzip">
                  </mat-form-field>
                </div>
                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.DeliveryCity" (ngModelChange)="user.AccountSettings.DeliveryCity = $event" placeholder="Ort" id="levcity" name="levcity" #levcity="ngModel">
                  </mat-form-field>
                </div>
              </div>
            </mat-card>
            <mat-card class="card-margin">
              <div class="row" style="margin-bottom: 15px;">
                <div class="col-12">
                  <h4>Bank</h4>
                </div>
                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput required [ngModel]="user?.AccountSettings.BankName" (ngModelChange)="user.AccountSettings.BankName = $event" placeholder="Banknamn" id="BankName" name="BankName">
                  </mat-form-field>
                </div>
                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput required [ngModel]="user?.AccountSettings.ClearingNumber" (ngModelChange)="user.AccountSettings.ClearingNumber = $event" placeholder="Clearingnummer" id="ClearingNumber" name="ClearingNumber">
                  </mat-form-field>
                </div>
                <div class="col-sm-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput required [ngModel]="user?.AccountSettings.AccountNumber" (ngModelChange)="user.AccountSettings.AccountNumber = $event" placeholder="Kontonummer" id="AccountNumber" name="AccountNumber">
                  </mat-form-field>
                </div>

              </div>
            </mat-card>

            <mat-card class="card-margin">
              <div class="row" style="margin-bottom: 20px;">
                <div class="col-12">
                  <h4>Övrigt</h4>
                </div>
                <div class="col-sm-12 col-md-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <mat-select placeholder="Notifikationer" [(ngModel)]="selectedNotificationTypes" (ngModelChange)="notificationSettingsChange($event)" [ngModelOptions]="{standalone: true}" multiple>
                      <mat-option *ngFor="let s of user.AccountSettings.NotificationSettings" [value]="s.Type.Id">
                        {{ s.Type.Name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <span style="font-size: 80%">Här kan du bestämma vilka mail du vill få. Klicka ur de du inte vill ha.</span>
                </div>
              </div>
              <div class="row" style="margin-bottom: 15px;">
                <div class="col-sm-12 col-md-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput [ngModel]="user?.AccountSettings.DiscountCode" (ngModelChange)="user.AccountSettings.DiscountCode = $event" placeholder="Kampanjkod" id="discountcode" name="discountcode" disabled>
                  </mat-form-field>
                </div>
              </div>
            </mat-card>

            <mat-card class="card-margin">
              <!--Tjänstepension-->
              <div class="row" style="margin-bottom: 20px;">
                <div class="col-12">
                  <h4>Avsättning till tjänstepension</h4>
                </div>
                <div class="col-lg-12" style="margin-bottom: 15px;">
                  <span style="font-size: 80%;">
                    Du kan göra avsättningar till din tjänstepension. Du kan i dina inställningar välja en generell avsättning, som du kan justera innan du skickar din faktura om så önskas.
                  </span>
                </div>  
                <div class="col-lg-6">
                  <mat-form-field style="width: 100%" floatPlaceholder="never">
                    <input matInput
                           [ngModel]="user?.AccountSettings?.PensionSavingsSettings?.Amount"
                           (ngModelChange)="user.AccountSettings.PensionSavingsSettings.Amount = $event"
                           placeholder="Andel"
                           type="number"
                           id="PensionAmount"
                           name="PensionAmount">
                    <!--<input matInput required [ngModel]="user?.AccountSettings?.PensionSavingsSettings?.Amount" (ngModelChange)="user?.AccountSettings?.PensionSavingsSettings?.Amount = $event" placeholder="Procent eller kronor" id="PensionAmount" name="PensionAmount">-->
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <mat-form-field style="width: 100%;">
                    <select [ngModel]="user.AccountSettings.PensionSavingsSettings.Unit.Id"
                            (ngModelChange)="pensionSavingsTypeChanged($event)"
                            matNativeControl
                            [ngModelOptions]="{standalone: true}">
                      <option value="1">Procent</option>
                      <option value="2">SEK</option>
                    </select>
                  </mat-form-field>
                </div>
                <div class="col-lg-6">
                  <div>Val av tjänstepensionsföretag:</div>
                  <mat-form-field style="width: 100%;">
                    <select [ngModel]="user?.AccountSettings?.PensionSavingsSettings?.PensionSavingsCompany?.Id"
                            (ngModelChange)="pensionSavingsCompanyChanged($event)"
                            matNativeControl
                            [ngModelOptions]="{standalone: true}">
                      <option value="1">SPP</option>
                      <option value="2">Avanza</option>
                    </select>
                  </mat-form-field>
                </div>
                <div class="col-lg-12">
                  <span style="font-size: 80%">
                    Löneväxling passar inte alla. Din allmänna pension minskar om din bruttolön efter löneväxling blir lägre än 599 601 kronor (8,07 inkomstbasbelopp 2023). Om din lön blir mindre än så kan även din sjukpenning, föräldrapenning och andra socialförsäkringsförmåner påverkas.<br />Om du vill kan du välja var vi ska sätta in din tjänstepension. Om du inte gör ett aktivt val av tjänstepensionsföretag så sätter vi in pensionsavsättningen hos SPP.
                  </span>
                </div>

              </div>
            </mat-card>



            <mat-card class="card-margin">
              <!-- Skattejämkningar -->
              <div class="row">

                <div class="col-12">
                  <h4>Skattejämkningar / frivillig ändring av skatt</h4>
                </div>

                <!--Visar tabell för skattejämkningar-->
                <div class="col-12" style="margin: 0 0 20px 0;" *ngIf="user?.AccountSettings.TaxAdjustments.length > 0">
                  <mat-table [dataSource]="dataSource">

                    <ng-container matColumnDef="type">
                      <mat-header-cell *matHeaderCellDef>Typ</mat-header-cell>
                      <mat-cell *matCellDef="let row">{{ row?.TaxAdjustmentType?.Name }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="startdate">
                      <mat-header-cell *matHeaderCellDef>Startdatum</mat-header-cell>
                      <mat-cell *matCellDef="let row">{{ row?.DateStart | date: 'yyyy-MM-dd' }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="enddate">
                      <mat-header-cell *matHeaderCellDef>Slutdatum</mat-header-cell>
                      <mat-cell *matCellDef="let row">{{ row?.DateEnd | date: 'yyyy-MM-dd' }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="maxamount">
                      <mat-header-cell *matHeaderCellDef>Maxsumma</mat-header-cell>
                      <mat-cell *matCellDef="let row">{{ row?.MaxAmount }}</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="temptax">
                      <mat-header-cell *matHeaderCellDef>Tillfällig skatt</mat-header-cell>
                      <mat-cell *matCellDef="let row">{{ row?.TemporaryTax *100 }} %</mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="attachment">
                      <mat-header-cell *matHeaderCellDef>Uppladdad fil</mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <a *ngIf="row?.Attachment?.Id != null && row?.TaxAdjustmentType.Id != 3" mat-stroked-button color="secondary" (click)="showFile(row.Attachment)"><mat-icon>open_in_new</mat-icon></a>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="admin">
                      <mat-header-cell *matHeaderCellDef>Admin</mat-header-cell>
                      <mat-cell *matCellDef="let row">
                        <a mat-stroked-button mat-raised-button (click)="deleteTaxAdjustment(row)" style="margin-top: 8px;margin-right:20px;color:red;">radera</a>

                      </mat-cell>
                    </ng-container>

                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: displayedColumns;">
                    </mat-row>
                  </mat-table>
                  <!-- Slut tabell för skattejämkning-->
                </div>

                <div class="col-12">
                  <!--Knapp för skattejämkning-->
                  <div class="col-6"></div>
                  <a class="col-6 nomarginbutton" mat-stroked-button (click)="addTaxAdjustment()"><mat-icon>add</mat-icon> &nbsp; Lägg till skattejämkning/ändra skatt  &nbsp;  </a>
                </div>
              </div>
            </mat-card>

            <div class="col-sm-12">
              <div class="col-12">
                <button class="col-3" color="accent" mat-flat-button [disabled]="isLoading || !f.valid" style="float:right;">Spara</button>
              </div>
              <div class="col-sm-12" style="margin-bottom:15px;">
                <div *ngIf="!saveValidates()" style="color:red;"><div>{{validationErrorMessage}}</div></div>
              </div>
            </div>

          </form>
          </div>
        </div>

    <!-- ********************* -->
    <!-- Company user settings -->
    <!-- ********************* -->

        <div class="row" *ngIf="appId == 2 || user.AccountSettings.IsCompany">
          <div class="col-lg-12 entry backgroundcolor">
            <mat-card>
              <br />
              <form name="form" *ngIf="!isLoading" (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
                <div class="row" style="margin-bottom: 15px;">

                  <div class="col-lg-12">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input required matInput [ngModel]="user?.AccountSettings.CompanyName" (ngModelChange)="user.AccountSettings.CompanyName = $event" placeholder="Företagets namn" id="companyname" name="companyname">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput [ngModel]="user?.AccountSettings.Phone" (ngModelChange)="user.AccountSettings.Phone = $event" placeholder="Telefon" id="telephone" name="telephone">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput [ngModel]="user?.AccountSettings.Email" (ngModelChange)="user.AccountSettings.Email = $event" placeholder="E-post" id="email" name="email">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6" *ngIf="user.AccountSettings.Country.Id == null || user.AccountSettings.Country.Name == 'Sverige'">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput [ngModel]="user?.AccountSettings.OrgNumber" (ngModelChange)="user.AccountSettings.OrgNumber = $event" ngModel placeholder="Organisationsnr *" id="organisationsnr" name="Organisationsnr">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6" *ngIf="user.AccountSettings.Country.Id != null && user.AccountSettings.Country.Name != 'Sverige'">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput [ngModel]="user?.AccountSettings.Vat" (ngModelChange)="user.AccountSettings.Vat = $event && user.AccountSettings.OrgNumber = $event" ngModel placeholder="VAT" id="VAT" name="VAT">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6">
                    <mat-select name="country" placeholder="Land" id="country" [(ngModel)]="user.AccountSettings.Country.Id" (ngModelChange)="user.AccountSettings.Country.Id = $event; getCountryName(user.AccountSettings.Country.Id);" [ngModelOptions]="{standalone: true}" required>
                      <mat-option *ngFor="let c of countries" [value]="c.Id">
                        {{ c.Name }}
                      </mat-option>
                    </mat-select>
                  </div>

                  <!-- Kontaktperson -->
                  <div class="col-lg-12" style="margin: 20px 0">
                    <h4>Kontaktperson</h4>
                  </div>

                  <div class="col-sm-6">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput required [ngModel]="user?.AccountSettings.FirstName" (ngModelChange)="user.AccountSettings.FirstName = $event" placeholder="Förnamn" id="firstname" name="firstname">
                    </mat-form-field>
                  </div>
                  <div class="col-sm-6">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput required [ngModel]="user?.AccountSettings.LastName" (ngModelChange)="user.AccountSettings.LastName = $event" placeholder="Efternamn" id="lastname" name="lastname">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput [ngModel]="user?.AccountSettings.ContactPhone" (ngModelChange)="user.AccountSettings.ContactPhone = $event" placeholder="Telefon till kontaktperson" id="telephoneftgcontact" name="telephonecontact">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput [ngModel]="user?.AccountSettings.ContactEmail" (ngModelChange)="user.AccountSettings.ContactEmail = $event" placeholder="E-post till kontaktperson" id="emailftgcontact" name="emailcontact">
                    </mat-form-field>
                  </div>

                  <!-- Fakturadress -->
                  <div class="col-lg-12" style="margin: 20px 0">
                    <h4>Företagsadress</h4>
                  </div>

                  <div class="col-lg-12">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput [ngModel]="user?.AccountSettings.Address" (ngModelChange)="user.AccountSettings.Address = $event" placeholder="Företagsadress (gatuadress)" id="address" name="address">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-12">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput [ngModel]="user?.AccountSettings.Co" (ngModelChange)="user.AccountSettings.Co = $event" placeholder="Företagsadress 2 (t ex c/o)" id="coaddress" name="coaddress">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput [ngModel]="user?.AccountSettings.Zip" (ngModelChange)="user.AccountSettings.Zip = $event" placeholder="Postnummer" id="zip" name="zip">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput [ngModel]="user?.AccountSettings.City" (ngModelChange)="user.AccountSettings.City = $event" placeholder="Ort" id="city" name="city">
                    </mat-form-field>
                  </div>

                  <!-- Leveransadress -->

                  <div class="col-lg-12" style="margin: 20px 0">
                    <h4>Särskild fakturaadress, om annan än ovan</h4>
                  </div>
                  <div class="col-lg-12">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput [ngModel]="user?.AccountSettings.DeliveryCo" (ngModelChange)="user.AccountSettings.DeliveryCo = $event" placeholder="Fakturaadress 2 (t ex c/o)" id="levaddress" name="levaddress2">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-12">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput [ngModel]="user?.AccountSettings.DeliveryAddress" (ngModelChange)="user.AccountSettings.DeliveryAddress = $event" placeholder="Fakturaadress (gatuadress)" id="levaddress" name="levaddress">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput [ngModel]="user?.AccountSettings.DeliveryZip" (ngModelChange)="user.AccountSettings.DeliveryZip = $event" placeholder="Postnummer" id="levzip" name="levzip">
                    </mat-form-field>
                  </div>
                  <div class="col-lg-6">
                    <mat-form-field style="width: 100%" floatPlaceholder="never">
                      <input matInput [ngModel]="user?.AccountSettings.DeliveryCity" (ngModelChange)="user.AccountSettings.DeliveryCity = $event" placeholder="Ort" id="levcity" name="levcity" #levcity="ngModel">
                    </mat-form-field>
                  </div>



                  <!-- Bankuppgifter -->
                  <div class="col-lg-12" style="margin: 20px 0">
                    <h4>Bankuppgifter</h4>
                  </div>

                  <div class="row col-lg-12" *ngIf="user.AccountSettings.Country.Id == null || user.AccountSettings.Country.Name == 'Sverige'">
                    <div class="col-lg-6">
                      <mat-form-field style="width: 100%" floatPlaceholder="never">
                        <input matInput [ngModel]="user?.AccountSettings.BankName" (ngModelChange)="user.AccountSettings.BankName = $event" placeholder="Banknamn" id="BankName" name="BankName">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                      <mat-form-field style="width: 100%" floatPlaceholder="never">
                        <input matInput [ngModel]="user?.AccountSettings.ClearingNumber" (ngModelChange)="user.AccountSettings.ClearingNumber = $event" placeholder="Clearingnummer" id="ClearingNumber" name="ClearingNumber">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-3">
                      <mat-form-field style="width: 100%" floatPlaceholder="never">
                        <input matInput [ngModel]="user?.AccountSettings.AccountNumber" (ngModelChange)="user.AccountSettings.AccountNumber = $event" placeholder="Kontonummer" id="AccountNumber" name="AccountNumber">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                      <mat-form-field style="width: 100%" floatPlaceholder="never">
                        <input matInput [ngModel]="user?.AccountSettings.Bankgiro" (ngModelChange)="user.AccountSettings.Bankgiro = $event" placeholder="Bankgiro" id="AccountNumber" name="Bankgiro">
                      </mat-form-field>
                    </div>

                    <div class="col-lg-6">
                      <mat-form-field style="width: 100%" floatPlaceholder="never">
                        <input matInput [ngModel]="user?.AccountSettings.Plusgiro" (ngModelChange)="user.AccountSettings.Plusgiro = $event" placeholder="Plusgiro" id="AccountNumber" name="Plusgiro">
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row col-lg-12" *ngIf="user.AccountSettings.Country.Id == null || user.AccountSettings.Country.Name != 'Sverige'">
                    <div class="col-lg-6">
                      <mat-form-field style="width: 100%" floatPlaceholder="never">
                        <input matInput [ngModel]="user?.AccountSettings.Iban" (ngModelChange)="user.AccountSettings.Iban = $event" placeholder="IBAN" id="iban" name="Iban">
                      </mat-form-field>
                    </div>

                    <div class="col-lg-6">
                      <mat-form-field style="width: 100%" floatPlaceholder="never">
                        <input matInput [ngModel]="user?.AccountSettings.Bic" (ngModelChange)="user.AccountSettings.Bic = $event" placeholder="BIC" id="bic" name="Bic">
                      </mat-form-field>
                    </div>
                  </div>

                  <!-- Övrigt -->
                  <div class="col-lg-12" style="margin: 20px 0">
                    <h4>Övrigt</h4>
                  </div>
                  <div class="row col-12" style="height: 67px;">
                    <div class="col-lg-6" *ngIf="user.AccountSettings.HasCustomServiceFee">
                      <mat-form-field style="width: 100%" floatPlaceholder="never">
                        <input disabled type="number" matInput [ngModel]="CustomServiceFee" placeholder="Avtalad serviceavgift, procent" id="servicefee" name="servicefee" #servicefee="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row col-12">
                    <div class="col-lg-6">
                      <mat-form-field style="width: 100%" floatPlaceholder="never">
                        <input type="number" matInput [ngModel]="Commission" (ngModelChange)="Commission = $event" placeholder="Provision, procent" id="commission" name="commission" #commission="ngModel">
                      </mat-form-field>
                    </div>
                    <div class="col-lg-6">
                      <span style="font-size: 80%;">Om du tar provision/förmedlingsavgift för uppdrag du fakturerar en annan slutkund för anger du din procentsats här (den går även att ändra per faktura)</span>
                    </div>
                  </div>
                  <div class="row col-12">
                    <div class="col-lg-6">
                      <span *ngIf="user?.AccountSettings.FTax">Är registrerad för F-skatt</span>
                      <span *ngIf="!user?.AccountSettings.FTax">Är ej registrerad för F-skatt</span>
                    </div>
                  </div>

                  <div class="col-sm-12">
                    <button color="accent" [disabled]="!saveValidates()" mat-flat-button style="float:right; margin-top: 20px;">Spara</button>
                  </div>
                  <div class="col-12" style="text-align: right; font-size: 80%;">{{validationErrorMessage}}</div>
                </div>

              </form>
            </mat-card>
          </div>
        </div>
    </div>
    </div>
  </div>
</div>
