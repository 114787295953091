<!-- Visable on large devices -->
<div class="container d-none d-lg-block py-3 animated fadeInDown">
  <form name="form" (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
    <h1 class="mat-display-3 d-flex justify-content-center mt-5 accent">SKAPA KONTO</h1>
    <div class="container">
      <div class="col">
        <div *ngIf="appId == 1" class="row">
          <div class="col-sm-6">
            <mat-form-field color="accent" style="width: 100%; text-align: center;" floatPlaceholder="never">
              <input matInput [ngModel]="user.firstname" (ngModelChange)="user.firstname = $event" placeholder="Förnamn" id="firstname" name="firstname">
              <mat-icon after color="accent" matPrefix>person</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field color="accent" style="width: 100%; text-align: center;" floatPlaceholder="never">
              <input matInput [ngModel]="user.lastname" (ngModelChange)="user.lastname = $event" placeholder="Efternamn" id="lastname" name="lastname">
              <mat-icon after color="accent" matSuffix>person</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="appId == 2" class="row">
          <div class="col-sm-6">
            <mat-form-field color="accent" style="width: 100%; text-align: center;" floatPlaceholder="never">
              <input matInput [ngModel]="user.companyname" (ngModelChange)="user.companyname = $event" placeholder="Företagsnamn" id="companyname" name="companyname">
              <mat-icon after color="accent" matPrefix>person</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-sm-6"></div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field color="accent" style="width: 100%; text-align: center;" floatPlaceholder="never">
              <input matInput [ngModel]="user.email" (ngModelChange)="user.email = $event" placeholder="Epostadress *" id="useremail" name="useremail">
              <mat-icon after color="accent" matPrefix>contact_mail</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field color="accent" style="width: 100%; text-align: center;" floatPlaceholder="never">
              <input matInput [ngModel]="user.phone" (ngModelChange)="user.phone = $event" placeholder="Telefon *" id="phone" name="phone">
              <mat-icon after color="accent" matSuffix>phone_enabled</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field color="accent" style="width: 100%; text-align: center;" floatPlaceholder="never">
              <input matInput required [ngModel]="user.password" (ngModelChange)="user.password = $event" type="password" placeholder="Lösenord" id="password" name="password">
              <mat-icon after color="accent" matPrefix>vpn_key</mat-icon>
            </mat-form-field>
          </div>
          <div class="col-sm-6">
            <mat-form-field color="accent" style="width: 100%; text-align: center;" floatPlaceholder="never">
              <input matInput required [ngModel]="user.repeatpassword" (ngModelChange)="user.repeatpassword = $event" type="password" placeholder="Repetera lösenord" id="repeatpassword" name="repeatpassword">
              <mat-icon after color="accent" matSuffix>vpn_key</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <mat-form-field color="accent" style="width: 100%; text-align: center;" floatPlaceholder="never">
              <input matInput [ngModel]="user.DiscountCode" (ngModelChange)="user.DiscountCode = $event" placeholder="Kampanjkod" id="discountcode" name="discountcode">
              <mat-icon after color="accent" matPrefix>loyalty</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <mat-checkbox type="checkbox" [ngModel]="tosAccepted" (change)="tosAccepted = $event.checked" class="py-2" name="active" requierd>Härmed godkänner jag <a class="accent" href="https://api.sampoolen.se/Documents/GeneralTermsOfUser.pdf" target="_blank">användaravtalet</a> och de <a class="accent" href="https://api.sampoolen.se/Documents/GeneralTermsRegistration.pdf" target="_blank">generella villkoren</a></mat-checkbox>
          <button type="submit" [disabled]="!validateForm() || !tosAccepted" mat-raised-button name="action" color="accent">Skapa konto</button>
        </div>

      </div>
    </div>
  </form>

</div>

<!-- Visable on medium and lower devices -->
<div class="d-lg-none d-md-block">
  <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
    <h1 class="d-flex justify-content-center pt-5">SKAPA KONTO</h1>
    <div class="container">
      <div class="row">
        <div class="col-6">
          <mat-form-field color="accent" style="width: 100%; text-align: center;" floatPlaceholder="never">
            <input matInput required [ngModel]="user.firstname" (ngModelChange)="user.firstname = $event" placeholder="Förnamn" id="firstname" name="firstname">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field color="accent" style="width: 100%; text-align: center;" floatPlaceholder="never">
            <input matInput required [ngModel]="user.lastname" (ngModelChange)="user.lastname = $event" placeholder="Efternamn" id="lastname" name="lastname">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field color="accent" style="width: 100%; text-align: center;" floatPlaceholder="never">
            <input matInput required [ngModel]="user.email" (ngModelChange)="user.email = $event" placeholder="Epostadress" id="useremail" name="useremail">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field color="accent" style="width: 100%; text-align: center;" floatPlaceholder="never">
            <input matInput required [ngModel]="user.phone" (ngModelChange)="user.phone = $event" placeholder="Telefon" id="phone" name="phone">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <mat-form-field color="accent" style="width: 100%; text-align: center;" floatPlaceholder="never">
            <input matInput required [ngModel]="user.password" (ngModelChange)="user.password = $event" type="password" placeholder="Lösenord" id="password" name="password">
          </mat-form-field>
        </div>
        <div class="col-6">
          <mat-form-field color="accent" style="width: 100%; text-align: center;" floatPlaceholder="never">
            <input matInput required [ngModel]="user.repeatpassword" (ngModelChange)="user.repeatpassword = $event" type="password" placeholder="Repetera lösenord" id="repeatpassword" name="repeatpassword">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field color="accent" style="width: 100%; text-align: center;" floatPlaceholder="never">
            <input matInput [ngModel]="user.DiscountCode" (ngModelChange)="user.DiscountCode = $event" placeholder="Kampanjkod" id="discountcode" name="discountcode">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-checkbox type="checkbox" [ngModel]="tosAccepted" (change)="tosAccepted = $event" class="py-2" name="active" requierd>Härmed godkänner jag <a  class="accent" href="https://api.sampoolen.se/Documents/GeneralTermsOfUser.pdf" target="_blank">användaravtalet</a> och de <a class="accent" href="https://api.sampoolen.se/Documents/GeneralTermsRegistration.pdf" target="_blank">generella villkoren</a></mat-checkbox>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col py-3">
          <button type="submit" [disabled]="!tosAccepted?.checked || !f.form.valid" mat-raised-button name="action" color="accent">Skapa konto</button>
        </div>
      </div>
    </div>
  </form>
</div>

