import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IConfirmResponse } from '../../core/interfaces/confirm/IConfirmResponse';

@Component({
    selector: 'confirm-dialog',
  templateUrl: 'ConfirmDialog.Template.html',
  styleUrls: ['./ConfirmDialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ConfirmDialogComponent {

    public response: IConfirmResponse = {} as IConfirmResponse;
    public headline;
    public text;
    public confirmButtonText;
  public confirmButtonIcon;
  public confirmButtonColor: 'primary' | 'secondary' | 'warn';
  public requireInput: boolean = true;
  public hideInput: boolean = false;
  public hideCancelButton: boolean;

    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.headline = this.data.headline != null ? this.data.headline : '';
        this.text = this.data.text != null ? this.data.text : '';
        this.confirmButtonText = this.data.confirmButtonText != null ? this.data.confirmButtonText : 'Spara';
      this.confirmButtonIcon = this.data.confirmButtonIcon != null ? this.data.confirmButtonIcon : 'check';
      this.confirmButtonColor = this.data.confirmButtonColor ? this.data.confirmButtonColor : 'warn';

      this.requireInput = this.data.requireInput != null ? this.data.requireInput : true;
      this.hideInput = this.data.hideInput != null ? this.data.hideInput : false;
      this.hideCancelButton = this.data.hideCancelButton ? this.data.hideCancelButton : false;
    }

    save()
    {
        this.response.Confirmed = true;
        this.dialogRef.close(this.response);
    }

    cancel() {
        this.response.Confirmed = false;
        this.dialogRef.close(this.response);
    }
}
