<style>
  .time-row, .text-input, textarea {
    font-size: 16px;
    margin-left: 0px !important;
    margin-bottom: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 8px;
    border: 1px solid gray;
  }

  mat-select.select-hours, mat-select.select-vat, textarea {
    padding: 9px !important;
    border: 1px solid gray !important;
  }

  .mat-form-field-label {
    margin-left: 10px !important;
  }

  textarea:focus, input:focus {
    caret-color: black;
  }

  .segment
  {
    margin-bottom:20px;
  }

  .invoicegroup
  {
    margin-bottom: 20px;
  }

  .angular-with-newlines {
    white-space: pre-wrap;
  }

  .border
  {
    border: 1px solid;
    margin-bottom: 10px;
    padding: 10px 10px;
    border-color: #b9b9b9;
  }

  .deduction {
    border: solid 1px black;
    margin: 10px 10px;
    padding: 10px 10px;
  }

  .currency {
    float: right;
    position: relative;
    top: 28px;
  }

  .inputwidth {
    width: 30%;
  }

  .default-theme .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }

  .error {
    color: red;
    font-size: 80%;
  }

</style>
<div class="container">
  <div style='height:100%;'>
    <div class="row segment" *ngIf="data.Customer != undefined">
      <div *ngIf="data?.CreditInvoiceFor != null && data.CreditInvoiceFor != ''" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">Detta är en kreditfaktura för faktura nr {{data?.CreditInvoiceFor}}</b><br />
      </div>
      <div *ngIf="data?.SubInvoice" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">Detta är en underfaktura</b><br />
      </div>
      <div *ngIf="data?.ParentInvoice" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">Detta är en moderfaktura</b><br />
      </div>
      <div *ngIf="data.SalaryShares != null && data.SalaryShares.length > 0" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">Det finns en lönedelning på denna faktura</b><br />
      </div>
      <div *ngIf="data?.User?.AccountSettings?.TaxAdjustments.length != 0 && data?.User?.AccountSettings?.TaxAdjustments.length > 0" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">Det finns en skattejämkning/skatteändring på medlemmen</b><br />
      </div>
      <!--<div *ngIf="data?.User?.AccountSettings?.AttachmentOfSalaries.length != 0 && data?.User?.AccountSettings?.AttachmentOfSalaries.length > 0" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">Det finns en löneutmätning på medlemmen</b><br />
      </div>-->
      <div *ngIf="data.IsRotRut != null && data.IsRotRut" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">Det finns ROT/RUT på denna faktura</b><br />
      </div>
      <div *ngIf="data.IsRut != null && data.IsRut" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">Det finns RUT på denna faktura</b><br />
      </div>
      <div *ngIf="data.IsRot != null && data.IsRot" class="col-12" style="margin-bottom: 10px;">
        <b style="color: red;">Det finns ROT på denna faktura</b><br />
      </div>
      <mat-form-field class="col-lg-12 col-sm-12">
        <mat-label>Info om fakturan som bara admin ser</mat-label>
        <input matInput [(ngModel)]="data.AdminInfo" value="{{data?.AdminInfo}}">
      </mat-form-field>
      <mat-form-field class="col-lg-12 col-sm-12" *ngIf="data?.UserSettings?.AdminInfo != null">
        <mat-label>Info om medlem som bara admin ser</mat-label>
        <input matInput readonly value="{{data?.UserSettings?.AdminInfo}}">
      </mat-form-field>
      <mat-form-field class="col-lg-12 col-sm-12" *ngIf="data?.Customer?.PublicInfo != null">
        <mat-label>Info om kund som visas för medlem:</mat-label>
        <input matInput readonly value="{{data?.Customer?.PublicInfo}}">
      </mat-form-field>
      <mat-form-field class="col-lg-12 col-sm-12" *ngIf="data?.Customer?.AdminInfo != null">
        <mat-label>Info om kund som bara admin ser:</mat-label>
        <input matInput readonly value="{{data?.Customer?.AdminInfo}}">
      </mat-form-field>

      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Medlems.nr:</mat-label>
        <input matInput readonly value="{{data?.UserSettings?.MemberNumber}}">
      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Anställnings.nr:</mat-label>
        <input matInput readonly value="{{data?.UserSettings?.EmploymentNumber}}">
      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Kund:</mat-label>
        <input matInput readonly value="{{data?.Customer?.Name}}">
      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Org.nr/p.nr:</mat-label>
        <input matInput readonly value="{{data?.Customer?.OrgNumber}}">
      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Fakturadatum:</mat-label>
        <input matInput readonly value="{{data?.InvoiceSentByUser | date: 'yyyy-MM-dd'}}">
      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Förfallodatum:</mat-label>
        <input matInput readonly value="{{data?.PaymentDue | date: 'yyyy-MM-dd'}}">
      </mat-form-field>
      <div *ngIf="data.IsRut != null && data.IsRut" class="col-12" style="margin-bottom: 10px;">
        <b>Det finns RUT på denna faktura</b><br />
      </div>
      <div *ngIf="data.IsRot != null && data.IsRot" class="col-12" style="margin-bottom: 10px;">
        <b>Det finns ROT på denna faktura</b><br />
      </div>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Betalningsvillkor:</mat-label>
        <input matInput readonly value="{{data?.PaymentTerm}}">
      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Fakturamärkning:</mat-label>
        <input matInput readonly value="{{data?.Marking}}">
      </mat-form-field>
      <mat-form-field class="col-lg-12 col-sm-12">
        <mat-label>Medlemmens namn:</mat-label>
        <input matInput readonly value="{{data?.UserSettings?.FirstName}} {{data?.UserSettings?.LastName}}">
      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Kundreferens för fakturan:</mat-label>
        <input matInput readonly value="{{data?.Reference?.Name}}">
      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Kundreferensens email:</mat-label>
        <input matInput readonly value="{{data?.Reference?.Email}}">
      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Elektronisk referens:</mat-label>
        <input matInput readonly value="{{data?.ReferenceText}}">
      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Valuta:</mat-label>
        <input matInput readonly value="{{data?.Currency?.Name}}">
      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Kundtyp (företag/privat):</mat-label>
        <input matInput readonly value="{{data?.Customer?.IsCompany ? 'Företag' : 'Privat'}}">
      </mat-form-field>
      <div class="col-lg-6 col-sm-12">

      </div>
      <mat-form-field class="col-lg-12 col-sm-12">
        <mat-label>Kund:</mat-label>
        <input matInput readonly value="{{data?.Customer?.Name}}">
      </mat-form-field>
      <mat-form-field class="col-lg-12 col-sm-12">
        <mat-label>Fakturaadress:</mat-label>
        <input matInput readonly value="c/o: {{data?.Customer?.Co}}">
        <input matInput readonly value="{{data?.Customer?.Address}}">
        <input matInput readonly value="{{data?.Customer?.Address2}}">

      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Postnr:</mat-label>
        <input matInput readonly value="{{data?.Customer?.Zip}}">
      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Ort:</mat-label>
        <input matInput readonly value="{{data?.Customer?.City}}">
      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Land:</mat-label>
        <input matInput readonly value="{{data?.Customer?.Country?.Name}}">
      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>Telefon till företaget:</mat-label>
        <input matInput readonly value="{{data?.Customer?.Phone}}">
      </mat-form-field>
      <mat-form-field class="col-lg-6 col-sm-12">
        <mat-label>E-postadress:</mat-label>
        <input matInput readonly value="{{data?.Customer?.Email}}">
      </mat-form-field>
      <mat-form-field *ngIf="data?.Customer.GlnNumber != null && data?.Customer.GlnNumber != ''" class="col-lg-12 col-sm-12">
        <mat-label>GLN-nummer:</mat-label>
        <input matInput readonly value="{{data?.Customer?.GlnNumber}}">
      </mat-form-field>
      <div class="col-lg-6 col-sm-12">
        <button mat-stroked-button (click)="editCustomer(data.Customer)" [disabled]="isLoading">Redigera kund</button>
      </div>

      <div class="col-12">
        <h4>Vad ska faktureras:</h4>
        <div *ngFor="let group of data.InvoiceGroups" style="border-style: solid; border-color: #b4b4b4; border-width: 2px; margin-bottom:15px; padding: 10px;">

          <div *ngIf="data.ParentInvoice">
            Mottagare:
            <span *ngIf="!group?.PaymentReciever?.IsCompany">{{group?.PaymentReciever?.FirstName}} {{group?.PaymentReciever?.LastName}} - {{group?.PaymentReciever?.PersonalNumber}}</span>
            <span *ngIf="group?.PaymentReciever?.IsCompany">{{group?.PaymentReciever.CompanyName}} - {{group?.PaymentReciever?.OrgNumber}}</span><br />
            <span *ngIf="group?.EmploymentType != null && !group?.PaymentReciever?.IsCompany">({{group?.EmploymentType?.Name}})</span>
            <span *ngIf="group?.PaymentReciever?.IsCompany">(Företag/konsult)</span>
          </div>
          <div *ngIf="group.PensionSavingsAmount > 0">
            <b>Pension inkl skatt:</b><br />
            {{group.PensionSavingsAmount * 1.2426 | number : '1.0-0'}}<br />
          </div>

          <b>Moms:</b><br />
          {{group?.Vat}} % <span *ngIf="group.ReverseTax">(omvänd skattskyldighet)</span><br />
          <b>Beskrivning:</b><br />
          <span class="angular-with-newlines">{{addLinebreaks(255,group.Description)}}</span><br />
          <b>Summa:</b><br />
          {{group.Amount}} {{data?.Currency?.Name}}<br />
          <b>Visa på faktura:</b><br />
          <span class="angular-with-newlines">{{group?.TimeReportStatus?.DisplayName}}</span><br />
          <span *ngIf="group.TimeReportStatus.Name == 'SumTime'">
            {{group?.InvoiceRows[0]?.StartDate | date: 'yyyy-MM-dd'}} -
            {{group?.InvoiceRows[group.InvoiceRows.length-1]?.StartDate | date: 'yyyy-MM-dd'}} ({{getGroupTotalHours(group)}})
          </span>
          <!--<div *ngIf="group.TimeReportStatus.Name == 'DetailedInfo'">
            <span *ngFor="let row of group.InvoiceRows; let odd = odd">
              {{row?.StartDate | date: 'yyyy-MM-dd'}}, ({{row?.HoursWorked}} h{{row?.MinutesWorked > 0 ? ' ' + row?.MinutesWorked.toString() + ' min' : ''}})
              <br *ngIf="odd" />
            </span>
          </div>-->
          <div *ngIf="group.TimeReportStatus.Name == 'DetailedInfo'">
            <span *ngFor="let row of group.InvoiceRows; let i = index">
              {{row?.StartDate | date: 'yyyy-MM-dd'}} ({{row?.HoursWorked}} h{{row?.MinutesWorked > 0 ? ' ' + row?.MinutesWorked.toString() + ' min' : ''}}),
              <br *ngIf="i > 0 && (i + 1) % 4 == 0" /> <!-- Check if index is possible to divide by 4, then add break -->
            </span>
          </div>

          <!-- Invoice group deductions in a combined invoice -->
          <div *ngIf="group.Deductions.length > 0" style="margin-top: 15px;">
            <h4>Avdrag:</h4>
            <div *ngFor="let deduction of group.Deductions" class="border">
              <h4>{{deduction?.Name}}</h4>
              <b>Summa:</b><br />
              {{deduction?.Amount  | number : '1.2-2'}} SEK<br />
              <b>Beskrivning:</b><br />
              <!--{{deduction?.Description}}<br />-->
              <span class="angular-with-newlines">{{addLinebreaks(50,deduction?.Description)}}</span>
            </div>
          </div>
          <!--  -->
          <!-- Invoice group allowances in a combined invoice -->
          <div *ngIf="group.Allowances.length > 0" class="border" style="margin-top: 15px;">
            <h4>Reseersättning:</h4>
            <div *ngFor="let allowance of group.Allowances">
              <h4>{{allowance.AllowanceType.Name}}</h4>
              {{allowance.Sum  | number : '1.2-2' }} SEK <br />
              <div *ngIf="allowance.Mileage > 0">{{allowance.Mileage}} km</div>
            </div>
          </div>
          <!--  -->
        </div>

        <div>
          <div *ngFor="let deduction of data?.Deductions">
            <div style="border-style: solid; border-color: #b4b4b4; border-width: 2px; margin-bottom:15px; padding: 10px;" *ngIf="deduction.AddedToInvoice == true">
              <div style="display: inline-block;">
                <i>Avdrag</i>
                <h4>{{deduction?.Name}}</h4>
                <b>Summa:</b><br />
                {{deduction?.Amount  | number : '1.2-2'}} SEK<br />
                <b>Beskrivning:</b><br />
                <span class="angular-with-newlines">{{addLinebreaks(50,deduction?.Description)}}</span>

              </div>
              <!--<div>
            <a *ngIf="deduction.Attachment != null" class="col-sm-2" mat-stroked-button color="secondary" (click)="showFile(deduction.Attachment)"><mat-icon>open_in_new</mat-icon>&nbsp; visa dokument</a>&nbsp;
            <a *ngIf="deduction.AttachmentReciept != null" class="col-sm-2" mat-stroked-button color="secondary" (click)="showFile(deduction.AttachmentReciept)"><mat-icon>open_in_new</mat-icon>&nbsp; visa kvittens</a>&nbsp;
          </div>-->
            </div>
          </div>
        </div>

        <div>
          <div *ngIf="data.IsRot || data.IsRut">
            <div style="border-style: solid; border-color: #b4b4b4; border-width: 2px; margin-bottom:15px; padding: 10px;">
              <div style="display: inline-block;">
                <i></i>
                <h4>Framkörningsavgift</h4>
                <div>
                  <b>Moms:</b><br />
                  25 %<br />
                </div>
                <div *ngIf="data.IsRot">
                  <b>Fastighet/lägenhetsnr:</b><br />
                  {{data?.RotProperty}}<br />
                </div>
                <div>
                  <b>Summa:</b><br />
                  {{data?.RotDrivingFeeTotalAmount  | number : '1.2-2'}} SEK<br />
                </div>
                <div>
                  <b>Antal km:</b><br />
                  {{data?.RotDrivingFeeKm}} km<br />
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="data.ParentInvoice && data?.ServiceFee > 0">
            <div style="border-style: solid; border-color: #b4b4b4; border-width: 2px; margin-bottom:15px; padding: 10px;">
              <div style="display: inline-block;">
                <i></i>
                <h4>SAMpoolen -avgift fakturabetalare</h4>

                <b>Moms:</b><br />
                25 %<br />
                <b>SAMpoolen -avgift</b><br />
                {{data?.ServiceFee  | number : '1.2-2'}} SEK<br />
              </div>
            </div>
          </div>

          <div>
            <div *ngFor="let c of data?.Allowances">
              <div *ngIf="c.AddedToInvoice == true" style="border-style: solid; border-color: #b4b4b4; border-width: 2px; margin-bottom:15px; padding: 10px;">
                <i>Reseersättning</i>
                <h4>{{c.AllowanceType.Name}}</h4>
                {{c.Sum  | number : '1.2-2' }} SEK <br />
                <div *ngIf="c.Mileage > 0">{{c.Mileage}} km</div>
              </div>
              <div class="d-flex justify-content-start">
                <!--<button [disabled]="downloading" mat-raised-button color="accent" (click)="downloadTravelReport(data.InvoiceNumber)" style="margin: 25px 25px; margin: 10px 10px; margin-bottom: 30px;"><mat-icon>print</mat-icon> &nbsp; Skapa PDF</button>
            <mat-spinner *ngIf="downloading" [diameter]="30" style="margin-top: 10px;" color="accent"></mat-spinner>-->
              </div>
            </div>
          </div>


        </div>

        <mat-form-field class="col-lg-6 col-sm-12">
          <mat-label>Fakturaavgift på faktura?</mat-label>
          <span *ngIf="data?.FeePaidByReciever"><input matInput readonly value="{{data?.InvoiceFee}}"></span>
          <span *ngIf="!data?.FeePaidByReciever"><input matInput readonly value="0"></span>
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12" *ngIf="data?.IsCompany == 1">
          <mat-label>Avsändaren registrerad för moms?</mat-label>
          <input matInput readonly value="{{data?.UserSettings?.FTax ? 'Ja' : 'Nej'}}">
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12" style="margin-top: 20px;" *ngIf="data.UserSettings.IsCompany && data.ParentInvoice">
          <mat-label>Provision på fakturan (%)</mat-label>
          <input matInput readonly value="{{data?.Commission * 100  | number : '1.0-1'}}">
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12" style="margin-top: 20px;" *ngIf="data?.PensionSavingsFactor > 0">
          <mat-label>Tjänstepension på fakturan (%)</mat-label>
          <input matInput readonly value="{{data?.PensionSavingsFactor * 100 | number : '1.0-1'}}">
        </mat-form-field>
        <mat-form-field class="col-lg-3 col-sm-12" style="margin-top: 20px;" *ngIf="data?.PensionSavingsAmount > 0">
          <mat-label>Tjänstepension (kronor)</mat-label>
          <input matInput readonly value="{{data?.PensionSavingsAmount  | number : '1.0-1'}}">
        </mat-form-field>
        <mat-form-field class="col-lg-3 col-sm-12" style="margin-top: 20px;" *ngIf="data?.PensionSavingsAmount > 0">
          <mat-label>Tjänstepension inkl skatt</mat-label>
          <input matInput readonly value="{{data?.PensionSavingsAmount * 1.2426 | number : '1.0-0'}}">
        </mat-form-field>
        <mat-form-field class="col-lg-12 col-sm-12" style="margin-top: 20px;">
          <mat-label>Totalsumma för hela fakturan ex moms:</mat-label>
          <input matInput readonly value="{{data?.TotalInvoiceAmount  | number : '1.2-2'}} {{data?.Currency?.Name}}">
        </mat-form-field>
        <mat-form-field class="col-lg-12 col-sm-12">
          <mat-label>Leveransmetod till kund:</mat-label>
          <input matInput readonly value="{{getSendTypeName(data.InvoiceSendType)}}">
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12">
          <mat-label>E-postadress faktura:</mat-label>
          <input matInput readonly value="{{data?.RecieverEmail}}">
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12">
          <mat-label>E-postadress fakturakopia:</mat-label>
          <input matInput readonly value="{{data?.RecieverEmailCopy}}">
        </mat-form-field>

        <!--<mat-form-field class="col-lg-6 col-sm-12">
              <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="3" readonly
                        value="Hej!

    Här kommer en faktura från vår medlem {{data?.UserSettings?.FirstName}} {{data?.UserSettings?.LastName}}.

    Vi vill passa på att önska en fortsatt riktigt härlig dag!

    Med vänliga hälsningar
    SAMpoolen 

    Har det blivit något knas med fakturan ska du i första hand kontakta {{data?.UserSettings?.FirstName}} {{data?.UserSettings?.LastName}} på {{data?.UserSettings?.Email}}.
    Vill du komma i kontakt med oss på SAMpoolen eller om du önskar prata med oss av annan anledning är det bara att antingen mail eller ringa oss på
    - faktura@sampoolen.se
    - 08 51 81 64 40

    SAMpoolen är Sveriges billigaste fakturatjänst, eller egenanställningstjänst som det lite tråkigt heter. Vårt mål är att ta så låg avgift som möjligt för att ge våra medlemmar, de som skickar fakturorna, så mycket som möjligt.
    På www.sampoolen.se listar vi alla seriösa egenanställningsföretag samt deras förhållandevis höga avgifter.">
              </textarea>
            </mat-form-field>-->

        <div class="col-12">
          <h4 *ngIf="data.Documents.length > 0">Fakturabilagor</h4>
          <div *ngFor="let doc of data.Documents">
            <div *ngIf="doc.FileType.TechnicalName == 'AttachedInvoiceDocument'" class="row col-12" style="margin-bottom: 10px;">
              <div class="col-lg-4">
                <a color="secondary" (click)="showFile(doc)" style="cursor: pointer;">
                  <mat-icon style="position: relative; top: 7px; margin-right: 10px;">picture_as_pdf</mat-icon> {{doc?.Name}}
                </a>
              </div>
              <div class="col-lg-2">
                <button mat-icon-button color="primary" [disabled]="isLoading" (click)="deleteDocumentFromList(doc,data.Documents)"><mat-icon>delete</mat-icon></button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <h4>Meddelanden till SAMpoolen :</h4>
          <div *ngFor="let m of data.Messages" class="angular-with-newlines">
            <i>({{m.Created | date: 'yyyy-MM-dd'}})</i>&nbsp;<b>{{m.CreatedBy}}:</b> <br />
            {{m.Text}}
          </div>
        </div>

        <div class="col-lg-12 col-sm-12" style="margin-top:20px; padding: 0px !important;">
          <mat-form-field class="col-lg-6 col-sm-12">
            <input type="text" [(ngModel)]="data.InvoiceNumber" matInput placeholder="Fakturanummer" />
          </mat-form-field>

          <mat-form-field class="col-lg-6 col-sm-12">
            <input type="text" [(ngModel)]="data.Ocr" matInput placeholder="OCR" />
          </mat-form-field>

          <div *ngIf="data.Currency.Name != 'SEK'">
            <mat-form-field class="col-6">
              <input type="number" [(ngModel)]="data.ExchangeRateInvoiceSent" matInput placeholder="Valutakurs" />
            </mat-form-field>
          </div>

          <mat-form-field class="col-6">
            <input matInput required [matDatepicker]="picker" [value]="data.InvoiceSentByUser" (dateChange)="data.InvoiceSentByUser = toFormattedDate($event.value)" placeholder="Fakturan skickad av medlem" id="InvoiceSentByUser" name="InvoiceSentByUser">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="col-6">
            <input matInput [matDatepicker]="pickerPaymentDue" [value]="data.PaymentDue" (dateChange)="data.PaymentDue = toFormattedDate($event.value)" placeholder="Förfallodatum" id="pickerPaymentDue" name="pickerPaymentDue">
            <mat-datepicker-toggle matSuffix [for]="pickerPaymentDue"></mat-datepicker-toggle>
            <mat-datepicker #pickerPaymentDue></mat-datepicker>
          </mat-form-field>

          <div class="row" style="margin-left:15px;margin-top:15px;padding-bottom:5px;">

            <div class="sp-form-field-label" style="width: 100%;"><h4>Fakturadokument:</h4></div>

            <div class="col-lg-6" style="margin-left: 0; padding-left: 0;">

              <button mat-raised-button color="accent" *ngIf="data?.InvoiceDocument?.Url != null" (click)="showDocument(data.InvoiceDocument.Url)" style="margin-left:2px;">Visa faktura: {{data?.InvoiceDocument?.Name}}</button>

              <button mat-stroked-button *ngIf="data?.InvoiceDocument?.Url != null" (click)="data.InvoiceDocument.Url = null" style="margin-left:5px;margin-right:5px;">Radera</button>

              <input *ngIf="data?.InvoiceDocument?.Url == null" type="file" mat-stroked-button mat-raised-button (change)="uploadFile($event,data.InvoiceDocument,'SentInvoice')" accept=".pdf,.doc,.docx,.jpeg,.jpg,.png,.gif">

            </div>

            <div class="col-lg-2">
              <button mat-raised-button color="accent" [disabled]="isLoading || data?.RecieverEmail == null || data?.RecieverEmail == '' || data?.InvoiceDocument.Guid == null" (click)="sendNotificationToUser(data.InvoiceDocument,data.Id)"><mat-icon>send</mat-icon> Skicka</button>
            </div>

            <div class="col-lg-4 error">
              <div *ngIf="data.RecieverEmail == null || data.RecieverEmail == ''">Epost till mottagaren saknas</div>
              <div *ngIf="data.InvoiceSendType != 1">Annat leveranssätt är valt</div>
            </div>
          </div>

          <hr />

          <div style="margin-bottom: 20px;">
            <div class="sp-form-field-label"><h4>Ladda upp övriga PDF-dokument</h4></div>
            <input [disabled]="isLoading" type="file" mat-stroked-button mat-raised-button (change)="uploadDocument($event,data.Documents)" accept=".pdf">
          </div>

          <!-- Document library -->
          <h4 *ngIf="data.Documents.length > 0">Övriga dokument</h4>
          <div *ngFor="let doc of data.Documents">
            <div *ngIf="doc.FileType.TechnicalName != 'AttachedInvoiceDocument'" class="row col-12" style="margin-bottom: 10px;">
              <div class="col-lg-4">
                <a color="secondary" (click)="showFile(doc)" style="cursor: pointer;">
                  <mat-icon style="position: relative; top: 7px; margin-right: 10px;">picture_as_pdf</mat-icon> {{doc?.Name}}
                </a>
              </div>
              <div class="col-lg-4">
                <mat-form-field>
                  <mat-select name="filetypes" placeholder="Dokumenttyp" [(ngModel)]="doc.FileType.Id" (selectionChange)="documentTypeOnChange()" required>
                    <mat-option *ngFor="let c of fileTypes" [value]="c.Id">
                      {{ c.Name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-lg-2">
                <button mat-raised-button color="accent" [disabled]="isLoading || !sendDocumentByEmailValidation(doc.FileType, data.RecieverEmail, data.Reference.Email)" (click)="sendNotificationToUser(doc,data.Id)"><mat-icon>send</mat-icon> Skicka</button>
                <div class="error">{{sendDocumentByEmailErrorMessage}}</div>
              </div>
              <div class="col-lg-2">
                <button mat-icon-button color="primary" [disabled]="isLoading" (click)="deleteDocumentFromList(doc,data.Documents)"><mat-icon>delete</mat-icon></button>
              </div>
            </div>
          </div>

          <!-- Log messages -->
          <h4 *ngIf="data.Log.length > 0">Händelselogg ({{data?.Log?.length}})</h4>
          <div *ngFor="let log of data.Log">
            <div class="row col-12" style="margin-bottom: 10px;">
              <div class="col-lg-3">
                <span color="secondary" style="cursor: pointer;">
                  {{log?.Created | date:'yyyy-MM-dd (HH:mm)'}}
                </span>
              </div>
              <div class="col-lg-9">
                <span style="font-size:12px;">{{log?.Message}}</span>
              </div>
            </div>
          </div>

          <hr />

          <div>
            <button mat-raised-button color="primary" (click)="close(data)" [disabled]="isLoading" style="float:left; margin-right: 10px;">Stäng</button>
            <button *ngIf="data?.ParentInvoice || data.SalaryShares.length > 0" mat-raised-button color="accent" (click)="saveAndClose(data)" [disabled]="isLoading || data.Status.TechnicalName != 'UnderReview' || data.InvoiceDocument.Guid == null || data.InvoiceNumber == null" style="float:right; margin-right: 10px;">Spara och skapa underfakturor</button>
            <button *ngIf="!data?.ParentInvoice && data.SalaryShares.length == 0" mat-raised-button color="accent" (click)="saveAndClose(data)" [disabled]="isLoading || (data.Status.TechnicalName != 'UnderReview' && data.Status.TechnicalName != 'Paired') || data.InvoiceDocument.Guid == null || data.InvoiceNumber == null" style="float:right; margin-right: 10px;">Spara </button>
            <button mat-raised-button (click)="save(data)" [disabled]="isLoading" style="float:right; margin-right: 10px;">Spara vy</button>
          </div>


        </div>
        <mat-form-field class="col-lg-12 col-sm-12" style="margin-top: 13px;">
          <mat-label>Medlemsnummer:</mat-label>
          <input matInput readonly value="{{data?.UserSettings?.MemberNumber}}">
        </mat-form-field>

        <div *ngIf="!data.ParentInvoice" class="col-lg-12 col-sm-12">
          <mat-label>Expressutbetalning vald (klicka i boxen för att ändra): </mat-label>
          <mat-checkbox [(ngModel)]="data.ExpressPayOut" style="margin-left:15px;">
            <div *ngIf="data.ExpressPayOut">
              Ja
            </div>
            <div *ngIf="!data.ExpressPayOut">
              Nej
            </div>
          </mat-checkbox>
        </div>

        <div *ngIf="data.ParentInvoice" class="col-lg-12 col-sm-12">
          <mat-label>Bekräftad av fakturamottagare (express av underfakturor kan godkännas): </mat-label>
          <mat-checkbox [(ngModel)]="data.ApprovedForExpress" style="margin-left:15px;">
            <div *ngIf="data.ApprovedForExpress">
              Ja
            </div>
            <div *ngIf="!data.ApprovedForExpress">
              Nej
            </div>
          </mat-checkbox>
        </div>

        <div *ngIf="!data.ParentInvoice && !data.SubInvoice" class="col-lg-12 col-sm-12">
          <mat-label>Fakturan godkänd för expressutbetalning: </mat-label>
          <mat-checkbox [(ngModel)]="data.ApprovedForExpress" style="margin-left:15px;">
            <div *ngIf="data.ApprovedForExpress">
              Ja
            </div>
            <div *ngIf="!data.ApprovedForExpress">
              Nej
            </div>
          </mat-checkbox>
        </div>

        <div *ngIf="data.SalaryShares != null && data.SalaryShares.length > 0" class="col-12">
          <b>Lönedelning:</b><br />
          <div *ngFor="let share of data.SalaryShares">
            <div>Personnummer: {{ share?.SalaryReciever?.PersonalNumber }}</div>
            <div>{{ share?.Share != null && share?.Share > 0 ? 'Delning: ' + share.Share + ' %' : '' }}</div>
            <div>{{ share?.Salary != null && share?.Salary > 0 ? 'Delning bruttolön: ' + share.Salary + ' kr' : '' }}</div>
          </div>
        </div>
        <mat-form-field class="col-lg-6 col-sm-12" style="margin-top: 20px;">
          <mat-label>E-postadress referensperson:</mat-label>
          <input matInput readonly value="{{data?.Reference?.Email}}">
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12">
          <mat-label>Telefon referensperson:</mat-label>
          <input matInput readonly value="{{data?.Reference?.Phone}}">
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12">
          <mat-label>Fakturareferens:</mat-label>
          <input matInput readonly value="{{data?.ReferenceText}}">
        </mat-form-field>
        <mat-form-field class="col-lg-6 col-sm-12">
          <mat-label>Kampanjkod:</mat-label>
          <input matInput readonly value="{{data?.UserSettings?.DiscountCode}}">
        </mat-form-field>

        <div class="col-12 border">
          <b>Summa avdrag:</b><br />
          {{data?.DeductionsTotalAmount  | number : '1.2-2'}} {{data?.Currency?.Name}}

          <div *ngFor="let deduction of data?.Deductions" class="deduction border">
            <div style="display: inline-block;">
              <b>{{deduction.Name}}</b><br /><br />
              <b>Beskrivning:</b><br />
              <span class="angular-with-newlines">{{addLinebreaks(50,deduction?.Description)}}</span><br /><br />
              <b>Summa:</b><br />
              {{deduction.Amount  | number : '1.2-2'}} SEK<br />

            </div>
            <div>
              <a *ngIf="deduction.Attachment.Url != null" mat-stroked-button color="secondary" (click)="showFile(deduction.Attachment)"><mat-icon>open_in_new</mat-icon>&nbsp; Visa dokument</a>&nbsp;
              <a *ngIf="deduction.AttachmentReciept.Url != null" mat-stroked-button color="secondary" (click)="showFile(deduction.AttachmentReciept)"><mat-icon>open_in_new</mat-icon>&nbsp; Visa kvittens</a>&nbsp;
            </div>

            <!--Update deduction-->
            <!--<div style="display: inline-block; float:right;">
          <button mat-stroked-button mat-raised-button [disabled]="!canUpdatePayout()" (click)="deleteDeduction(c)" style="margin-top: 8px;margin-right:20px;color:red;">radera</button>
          <button mat-stroked-button mat-raised-button (click)="editDeduction(c)" style="margin-top: 8px;margin-right:10px;">redigera</button>
        </div>
              </div>-->
          </div>
        </div>


        <div class="col-12 border" *ngIf="data?.TravelExpenses != null">
          <b>Resekostnader:</b><br />
          {{(data?.TravelExpenses?.TravelReportTotalAmount != null ? data?.TravelExpenses?.TravelReportTotalAmount : 0) | number}} {{data?.Currency?.Name}} <br />
          {{data?.TravelExpenses?.DriveJournalTotalAmount != null ? data?.TravelExpenses?.DriveJournalTotalAmount + ' km' : ''}}

          <div *ngFor="let r of data?.TravelExpenses.Reciepts" class="deduction">
            <b>{{r.Name}}</b><br />

            <button mat-stroked-button mat-raised-button (click)="showDocument(r.Url)" style="margin-left:2px;">visa kvitto</button>

          </div>

        </div>


        <div>
          <h4>Reseersättning</h4>
          <div *ngIf="sumMileage != 0">Summa milersättning: {{sumMileage}}</div>
          <div *ngIf="sumMileage != 0">Antal km: {{mileageDistance}}</div>
          <div *ngIf="sumDomesticAllowance != 0">Summa traktamente, inrikes: {{sumDomesticAllowance}}</div>
          <div *ngIf="sumInternationalAllowance != 0">Summa traktamente, utrikes: {{sumInternationalAllowance}}</div>
          <div *ngFor="let c of data?.Allowances" class="deduction">
            <b>{{c.AllowanceType.Name}}</b> {{c.Sum  | number : '1.2-2' }} SEK <br />
            <div *ngIf="c.Mileage > 0">{{c.Mileage}} km</div>
          </div>
          <div class="d-flex justify-content-start">
            <button [disabled]="downloading" mat-raised-button color="accent" (click)="downloadTravelReport(data.InvoiceNumber)" style="margin: 25px 25px; margin: 10px 10px; margin-bottom: 30px;"><mat-icon>print</mat-icon> &nbsp; Skapa PDF</button>
            <mat-spinner *ngIf="downloading" [diameter]="30" style="margin-top: 10px;" color="accent"></mat-spinner>
          </div>

        </div>


      </div>

    </div>
  </div>
</div>
