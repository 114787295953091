import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from '@app/core';

import { AboutComponent } from './about/about.component';
import { FeaturesComponent } from './features/features.component';
import { TransactionLogsComponent } from './transaction-logs/transaction-logs.component';
import { BalanceAccountComponent } from './balance-account/balance-account.component';

const routes: Routes = [
    //{ path: 'about', component: AboutComponent, data: { title: 'About..' } },
    //{
    //  path: 'features',
    //  component: FeaturesComponent,
    //  data: { title: 'Features' }
    //}

  {
      path: 'transactions',
      component: TransactionLogsComponent,
      data: {
          title: 'Ekonomi'
      },
      canActivate: [AuthGuardService] // Only logged in can access this route 
  },

  {
    path: 'balance-account',
    component: BalanceAccountComponent,
    data: {
      title: 'Kontosaldo'
    },
    canActivate: [AuthGuardService] // Only logged in can access this route 
  },
   

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class StaticRoutingModule { }

