import { Component, Inject, ViewChild, TemplateRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDatepicker, MatDatepickerToggle, NativeDateAdapter } from '@angular/material';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Http, RequestOptions, Headers, Response } from '@angular/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../core/services/AuthenticationService'
import { AppConfig } from '../../config/app.config';
import { ICompany } from '../../core/interfaces/ICompany';
import { ITimeReportStatus } from '../../core/interfaces/invoice/TimeReportStatus';
import { InvoiceService } from '../../core/services/InvoiceService';
import { CompanyService } from '../../core/services/CompanyService';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { UserService } from '../../core/services/UserService';


@Component({
    selector: 'edit-customer-dialog',
    templateUrl: 'invoice-tool.calendarDialog.Component.html'
})

export class InvoiceToolCalendarDialogComponent {
    private _dimesionToggle = false;
    private _formValid = false;
    public _imageList: any[] = [];
    public _hasImages = false;
    private _showImageList = false;
    private _showAttachments = false;
    private _hasAttachments = false;
    private _showComments = false;
    public customer: ICompany;
    public isNewCustomer: boolean;
    @ViewChild('myInput', { static: false })
    myInputVariable: any;
    private _apiBaseUrl: string;
    @ViewChild("datepicker", { static: false }) datepicker: MatDatepicker<Date>;
    selectedDate: Date = new Date();
    private hoursList = [];
    private minutesList = [];
    private vatList = [];
    public rowTypeList = [];
    private hoursSelected: string = "8";
    private minutesSelected: string = "0";
    private rowTypeSelected: string = "0"
    private row: any = {};
    public selectedDates = [];
    public timeReportStatuses: Array<ITimeReportStatus> = [];
    public customerIsSweden: boolean = true;
    isAdmin = false;
  ErrorMessageTab: string = "";
  quickrowValid: boolean = false;
  ErrorMessageDesc: string = "";
  ErrorMessageSend: string = "";




  constructor(
        public dialogRef: MatDialogRef<InvoiceToolCalendarDialogComponent>,
        private http: Http,
        private authenticationService: AuthenticationService,
        private invoiceService: InvoiceService,
        private companyService: CompanyService,
        private userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) 
    { 
        
    }

  ngOnInit() {
    
    this.isAdmin = this.userService.userHasRole("admin");
    this.getTimeReportStatuses();
    console.log(this.data.customerCountryName);
    this.customerIsSweden = this.data.customerCountryName != null && this.data.customerCountryName == "Sverige" ? true : false;

    // Create new group
    if (this.data.invoiceItemGroup == undefined || this.data.invoiceItemGroup == "")
    {
      this.data.invoiceItemGroup = { Id: this.guid(), Description: '', InvoiceRows: [], Type: {}, Vat: "25" };

      if (!this.customerIsSweden) {
        this.data.invoiceItemGroup = { Id: this.guid(), Description: '', InvoiceRows: [], Type: {}, Vat: "25" };
      }
    }

    this.data.invoiceItemGroup = JSON.parse(JSON.stringify(this.data.invoiceItemGroup));

    if (this.data.invoiceItemGroup.InvoiceRows != undefined) {
        for (var i = 0; i < this.data.invoiceItemGroup.InvoiceRows.length; i++) {
            this.data.invoiceItemGroup.InvoiceRows[i].HoursWorked = this.data.invoiceItemGroup.InvoiceRows[i].HoursWorked.toString();

            if (this.data.invoiceItemGroup.InvoiceRows[i].MinutesWorked != null)
            {
                this.data.invoiceItemGroup.InvoiceRows[i].MinutesWorked = this.data.invoiceItemGroup.InvoiceRows[i].MinutesWorked.toString();
            }
            else
            {
                this.data.invoiceItemGroup.InvoiceRows[i].MinutesWorked = "0";
            }
        }
    }

    for (var i = 0; i < 25; i++) {
        this.hoursList.push({ Value: i.toString() });
    }

    this.minutesList.push({ Value: "0" }, { Value: "15" }, { Value: "30" }, { Value: "45" });

    if (this.customerIsSweden && this.isAdmin == false) {
      this.vatList.push({ Value: "25" }, { Value: "12" }, { Value: "6" })
    }
    else if (this.isAdmin == true) {
        
      this.vatList.push({ Value: "25" }, { Value: "12" }, { Value: "6" }, {Value: "0"})
    }

      
    this.rowTypeList.push({ Value: "1", Name: "Arbetad tid" }, { Value: "2", Name: "Övrigt" })

    this.selectedDates = [new Date('2018-10-28T11:13:59'), new Date('2018-10-27T11:13:59')];

    if (this.data.customer.TimeReportMandatory) {
      this.data.invoiceItemGroup.TimeReportStatus.Name == 'DetailedInfo';
    }

    this.quickRowValidate();
  }

  validates()
  {
    if (this.data.customer.TimeReportMandatory && this.data.invoiceItemGroup.TimeReportStatus.Name != 'DetailedInfo') {
      this.ErrorMessageSend = "Denna kund kräver att du visar detaljerad tid på din faktura.";
      return false;
    }
    else if ((this.data.invoiceItemGroup.TimeReportStatus.Name == 'DetailedInfo' || this.data.invoiceItemGroup.TimeReportStatus.Name == 'SumTime') && this.data.invoiceItemGroup.InvoiceRows == 0) {
      this.ErrorMessageSend = "Välj datum och tid för att visa på faktura";
      return false;
    }
    else if (!this.checkForbiddenSymbols(this.data.invoiceItemGroup.Description)) {
      this.ErrorMessageSend = 'Beskrivningen innehåller otillåtna tecken. Du behöver radera otillåtna tecken och tabbar. Godkänt innehåll är a-ö, A-Ö, 0-9 samt , . ! ( ) ? / -';
      return false;
    }
    else
    {
      this.ErrorMessageSend = "";
        return true;
    }
  }

  public quickRowValidate() {
    
    if (this.data.invoiceItemGroup.Description == '' || this.data.invoiceItemGroup.Description == ' ') {
      this.ErrorMessageTab = '';
    }

    this.checkForbiddenSymbols(this.data.invoiceItemGroup.Description);

  }

  checkForbiddenSymbols(description: any) {

    var regex = /^[-a-öA-Ö0-9,./&€%;+:@!?() \r\n]*[^|¤"–]$/g;
    var inputIsValid = regex.test(description);

    if (inputIsValid) {
      this.quickrowValid = true;
      this.ErrorMessageDesc = '';
      return true;
    }
    else {
      this.quickrowValid = false;
      this.ErrorMessageSend = 'Beskrivningen innehåller otillåtna tecken. Du behöver radera otillåtna tecken och tabbar. Godkänt innehåll är a-ö, A-Ö, 0-9 samt , . ! ( ) ? / -';
      return false;
    }
  }

  timeReportStatusChange(event: any)
  {
      console.log(event.value);

      for (var i = 0; i < this.timeReportStatuses.length; i++)
      {
          if (this.timeReportStatuses[i].Id == event.value)
          {
              this.data.invoiceItemGroup.TimeReportStatus.Name = this.timeReportStatuses[i].Name;
          }
      }
  }

  canEditGroup()
  {
    if (this.isAdmin) {
      return true;
    }
    else {
      let statuses = ["Draft"];
      return statuses.includes(this.data.status);
    }
  }

  getTimeReportStatuses()
  {
      this.invoiceService.getTimeReportStatuses()
          .subscribe(
          response => this.getTimeReportStatusesFinished(response),
          error => console.log(error));
  }

  getTimeReportStatusesFinished(statuses: Array<ITimeReportStatus>)
  {
    this.timeReportStatuses = statuses;

    if (this.data.customer.TimeReportMandatory) {
      this.data.invoiceItemGroup.TimeReportStatus.Id = "1";
      this.data.invoiceItemGroup.TimeReportStatus.Name = "DetailedInfo";
      this.data.invoiceItemGroup.TimeReportStatus.DisplayName = "Detaljerad tid";
    }
    else {
      if (this.data.invoiceItemGroup.TimeReportStatus != null && this.data.invoiceItemGroup.TimeReportStatus.Id == null) {
        for (var i = 0; i < this.timeReportStatuses.length; i++) {
          if (this.timeReportStatuses[i].SortOrder == 1) {
            var status = this.timeReportStatuses[i];
            this.data.invoiceItemGroup.TimeReportStatus.Id = status.Id.toString();
            this.data.invoiceItemGroup.TimeReportStatus.Name = status.Name;
            this.data.invoiceItemGroup.TimeReportStatus.DisplayName = status.DisplayName;

          }
        }
      }
      else if (this.data.invoiceItemGroup.TimeReportStatus != null) {
        this.data.invoiceItemGroup.TimeReportStatus.Id = this.data.invoiceItemGroup.TimeReportStatus.Id.toString();
      }
    }
        
  }

  updateTimeReportStatus() {

      // Don't do for the old rows
      if (this.data.invoiceItemGroup.TimeReportStatus != null) {

          for (var i = 0; i < this.timeReportStatuses.length; i++) {

              if (this.timeReportStatuses[i].Id.toString() == this.data.invoiceItemGroup.TimeReportStatus.Id) {

                  var status = this.timeReportStatuses[i];
                  this.data.invoiceItemGroup.TimeReportStatus.Name = status.Name;
                  this.data.invoiceItemGroup.TimeReportStatus.DisplayName = status.DisplayName;
              }
          }
      }

  }

  rowTypeChange(event: any)
  {
      this.data.invoiceItemGroup.Type = this.getRowType(event.value);
        
      if (event.value == "Övrigt")
      {
          // No rows allowed in the misc category
          this.data.invoiceItemGroup.InvoiceRows = [];
      }
  }

  getRowType(id: any)
  {
      for (var i = 0; i < this.rowTypeList.length; i++)
      {
          if (this.rowTypeList[i].Name == id)
          {
              return { Id: this.rowTypeList[i].Id, Name: this.rowTypeList[i].Name};
          }
      }
  }

  saveCalendar()
  {
      console.log(this.data.invoiceItemGroup);

      this.dialogRef.close(this.data.invoiceItemGroup);
  }

  saveRow() {
      this.data.invoiceItemGroup.isNew = false;
      this.updateTimeReportStatus();
      this.dialogRef.close(this.data.invoiceItemGroup);
  }
  
  deleteRow()
  {
      this.data.invoiceItemGroup.action = 'delete';
      this.dialogRef.close(this.data.invoiceItemGroup);
  }    

  cancel()
  {
      this.dialogRef.close(undefined);
  }
    
  deleteListItem(item: any)
  {
      console.log(item);

      this.data.invoiceItemGroup.InvoiceRows.splice(this.data.invoiceItemGroup.InvoiceRows.indexOf(item), 1);
  }

  guid() {  
      return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }

  s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
  }
    
  calendarSelect(dateString: string)
  {

      var date = this.toFormattedDate(dateString);
        
      // Duplicate dates not allowed
      for (var i = 0; i < this.data.invoiceItemGroup.InvoiceRows.length; i++)
      {
          var item = this.data.invoiceItemGroup.InvoiceRows[i];

          if (item.StartDate == date)
          {
              return;
          }
      }

      // Add item
      this.data.invoiceItemGroup.InvoiceRows.push({ StartDate: date, Id: this.guid(), HoursWorked: this.hoursSelected, MinutesWorked: this.minutesSelected });

      // Sort list by date
      this.data.invoiceItemGroup.InvoiceRows.sort((val1: any, val2: any) => {
          var d1 = <any>new Date(val1.StartDate.toString());
          var d2 = <any>new Date(val2.StartDate.toString());
            
          return d1 - d2;
      })
        
  }

  toFormattedDate(iso: string) {

      const date = new Date(iso);

      var d = `${date.getFullYear()}-${(date.getMonth() + 1).toString().length == 1 ? ('0' + (date.getMonth() + 1)) : date.getMonth() + 1}-${date.getDate().toString().length == 1 ? ("0" + date.getDate().toString()) : date.getDate().toString()}`;

      if (d == '1970-01-01')
          return undefined;

      return d;
  }

    
  onError(error: any)
  {
      alert('An error occured');
      console.log(error);
  }
    
}
