import browser from 'browser-detect';
import { Title } from '@angular/platform-browser';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivationEnd, Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { UserService } from './core/services/UserService';
import { AutoLogoutService } from "./core/services/AutoLogoutService" 

import {
  ActionAuthLogin,
  ActionAuthLogout,
  AnimationsService,
  selectorAuth,
  routeAnimations
} from '@app/core';
import { environment as env } from '@env/environment';

import {
  NIGHT_MODE_THEME,
  selectorSettings,
  SettingsState,
  ActionSettingsChangeAnimationsPageDisabled
} from './settings';
import { AuthenticationService } from './core/services/AuthenticationService';


import {
  ActionSettingsChangeTheme,
  ActionSettingsPersist,
} from './settings/settings.reducer';


@Component({
  selector: 'anms-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routeAnimations]
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  @HostBinding('class') componentCssClass;
  isProd = env.production;
  envName = env.envName;
  version = env.versions.app;
  year = new Date().getFullYear();
  logo = require('../assets/logo.png');
  navigation = [];
  userUpdatesSubscription: Subscription;
  navigationSideMenu = [];
  isAuthenticated;
  navigtionMenu = [];
  public appId: number = env.appId;

  constructor(
    public overlayContainer: OverlayContainer,
    private store: Store<any>,
    private router: Router,
    private titleService: Title,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    public autoLogoutService: AutoLogoutService,
    private animationService: AnimationsService
  ) {
      this.userUpdatesSubscription = this.authenticationService.getUserData().subscribe(message => { this.userUpdated(message); });
    this.autoLogoutService.initListener();

    this.changeTheme({ value: env.themeName });

  }

  changeTheme({ value: theme }) {
    this.store.dispatch(new ActionSettingsChangeTheme({ theme }));
  }

  userUpdated(user: any) {
      this.setMenu();
  }

  setMenu()
  {
      //ADMIN "NEW VIEW"
      //if (this.userService.userHasRole('admin')) {

      //    //Hamburger navigation button
      //    this.navigtionMenu = [
            
      //      { link: 'settings', label: 'Inställningar', icon: 'settings', title: 'Inställningar' },
      //      { link: 'mypages/mydocuments', label: 'Mina dokument', icon: 'attachment', title: 'Mina dokument' },
              
      //    ];

      //    //Main navigation
      //    this.navigation = [
      //        { link: 'admin/invoices-admin', label: 'Fakturor', icon: 'list_alt', title: 'Fakturor' },
      //        { link: 'combined-invoice-tool', label: 'Ny samlingsfaktura', icon: 'library_books', title: 'Ny samlingsfaktura' },
      //        { link: 'users', label: 'Användare', icon: 'diversity_1', title: 'Medlemmar' },
      //        { link: 'admin/transaction-logs-admin', label: 'Ekonomi', icon: 'attach_money', title: 'Ekonomi' },
      //        { link: 'payout-reports', label: 'Återrapportering', icon: 'autorenew', title: 'Återrapportering' },
      //        { link: 'admin/documents', label: 'Medlemsdokument', icon: 'folder_shared', title: 'Medlemsdokument' },
      //        { link: 'admin/reports', label: 'Medlemsrapporter', icon: 'fact_check', title: 'Medlemsrapporter' },
      //        { link: 'mypages/customers', label: 'Kunder', icon: 'groups_2', title: 'Kunder' },
      //        { link: 'about', label: 'Hem', icon: 'home', title: 'Hem' },
      //    ];

      //    //Mobile navigation
      //    this.navigationSideMenu = [
      //        { link: 'admin/invoices-admin', label: 'Fakturor', icon: 'list_alt', title:'Fakturor' },
      //        { link: 'users', label: 'Medlemmar', icon: 'diversity_1', title: 'Medlemmar' },
      //        { link: 'admin/transaction-logs-admin', label: 'Ekonomi', icon: 'attach_money', title: 'Ekonomi' },
      //        { link: 'payout-reports', label: 'Återrapportering', icon: 'autorenew', title: 'Återrapportering' },
      //        { link: 'admin/documents', label: 'Medlemsdokument', icon: 'folder_shared', title: 'Dokument' },
      //        { link: 'mypages/customers', label: 'Kunder', icon: 'groups_2', title: 'Kunder' },
      //        { link: 'about', label: 'Hem', icon: 'home', title: 'Hem' },
      //        { link: 'settings', label: 'Inställningar', icon: 'settings', title: 'Inställningar' }            
      //    ];
  
      //}
    //ADMIN
    if (this.userService.userHasRole('admin')) {

      //Hamburger navigation button
      this.navigtionMenu = [
        { link: 'transactions', label: 'Ekonomi', icon: 'attach_money', title: 'Ekonomi' },
        { link: 'admin/payout-reports', label: 'Återrapportering', icon: 'autorenew', title: 'Återrapportering' },
        { link: 'admin/documents', label: 'Medlemsdokument', icon: 'folder_shared', title: 'Medlemsdokument' },
        { link: 'admin/reports', label: 'Medlemsrapporter', icon: 'fact_check', title: 'Medlemsrapporter' },
        { link: 'mypages/customers', label: 'Kunder', icon: 'people_alt', title: 'Kunder' },

      ];

      //Main navigation
      this.navigation = [
        { link: 'mypages/invoices', label: 'Fakturor', icon: 'list_alt', title: 'Fakturor' },
        //{ link: 'admin/invoices-admin', label: 'Fakturor', icon: 'format_list_bulleted_add', title: 'Fakturor-admin' },
        { link: 'combined-invoice-tool', label: 'Ny samlingsfaktura', icon: 'library_books', title: 'Ny samlingsfaktura' },
        { link: 'users', label: 'Användare', icon: 'people_alt', title: 'Användare' },
        { link: 'about', label: 'Hem', icon: 'home', title: 'Hem' },
        { link: 'settings', label: 'Inställningar', icon: 'settings', title: 'Inställningar' },
        { link: 'mypages/mydocuments', label: 'Mina dokument', icon: 'attachment', title: 'Mina dokument' }
      ];

      //Mobile navigation
      this.navigationSideMenu = [
        { link: 'mypages/invoices', label: 'Fakturor', icon: 'list_alt', title: 'Fakturor' },
        { link: 'users', label: 'Användare', icon: 'people_alt', title: 'Användare' },
        { link: 'transactions', label: 'Ekonomi', icon: 'attach_money', title: 'Ekonomi' },
        { link: 'payout-reports', label: 'Återrapportering', icon: 'autorenew', title: 'Återrapportering' },
        { link: 'admin/documents', label: 'Medlemsdokument', icon: 'folder_shared', title: 'Dokument' },
        { link: 'mypages/customers', label: 'Kunder', icon: 'people_alt', title: 'Kunder' },
        { link: 'about', label: 'Hem', icon: 'home', title: 'Hem' },
        { link: 'settings', label: 'Inställningar', icon: 'settings', title: 'Inställningar' }
      ];

    }

      else if (this.appId == 2) {

        //Hamburger navigation button
        this.navigtionMenu = [
          { link: 'settings', label: 'Inställningar', icon: 'settings_applications', title: 'Inställningar ftg' },
          { link: 'transactions', label: 'Ekonomi', icon: 'attach_money', title: 'Ekonomi' },
          { link: 'mypages/customers', label: 'Kunder', icon: 'people_alt', title: 'Kunder' },
          { link: 'mypages/mydocuments', label: 'Mina dokument', icon: 'attachment', title: 'Mina dokument' },
        ];
          //Main navigation
        this.navigation = [
          { link: 'mypages/invoices', label: 'Fakturor', icon: 'list_alt', title: 'Fakturor' },
          { link: 'combined-invoice-tool', label: 'Ny samlingsfaktura', icon: 'library_books', title: 'Ny samlingsfaktura' },
          { link: 'about', label: 'Hem', icon: 'home', title: 'Hem' },    
        ];

          //Mobile navigation
        this.navigationSideMenu = [
          { link: 'invoice-tool', label: 'Ny faktura', icon: 'description' },
          { link: 'mypages/invoices', label: 'Fakturor', icon: 'list_alt' },
          { link: 'mypages/customers', label: 'Kunder', icon: 'people_alt' },
          { link: 'mypages/mydocuments', label: 'Mina dokument', icon: 'attachment', title: 'Mina dokument' },
          { link: 'contact', label: 'Kontakt', icon: 'perm_contact_calendar' },
          { link: 'about', label: 'Hem', icon: 'home' },
          { link: 'settings', label: 'Inställningar', icon: 'settings' }
        ];
      }

      else if ((!this.userService.userHasRole('companyuser') && !this.userService.userHasRole('admin')) && this.appId == 1) {
        //Hamburger navigation button
          this.navigtionMenu = [
              { link: 'contact', label: 'Kontakt', icon: 'perm_contact_calendar', title: 'Kontakt' },
              { link: 'settings', label: 'Inställningar', icon: 'settings', title: 'Inställningar' },
              { link: 'mypages/customers', label: 'Kunder', icon: 'people_alt', title: 'Kunder' } 
          ];

        //Main navigation
          this.navigation = [
              { link: 'invoice-tool', label: 'Ny faktura', icon: 'description', title: 'Ny faktura' },
              { link: 'mypages/invoices', label: 'Fakturor', icon: 'list_alt', title: 'Fakturor' },
            { link: 'transactions', label: 'Ekonomi', icon: 'attach_money', title: 'Ekonomi' },
            { link: 'mypages/mydocuments', label: 'Mina dokument', icon: 'attachment', title: 'Mina dokument' },
              { link: 'about', label: 'Hem', icon: 'home', title: 'Hem' }
          ];
        //Mobile navigation
          this.navigationSideMenu = [
              { link: 'invoice-tool', label: 'Ny faktura', icon: 'description' },
              { link: 'mypages/invoices', label: 'Fakturor', icon: 'list_alt' },
              { link: 'mypages/customers', label: 'Kunder', icon: 'people_alt' },
              { link: 'transactions', label: 'Ekonomi', icon: 'attach_money' },
              { link: 'contact', label: 'Kontakt', icon: 'perm_contact_calendar' },
              { link: 'about', label: 'Hem', icon: 'home' },
              { link: 'settings', label: 'Inställningar', icon: 'settings' }
          ];
      }
  }

  onCreateAccountClick()
  {
      this.router.navigate(['/create-account']);
  }

  onLogoutClick() {

      this.store.dispatch(new ActionAuthLogout());
      this.router.navigate(['/login'])
      setTimeout(location.reload.bind(location), 1);
  }

  private subscribeToIsAuthenticated() {

      this.store
      .select(selectorAuth)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(auth => (this.authenticated(auth)));
  }

  authenticated(auth: any)
  {
      this.isAuthenticated = auth.isAuthenticated;
  }

  private subscribeToSettings() {
    if (AppComponent.isIEorEdge()) {
      this.store.dispatch(
        new ActionSettingsChangeAnimationsPageDisabled({
          pageAnimationsDisabled: true
        })
      );
    }
    this.store
      .select(selectorSettings)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(settings => {
        this.setTheme(settings);
        this.animationService.updateRouteAnimationType(
          settings.pageAnimations,
          settings.elementsAnimations
        );
      });
  }

  private setTheme(settings: SettingsState) {
    const { theme, autoNightMode } = settings;
    const hours = new Date().getHours();
    const effectiveTheme = (autoNightMode && (hours >= 20 || hours <= 6)
      ? NIGHT_MODE_THEME
      : theme
    ).toLowerCase();
    this.componentCssClass = effectiveTheme;
    const classList = this.overlayContainer.getContainerElement().classList;
    const toRemove = Array.from(classList).filter((item: string) =>
      item.includes('-theme')
    );
    if (toRemove.length) {
      classList.remove(...toRemove);
    }
    classList.add(effectiveTheme);
  }

  private subscribeToRouterEvents() {
    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof ActivationEnd || event instanceof NavigationEnd
        ),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(event => {
        if (event instanceof ActivationEnd) {
          this.setPageTitle(event);
        }

        if (event instanceof NavigationEnd) {
          AppComponent.trackPageView(event);
        }
      });
  }

  private setPageTitle(event: ActivationEnd) {
    let lastChild = event.snapshot;
    while (lastChild.children.length) {
      lastChild = lastChild.children[0];
    }
    const { title } = lastChild.data;
    this.titleService.setTitle(
      title ? `${title} - ${env.appName}` : env.appName
    );
  }

  private static trackPageView(event: NavigationEnd) {
      (<any>window).ga('set', 'page', event.urlAfterRedirects);
      (<any>window).ga('send', 'pageview');
  }

  private static isIEorEdge() {
      return ['ie', 'edge'].includes(browser().name);
  }

  ngOnInit(): void {
      this.subscribeToSettings();
      this.subscribeToIsAuthenticated();
      this.subscribeToRouterEvents();
      this.setMenu();
  }

  ngOnDestroy(): void {
      this.unsubscribe$.next();
      this.unsubscribe$.complete();
  }

  onLoginClick() {
      this.router.navigate(['/login']);
  }

}
