import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { ICompany } from '../interfaces/ICompany';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment as env } from '@env/environment';
import { HttpHeaders } from '@angular/common/http';
import { HttpErrorHandler, HandleError } from './http-error-handler';
import { tap } from "rxjs/internal/operators/tap";
import { AuthGuardService } from '../auth/auth-guard.service';
import { Store } from '@ngrx/store';
import { AuthenticationService } from '../services/AuthenticationService';
import { IContact} from '../interfaces/IContact';

@Injectable()
export class CompanyService {
    public apiAuthUrl: string;
    private apiBaseUrl: string;
    public token: string;
    private userString: string;
    private httpOptions: any;
    private handleError: HandleError;
    
    constructor(
        private http: HttpClient,
        private router: Router,
        private authenticationService: AuthenticationService,
        httpErrorHandler: HttpErrorHandler,
        private route: ActivatedRoute)
    {
        this.apiBaseUrl = env.hostApi;
        this.handleError = httpErrorHandler.createHandleError('CompanyService');
        this.setHttpOptions();
    }
    
    getMyCompanies(): Observable<any> {
        
        var url = this.apiBaseUrl + 'Company/My';

        console.log(url);
        console.log(this.httpOptions);

        return this.http.get<any>(url, this.httpOptions)
            .pipe(
            tap((response: any) => this.onCreated(response)),
            catchError(
                (err: any) => {
                    
                    if (err.status == '401') {
                        this.router.navigate(['/login']);
                    }

                    return new Observable<any>();

                }
            ));    
    }

    getCompanies(userId: string): Observable<any> {

        var url = this.apiBaseUrl + 'Users/' + userId + '/companies';

        return this.http.get<any>(url, this.httpOptions)
            .pipe(
            tap((response: any) => this.onCreated(response)),
            catchError(
                (err: any) => {

                    return new Observable<any>();

                }
            ));
  }

    getGlobalCompanyByOrgNr(orgNr: string): Observable<any> {

    var url = this.apiBaseUrl + 'Companies/Global?orgNr=' + orgNr;

    return this.http.get<any>(url, this.httpOptions)
      .pipe(
        tap((response: any) => this.onCreated(response)),
        catchError(
          (err: any) => {

            return new Observable<any>();

          }
        ));
  }

    GetCompanyUsersFile(companyId: number): Observable<any> {

        var url = this.apiBaseUrl + 'Company/' + companyId.toString() + '/Users/File';

        var bearerToken = 'bearer ' + this.authenticationService.token;

        var httpOptions = {
            responseType: 'blob' as 'json',
            headers: new HttpHeaders({
                'Authorization': bearerToken
            })
        };

        return this.http.get(url, httpOptions);
    }

    getCountries(): Observable<any> {

        var url = this.apiBaseUrl + 'Countries';

        return this.http.get<any>(url, this.httpOptions)
            .pipe(
            tap((response: any) => this.onCreated(response)),
            catchError(
                (err: any) => {

                    return new Observable<any>();

                } 
            ));
    }

    createCompany(company: ICompany): Observable<any> {
      
        var c = JSON.stringify(company); 
        var url = this.apiBaseUrl + 'Company';
        
        return this.http.post<any>(url, c, this.httpOptions)
            .pipe(
            tap((response: any) => this.onCreated(response)),
            catchError(
                this.handleError('createCompany', c)
            ));
    }

    updateCompany(company: ICompany): Observable<any> {

        var c = JSON.stringify(company);
        var url = this.apiBaseUrl + 'Company';

        return this.http.put<any>(url, c, this.httpOptions)
            .pipe(
            tap((response: any) => this.onCreated(response)),
            catchError(
                this.handleError('updateCompany', c)
            ));
    }

    addUser(user: IContact, companyId: number): Observable<any> {
        
        var c = JSON.stringify(user);
        var url = this.apiBaseUrl + 'Company/' + companyId.toString() + '/contact/add';

        return this.http.post<any>(url, c, this.httpOptions)
            .pipe(
            tap((response: any) => this.onCreated(response)),
            catchError(
                this.handleError('adduser', c)
            ));
    }

    updateUser(user: IContact, companyId: number): Observable<any> {

        var c = JSON.stringify(user);
        var url = this.apiBaseUrl + 'Company/' + companyId.toString() + '/contact/update';

        return this.http.put<any>(url, c, this.httpOptions)
            .pipe(
            tap((response: any) => this.onCreated(response)),
            catchError(
                this.handleError('adduser', c)
            ));
    }

    onCreated(response: any)
    {
        //console.log(response);
    }
    
    setHttpOptions() {
        var bearerToken = 'bearer ' + this.authenticationService.token;

        this.httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': bearerToken
            })
        };
    }
}

